import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';


@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  constructor(public appservice:AppService,
    public messageService : MessageService,
    ) { }
  @Output() changeTab =  new EventEmitter();
  @Output() fileChange =  new EventEmitter<{data: any , fileName: any,fileSize:any}>();
  ngOnInit(): void {
  }
  @ViewChild('inputFile') myInputVariable: ElementRef | any;

  data:any = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  @Input() fileName: string = '';
  @Input() fileSize: string = '';
  @Input() dataType: string = '';
  // ws:any= null;
  success = false;
  deleteToggle = false;
  deleteMsg = "";

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      // throw new Error('Cannot use multiple files');
      this.deleteToggle = true;
      this.deleteMsg = "Cannot use multiple files";
      this.deleteFile();
    }

    const file = target.files[0];
    const extension       = file?.type.split('/');
    if(extension[1] =='xlsx' || extension[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){

      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        // const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary',cellDates:true });
        // const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true, cellNF:true});
        let wb: XLSX.WorkBook 
        if(this.dataType == "education" || this.dataType == "family" || this.dataType == 'snapshot' || this.dataType == 'prev_employer')
        wb = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true, cellNF:false});
        else
        wb = XLSX.read(bstr, { type: 'binary', cellText: false, cellDates: true, cellNF:true});
        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        // this.ws = wb.Sheets[wsname];

        var ws = wb.Sheets[wsname];

        Object.keys(ws).forEach(function(s) {
          if(ws[s].w) {
              delete ws[s].w;
              ws[s].z = '0';
          }
        });
        /* save data */
        this.fileName = target.files[0].name;
        this.fileSize = this.appservice.formatSizeUnits(target.files[0].size)
        if(this.dataType == "education" || this.dataType == "family" || this.dataType == 'snapshot'|| this.dataType == 'prev_employer'){
          this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'dd-mm-yyyy',blankrows:false,rawNumbers: false }));
        } else {
          this.data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: true, dateNF: 'dd-mm-yyyy',blankrows:false,rawNumbers: false }));
        }
        //rawNUmbers true changed to false for reading %
        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    } else {
      this.deleteToggle = true;
      this.deleteMsg = this.messageService.invalidExcelFormat();
      this.deleteFile();
    }
  }
  processData(){
    if(this.data.length <=1){
      this.deleteToggle = true;
      this.deleteMsg = "No data found in the uploaded file.";
      this.deleteFile();
    } else {
      this.validataData();
    }
  }

  validataData(){
    var headerData:any = [];

    var headerRow = this.data[0];
    if(this.dataType == "snapshot"){
      headerData = ["prefix","First Name","Middle Name","Last Name","Employee Code","Date of Birth","Gender","Blood group","Nationality", "Work Email","Mobile Number","Date of joining", "Employment type", "Employment status", "Date of confirmation", "Skill type", "Company", "Business Unit", "Department","Designation","Branch", "Grade","Reporting Manager", "Functional Manager","Resignation date",	"Notice period",	"Leaving date",	"Reason for leaving", "Exclude from Final Settlement"
    ]

      this.data.forEach((item:any, value:any) => {
        // if(item[23] && item[23] != "" && typeof item[23] === 'string' || item[23] instanceof String) {
        //   let aEmpcode = item[23].split(" (");
        //   this.data[value][23] = aEmpcode[0]
        // }
        // if(item[22] && item[22] != "" && typeof item[22] === 'string' || item[22] instanceof String) {
        //   let aEmpcode = item[22].split(" (");
        //   this.data[value][22] = aEmpcode[0]
        // }
      })

    } else if(this.dataType == "health"){
      headerData = ["Employee Code","First Name","Last Name","Insuree Name","Insuree date of birth","Insuree Gender","Insurance Code","Assured Sum","Insurance Company","Company Code","Issue Date","Valid Upto"];
    } else if(this.dataType == "prev_employer"){
      headerData = ["Employee Code","First Name","Last Name","Income after exemption","Exempted gratuity amount","Exempted leave encashment amount","PF amount deducted","Professional tax amount deducted","Employer NPS deduction 80CCD(2)","Income tax","Surcharge","Cess"];
      this.data.forEach((item:any, value:any) => {
        if(value>0){
            this.data[value].forEach((key:any, val:any) => {
              if( isNaN(key) == false ) {
                this.data[value][val] = parseFloat(key).toFixed(2);
              }
            })
        }
      })
    }  else if(this.dataType == "education"){
      headerData = ["Employee Code","First Name","Last Name","Qualification","Specialization","Year of passing","University board","College/School","Grade/CGPA" ];
    } else if(this.dataType == "bank"){

      headerData = ["Employee Code","First Name","Last Name","Salary Pay Mode","Account Number","Bank Name",	"Account holder Name",	"IFSC code",	"Branch Name",	"branch Address"]
      ;
    } else if(this.dataType == "professional"){
      headerData = ["Employee Code","First Name","Last Name","Previous Company","Previous Designation","From Date","To Date","Job Description"];
    } else if(this.dataType == "personal"){
      headerData = ["Employee Code","First Name","Last Name","Pan Number","Aadhar Number","Passport Number","Passport Expiry Date","Personal Email","Emergency Contact Name","Emergency Contact Number","Relation","Current Address","Current Country","Current State","Current City","Current Pin","Permanent Address","Permanent Country","Permanent State","Permanent City","Permanent Pin"]
    } else if(this.dataType == "family"){
      var childKeyArray=this.arraySearch("Children Name",headerRow);
      var childArray:any = [];
      var depkeyArray=this.arraySearch("Dependent Name",headerRow);
      headerData = ["Employee Code","First Name","Last Name","Father's Name","Father's DOB","Mother's Name","Mother's DOB","Marital Status","Date of Marriage","Spouse Name","Spouse DOB"]
      var childHead = ["Children Name","Children DOB","Children Gender","Is dependant","Is going to school","Is going to hostel"];
      var depHead = ["Dependent Name","Dependent DOB","Dependent Gender","Dependent relationship","Dependent remarks"];

      childKeyArray.forEach(element => {
        childArray = headerRow.slice(element, element+6);
        this.success = childHead.every(function(val:any) {
          return childArray.indexOf(val) !== -1;
        });
        if(this.success == false){
          this.deleteToggle = true;
          this.deleteMsg = this.messageService.invalidExcelFormat()
          this.deleteFile();
          return false;
        } else{
          return true;
        }
      });
      depkeyArray.forEach(element => {
        childArray = headerRow.slice(element, element+5);
        this.success = depHead.every(function(val:any) {
          return childArray.indexOf(val) !== -1;
        });
        if(this.success == false){
          this.deleteToggle = true;
          this.deleteMsg = this.messageService.invalidExcelFormat();
          this.deleteFile();
          return false;
        } else{
          return true;
        }
      });
    }

    // this.success = headerData.every(function(val:any) {
    //   return headerRow.indexOf(val) !== -1;
    // });
    // if((headerRow.length > headerData.length+2 && this.dataType != "family" && this.dataType != "snapshot") ||headerRow.length > headerData.length && this.dataType == "snapshot"){
    //   this.success = false
    // }
    if(this.dataType != "family"){
      this.success =  JSON.stringify(headerData)==JSON.stringify(headerRow)
    }



    if(this.success == false){
      this.deleteToggle = true;
      this.deleteMsg = this.messageService.invalidExcelFormat()
      this.deleteFile();
    }
    else
      this.fileChange.emit({data: this.data , fileName: this.fileName,fileSize:this.fileSize});
  }



  goNext(tab:number){
    this.changeTab.emit(tab);
  }
  deleteFile(){
    this.myInputVariable.nativeElement.value = '';
    this.fileName = '';
    this.data = [];
    this.fileSize = '';
    this.fileChange.emit({data: this.data , fileName: this.fileName,fileSize:this.fileSize});
  }
  arraySearch(val:any,headerRow:any) {
    let start = [];
    for (var i=0; i<headerRow.length; i++)
        if (headerRow[i] === val)
            start.push(i);
    return start;
  }


}
