import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, NgModel, Validators } from '@angular/forms';
import { InboxService } from 'src/app/@core/services/inbox.service';
import { InvestmentDeclerationService } from 'src/app/@core/services/investment-decleration.service';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { AppService } from 'src/app/app.global';
import { MessageService } from 'src/app/message.global';

@Component({
  selector: 'app-loan-process',
  templateUrl: './loan-process.component.html',
  styleUrls: ['./loan-process.component.scss']
})
export class LoanProcessComponent implements OnInit {

  // Input
  @Input() requestitem: any;

  // Output
  @Output() successEmitter: EventEmitter<boolean> = new EventEmitter();

  // View Status
  billLoader              = false;
  viewstatusPanel         = false;
  viewStatusData    : any = [];
  viewStatusLevelCnt: any;
  viewStatusmaxLevel: any;
  regenerate              = false;
  submitClicked           = false
  loader1                 = true;
  processBtn              = false;
  viewExistingLoan        = false
  viewExistingData  : any = []
  navData           : any = []
  fromModal               = ''
  listOfMonth             =
  ["January","February","March","April","May","June","July",
    "August","September","October","November","December"];

  form                    = this.fb.group({
    comment          : ['', Validators.maxLength(500)],
    loan_amount      : [null],
    number_of_emi    : [null],
    interest_rate    : [null],
    interest_penality: [null],
    start_month      : [null, [Validators.required]]
  })

  alertAmountToggle       = false
  alertEMIToggle          = false
  alertINT_rate           = false
  alertINT_penality       = false

  get f() { return this.form.controls; }

  constructor(
    private inboxservice: InboxService,
    private investmentService: InvestmentDeclerationService,
    public appservice   : AppService,
    public msgServ      : MessageService,
    private fb          : FormBuilder,
    public cd           : ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    this.loader1       = true;
    setTimeout(() => {
      this.loader1     = false;
    }, 2000);
  }

  existingLoanData(){
    this.viewExistingLoan = true
    this.billLoader = true
    let empId = this.requestitem?.requests?.from_person?.id
    this.inboxservice.getExistingLoan(empId).subscribe((res:any)=>{
      let response = res?.data
      this.navData = response.map((item:any)=>{
        return {
          'loan_code' : item.loan_details.loan_code,
          'loan_id' : item.loan_details.id
        }
      })
      this.viewExistingData = response[0]?.loan_details
      this.billLoader = false
    })
  }

  panelClose(val:any){
    this.viewExistingLoan = val
  }

  submitModalData(from:any){
    this.submitClicked = true
    this.openCloseModal(from,false)
    let body = { [from] : Number(this.form.get(from)?.value) };
    this.inboxservice.editModalUpdates(this.requestitem?.id,body).subscribe((res:any)=>{
      this.successEmitter.emit(true);
    })
  }

  editModal(from:any){
    let control = this.form.get(from)
    if(control){
      if(from == 'loan_amount'){
        control?.setValidators([Validators.max(this.requestitem?.requests?.amount_limit),Validators.required, Validators.min(1)])
      }else if(from == 'number_of_emi'){
        control?.setValidators([Validators.max(this.requestitem?.requests?.loan_type_details?.max_number_emis),Validators.required, Validators.min(1)])
      }else if(from == 'interest_rate'){
        control?.setValidators([Validators.max(this.requestitem?.requests?.loan_type_details?.interest_rate),Validators.required,NumberpatternValidator()])
      }else{
        control?.setValidators([Validators.max(this.requestitem?.requests?.loan_type_details?.interest_penality),Validators.required,NumberpatternValidator()])
      }
      control?.updateValueAndValidity();
      this.cd.detectChanges()
    }
    this.openCloseModal(from,true)
  }


  closeModal(control:any){
    this.form.get(control)?.setValue(null)
    this.form.get(control)?.clearValidators();
    this.form.get(control)?.updateValueAndValidity();
    this.openCloseModal(control,false)
  }

  openCloseModal(from:any,bool:any){
    if(from == 'loan_amount'){
      this.alertAmountToggle = bool
    }else if(from == 'number_of_emi'){
      this.alertEMIToggle = bool
    }else if(from == 'interest_rate'){
      this.alertINT_rate = bool
    }else{
      this.alertINT_penality = bool
    }
  }

  processFn(){
    this.processBtn = true
    let body = {
      'start_month' : this.form.get('start_month')?.value,
      'comment': this.form.get('comment')?.value
    }
    this.inboxservice.loanProcess(this.requestitem?.id, body).subscribe((res:any)=>{
      this.successEmitter.emit(true);
    },(err => {
      this.processBtn = false
    }))
  }

  performAction(action: any, type: any = 0, id: any, comment_value: any = '', read_status: any = true, star: any = true) {
    let status_value;
    let dataArray: any = [];

    if (action == 3) {
      if (type == 1) {
        status_value = "Approved"
      } else {
        status_value = "Rejected"
      }
      dataArray = {
        "approved_status": status_value,
        "comment": comment_value,

      };
    } else if (action == 1) {
      if (read_status == false) {
        read_status = true;
        dataArray = {
          "read_status": read_status,
        };
      }
    } else if (action == 2) {
      if (star == false) {
        star = true;
      } else {
        star = false;
      }
      dataArray = {
        "star": star,
      };
    }
    if (dataArray.length != 0) {
      this.inboxservice.updateInboxItem(id, dataArray).subscribe((res: any) => {
      })
    }
  }

  closegen(bool:any){
    this.regenerate = bool;
  }

  // View Status
  requestViewStatus() {
    this.viewstatusPanel = true
    this.billLoader = true;
    this.investmentService.requestViewStatus(this.requestitem.request_data['id']).subscribe((res: any = []) => {
      this.viewStatusData = res;
      this.billLoader = false;
      this.viewStatusLevelCnt = res?.activity?.level_count;
      this.viewStatusmaxLevel = res?.activity?.maxlevel;
    });
  }

  notanumber(val: any) {
    if (typeof +val === "number" && !isNaN(+val)) return null;
    else return true;
  }
}
