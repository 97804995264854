<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row  mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Pay Component</h3>
      <p class="text-helper mb-md-0">Pay components determines your salary structure. Add different pay components that
        will appear on your payslip.</p>
    </div>


    <div class="col-md-8 col-lg-6">

      <div class="d-flex justify-content-end  gap-16">
        <div class=" " style="width:22.6875rem;">
          <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]='paymentPlaceholder'
            (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchpay($event)" [isLoading]="isLoading"></app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';statusfilter('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';statusfilter(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';statusfilter(false)">Deleted</button>
          </div>
        </div>

        <button *ngIf="permissions.a" routerLink="/pay-component/pay-component-setup"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add"><i class="icon-plus"></i>Add Pay component</button>

      </div>
    </div>
  </div>
  <!-- No data found -->

  <div *ngIf="nodata && !loader;else all_data" class="row " style="height: calc(100vh - 12rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg42.png'" [noDataText]="'It Looks Like Pay Components Are Not Set'"
      [noDataPara]="'Add pay components to shape your salary structure. Click \'Add Pay Component\' to display them on your payslip.'"
      [hasPermission]="permissions?.a"
      (addData)="addNewdata()"> </app-nodata-view>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <ng-template #all_data>
    <div class="row row-16 mb-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 " *ngFor="let pay_comp of payComponentData;let i=index">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex p-16 {{(pay_comp.is_active == true) ? 'view-section' : 'w-100' }} "
                (click)="viewdataapproval(pay_comp.id)">

                <div class="logo-img {{pay_comp.color_code}}">{{this.appService.getFirstChar(pay_comp.name,2)}}</div>
                <div class="overflow-hidden ">
                  <h6 class="tips text-trim mb-0">{{pay_comp.name}}</h6>
                  <span class="tips-helper">{{pay_comp.name}}</span>
                  <span
                    class="fs-10 text-uppercase fw-500 {{(pay_comp.is_active == true) ? 'text-success' : 'text-danger' }} ">{{pay_comp.is_active
                    == true ? 'Active':'Deleted'}}</span>
                </div>
              </div>
              <div *ngIf="pay_comp.is_active==true && (permissions.e || permissions.d)" ngbDropdown
                class="pe-16 py-16 dropdown-section d-inline-block ">
                <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical fs-16"></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                  <button *ngIf="permissions.e" ngbDropdownItem
                    (click)="editComponent(pay_comp.id,pay_comp.is_paycomponent_used)">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <button
                    *ngIf="permissions.d && pay_comp.component_type_name != 'Gratuity' && pay_comp.component_type_name != 'Other Allowance'"
                    class="text-danger" ngbDropdownItem (click)="deleteAlert=true;deleteId=pay_comp.id">
                    <i class="icon-trash  me-1"></i> Delete
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader" >
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
  <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->


</div>
<!-- view -->
<div class="side-panel view-side" style="--sidepanel-width:44.6875rem;"
  [class.side-pane-active]='showSidePanel === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="showSidePanel = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;" *ngIf="permissions">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader">

          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">PAY COMPONENT</p>
            <a *ngIf="(permissions.e && viewpaycompdata.is_active)"
              class="link-primary fs-14 d-flex align-items-center ms-auto"
              (click)="editComponent(viewpaycompdata.id,viewpaycompdata.is_paycomponent_used)">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>
          <div class="row row-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 pt-16 pb-24">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">PAY COMPONENT DETAILS</span></div>
                  <div class="col-6">
                    <div class="detail-title">Component name</div>
                    <div class="detail-desc">{{viewpaycompdata.name}}</div>
                  </div>
                  <div class="col-6">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span class="fs-12 fw-500 text-success text-uppercase"
                        *ngIf="viewpaycompdata.is_active">ACTIVE</span>
                      <span class="fs-12 fw-500 text-danger text-uppercase"
                        *ngIf="viewpaycompdata.is_active==false">DELETED</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Component type</div>
                    <div class="detail-desc"> {{viewpaycompdata.component_type_name}} </div>
                  </div>
                  <ng-container *ngIf="viewpaycompdata.component_type_name=='Loan Type'">
                    <div class="col-12" >
                      <div class="detail-title">Loan eligibility from date of joining</div>
                      <div class="detail-desc"> {{viewpaycompdata?.loan_eligibility_days}} </div>
                    </div>
                    <div class="col-12" >
                      <div class="detail-title">Maximum number of EMI</div>
                      <div class="detail-desc"> {{viewpaycompdata?.max_number_emis}} </div>
                    </div>
                    <div class="col-12" >
                      <div class="detail-title">Interest type</div>
                      <div class="detail-desc"> {{viewpaycompdata?.interest_type}} </div>
                    </div>
                    <div class="col-12" >
                      <div class="detail-title">Rate of interest(%)</div>
                      <div class="detail-desc"> {{viewpaycompdata?.interest_rate}} </div>
                    </div>
                    <div class="col-12" >
                      <div class="detail-title">Interest penalty on payment shortfall rate(%)</div>
                      <div class="detail-desc"> {{viewpaycompdata?.interest_penality}} </div>
                    </div>
                  </ng-container>

                  <div class="col-12" *ngIf="viewpaycompdata.component_type_name=='Perquisite'">
                    <div class="detail-title">Perquisite type</div>
                    <div class="detail-desc"> {{viewpaycompdata?.perquisite_type}} </div>
                  </div>
                  <div class="col-12" *ngIf="viewpaycompdata.component_type_name=='Leave Encashment'">
                    <div class="detail-title">Leave type</div>
                    <div class="detail-desc"> {{viewpaycompdata?.encashment_leave_type_name}} </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Pay type</div>
                    <div class="detail-desc">
                      <span class=" " *ngIf="viewpaycompdata.pay_type">Addition</span>
                      <span class="" *ngIf="viewpaycompdata.pay_type==false">Deduction</span>
                    </div>
                  </div>

                  <div class="col-12 form-row" *ngIf="viewpaycompdata.set_component_ctc">
                    <div class="d-flex align-items-center gap-8 fs-14">
                      <i class="icon-check-square-fill text-light-500"></i>Set this component as part of CTC
                    </div>
                  </div>

                  <div class="col-12 form-row" *ngIf="viewpaycompdata.set_component_gross">
                    <div class="d-flex align-items-center gap-8 fs-14">
                      <i class="icon-check-square-fill text-light-500"></i>Set this component as part of gross
                    </div>
                  </div>
                  <div class="col-12 form-row" *ngIf="viewpaycompdata.pay_component_salary">
                    <div class="d-flex align-items-center gap-8 fs-14">
                      <i class="icon-check-square-fill text-light-500"></i>Want to pay this component in salary
                    </div>
                  </div>
                  <div class="col-12 form-row" *ngIf="viewpaycompdata.set_reimbursable">
                    <div class="d-flex align-items-center gap-8 fs-14">
                      <i class="icon-check-square-fill text-light-500"></i>Set this component as reimbursable
                    </div>
                  </div>
                  <div class="col-12 form-row" *ngIf="viewpaycompdata.set_fbp">
                    <div class="d-flex align-items-center gap-8 fs-14">
                      <i class="icon-check-square-fill text-light-500"></i>Set this component as flexi benefit plan
                      (FBP)
                    </div>
                  </div>
                  <div class="col-12 form-row" *ngIf="viewpaycompdata.restric_fbp_amount">
                    <div class="detail-title">Flexi benefit plan (FBP)</div>
                    <div class="d-flex align-items-center gap-8 fs-14 mt-1">
                      <i class="icon-check-square-fill text-light-500"></i>Restrcit employee from overriding the FBP
                      amount
                    </div>
                  </div>


                  <div class="col-12">
                    <div class="detail-title">Nature of payment</div>
                    <div class="detail-desc">
                      <span class="" *ngIf="viewpaycompdata.nature_payment">Fixed</span>
                      <span class="" *ngIf="viewpaycompdata.nature_payment==false">Variable</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title"> Prorate as per paid days</div>
                    <div class="detail-desc">
                      <span class="" *ngIf="viewpaycompdata.prorate_per_day">Yes</span>
                      <span class="" *ngIf="viewpaycompdata.prorate_per_day==false">No</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Arrear applicable</div>
                    <div class="detail-desc">
                      <span class="" *ngIf="viewpaycompdata.arrear_applicable">Yes</span>
                      <span class="" *ngIf="viewpaycompdata.arrear_applicable==false">No</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to process in joining month</div>
                    <div class="detail-desc">
                      <span class="" *ngIf="viewpaycompdata.process_join_month">Yes</span>
                      <span class="" *ngIf="viewpaycompdata.process_join_month==false">No</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Do you want to process in settlement month</div>
                    <div class="detail-desc">
                      <span class="" *ngIf="viewpaycompdata.process_settlement_month">Yes</span>
                      <span class="" *ngIf="viewpaycompdata.process_settlement_month==false">No</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Statutory Details</span></div>
                  <div class="col-12">
                    <div class="row row-12">
                      <div class="col-12 form-row" *ngIf="viewpaycompdata.pf_contribution">
                        <div class="d-flex align-items-center gap-8 fs-14">
                          <i class="icon-check-square-fill text-light-500"></i>Consider for PF contribution
                        </div>
                      </div>
                      <ng-container *ngIf="viewpaycompdata.pf_contribution">

                        <div class="col-12 form-row ms-20" *ngIf="viewpaycompdata.pf_always">
                          <div class="d-flex align-items-center gap-8 fs-14">
                            <i class="bi-record-circle-fill text-light-500"></i>Always consider for PF contribution
                          </div>
                        </div>
                        <div class="col-12 form-row ms-20" *ngIf="!viewpaycompdata.pf_always">
                          <div class="d-flex align-items-center gap-8 fs-14">
                            <i class="bi-record-circle-fill text-light-500"></i>Consider for PF contribution only when
                            PF wage is less than 15000
                          </div>
                        </div>
                      </ng-container>

                      <div class="col-12 form-row" *ngIf="viewpaycompdata.pt_contribution">
                        <div class="d-flex align-items-center gap-8 fs-14">
                          <i class="icon-check-square-fill text-light-500"></i>Consider for PT deduction
                        </div>
                      </div>
                      <div class="col-12 form-row" *ngIf="viewpaycompdata.esi_contribution">
                        <div class="d-flex align-items-center gap-8 fs-14">
                          <i class="icon-check-square-fill text-light-500"></i>Consider for ESI contribution
                        </div>
                      </div>
                      <div class="col-12 form-row" *ngIf="viewpaycompdata.esi_limit">
                        <div class="d-flex align-items-center gap-8 fs-14">
                          <i class="icon-check-square-fill text-light-500"></i>Consider checking the limit of ESI
                          applicability
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="detail-title">Round off configuration</div>
                    <div class="detail-desc">
                      <span class="">{{viewpaycompdata.roundoff}}</span>

                    </div>
                  </div>


                  <div class="col-12 form-row">
                    <div for="" class="detail-title">Show on payslip </div>
                    <div class="detail-desc">
                      <span class="" *ngIf="viewpaycompdata.show_salary_slip">Yes</span>
                      <span class="" *ngIf="viewpaycompdata.show_salary_slip==false">No</span>
                    </div>
                  </div>
                  <div class="col-12 form-row">
                    <div for="" class="detail-title">Show on salary register </div>
                    <div class="detail-desc">
                      <span class="" *ngIf="viewpaycompdata.show_salary_register">Yes</span>
                      <span class="" *ngIf="viewpaycompdata.show_salary_register==false">No</span>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="viewpaycompdata.show_salary_register">
                    <div class="detail-title">Salary register sort order</div>
                    <div class="detail-desc">{{viewpaycompdata.salary_register_order}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Tax Details</span></div>
                  <div class="col-12">
                    <div class="detail-title">Tax configuration
                    </div>
                    <div class="detail-desc">{{viewpaycompdata.tax_configuration}}</div>
                  </div>
                  <div class="col-12" *ngIf="viewpaycompdata.non_taxable_limit!=null">
                    <div class="detail-title">Non-taxable limit
                    </div>
                    <div class="detail-desc">{{viewpaycompdata.non_taxable_limit}}</div>
                  </div>
                  <div class="col-12" *ngIf="!taxRestriction">
                    <div class="detail-title">Tax computation
                    </div>
                    <div class="detail-desc">{{viewpaycompdata.tax_computation==true?'Proportionally':'Pay month'}}
                    </div>
                  </div>
                  <div class="col-12"
                    *ngIf="viewpaycompdata.under_section_income!='' && viewpaycompdata.under_section_income!=null ">
                    <div class="detail-title">Map income under this section
                    </div>
                    <div class="detail-desc">{{viewpaycompdata.under_section_income}}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<!-- Delete pay component -->
<div class="modal  modal-alert {{deleteAlert == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Delete Pay Component?</h4>

      </div>
      <div class="modal-body">
        <p class="mb-0">{{this.messageService.Deletemsgdisplay('Pay Component')}}

        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm text-uppercase"
          (click)="deleteAlert = false">Cancel</button>
        <button type="button" class="btn btn-danger btn-sm text-uppercase" [disabled]="deleteClicked"
          (click)="deletePayComponent(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
