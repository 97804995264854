<div  *ngIf="dataArranged.length>0" class="container-fluid p-32 form-container">
    <div class="row mb-32">
        <div class="d-flex flex-wrap">
            <p class="text-uppercase fw-500 mb-24">Preview and Upload</p>
            <div class="ms-auto d-flex justify-content-end align-items-center flex-wrap gap-16">
                <!-- <a class="link-danger" (click)="processDelete()"><i class="icon-trash me-8 "></i>Delete selected</a> -->

                <div style="min-width:22.688rem;">
                    <div class="form-icon2">
                      <i class="icon-search icon-left fs-14"></i>
                      <div *ngIf="(searchString != '' && searchString != null)" class="btn-close icon-right pointer fs-12" (click)="searchString=''"></div>
                      <input class="form-control  fs-14" autocomplete="off" placeholder="{{this.messageService.searchdisplay('novalue')}}  " name="searchString" [(ngModel)]="searchString"  (keyup)="page=1"/>
                    </div>
                </div>
                <div ngbDropdown class="d-inline-block">
                    <a class=" btn-square btn btn-secondary" href="javascript:void(0)" placement="bottom-left"
                        ngbDropdownToggle>
                        <i class="icon-filter-left fs-13"></i>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-end">
                        <button ngbDropdownItem (click)='hasError = "";page=1'>All</button>
                        <button ngbDropdownItem (click)='hasError = "hasErrorData";page=1'>Error data only</button>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="row row-16">
        <div class="col-12">
            <div class="table-responsive card card-c2 scrollbar-10" style="max-height: calc(100vh - (4rem + 2.5rem + 3.625rem + 5.5rem + 1rem)) ;"  (scroll)="scrollHandler($event)">
                <div class="input-group">
                    <table  *ngIf="deleteToggle == false" class="table sticky-header sticky-first-col table-striped vertical-align-top table-sm form-table" >
                        <thead>
                            <tr>
                                <th class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value=""
                                        [checked]="allChecked()" (click)="checkall($event)">
                                </th>
                                <th *ngFor="let val of initialHeader" style="white-space: no-wrap;">
                                    {{val}}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of $any(dataArranged |filter:searchString| filter:hasError | slice: (page-1) * pageSize : page * pageSize),let ind = index,let indd=index">
                            <!-- <tr *ngFor='let row of dataArranged|filter:searchString| filter:hasError |slice: (page-1) * pageSize : page * pageSize,let ind = index,let indd=index'> -->
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox"
                                        (click)="toggleSelection($event, getVal(row,'rowNum'))"
                                        [checked]="isChecked(getVal(row,'rowNum'))">
                                </td>
                                <td class="form-cell" *ngFor="let val of headerData,let k = index">
                                    <!-- {{val}} -->
                                    <span
                                    [class]="{'text-nowrap':(val.toLowerCase().includes('birth')  || val.toLowerCase().includes('dob') ||  val.toLowerCase().includes('date') || val.toLowerCase().includes('year')     )}"
                                        *ngIf=' (!val.toLowerCase().includes("tax") && !val.toLowerCase().includes("gratuity") && !val.toLowerCase().includes("pf") && !val.toLowerCase().includes("encashed")) && getVal(row,val) != "hasError" && getVal(row,val) != "hasErrorInput"'>{{getVal(row,val)}}</span>

                                    <span  *ngIf='(val.toLowerCase().includes("tax")  || val.toLowerCase().includes("gratuity") || val.toLowerCase().includes("pf") || val.toLowerCase().includes("encashed")) && getVal(row,val) != "hasError" && getVal(row,val) != "hasErrorInput"'>{{getVal(row,val)| number:'1.0':'en-US' }}</span>

                                    <input *ngIf=' getVal(row,val) == "hasErrorInput"' type="text" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        class="hasError form-control "
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]" placeholder="{{this.messageService.placeholderdisp(initialHeader[k])}}"/>
                                    <!-- date Input -->

                                    <input
                                        *ngIf='(val.toLowerCase().includes("birth")  || val.toLowerCase().includes("dob"))  && getVal(row,val) == "hasError"'
                                        class="hasError form-control  " [max]="today"  [class]="getClassName(getVal(row,'rowNum'),val,k )" [ngClass]="getClass(row,val)"
                                        placeholder="{{this.messageService.placeholderdisp('Date')}}" [(ngModel)]="data[getVal(row,'rowNum')][k]" matInput
                                        [matDatepicker]="indd" [matDatepickerFilter]="dobFilter"  [attr.id]="indd"
                                        (click)="indd.open()">
                                    <input
                                        *ngIf='(val.toLowerCase() == "valid upto" || val.toLowerCase() =="to date" ) && getVal(row,val) == "hasError"'
                                        class="hasError form-control  " [min]="data[getVal(row,'rowNum')][k-1]"  [class]="getClassName(getVal(row,'rowNum'),val,k )" [ngClass]="getClass(row,val)"  [matDatepickerFilter]="dateFilter"
                                        placeholder="{{this.messageService.placeholderdisp('Date')}}" [(ngModel)]="data[getVal(row,'rowNum')][k]" matInput
                                        [matDatepicker]="indd"  [attr.id]="indd"
                                        (click)="indd.open()">
                                    <input
                                        *ngIf='val.toLowerCase() !="to date"  && !val.toLowerCase().includes("birth") && (val.toLowerCase().includes("date") || val.toLowerCase().includes("year") )  && getVal(row,val) == "hasError"'  [matDatepickerFilter]="dateFilter"
                                        class="hasError form-control " [class]="getClassName(getVal(row,'rowNum'),val,k )" [ngClass]="getClass(row,val)"
                                        placeholder="{{this.messageService.placeholderdisp('Date')}}" [(ngModel)]="data[getVal(row,'rowNum')][k]" matInput
                                        [matDatepicker]="indd"  [attr.id]="indd"
                                        (click)="indd.open()">
                                    <mat-datepicker   #indd></mat-datepicker>
                                    <!-- skill -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("skill") && getVal(row,val) == "hasError"'
                                        class=" hasError" [placeholder]="skillkey"
                                        [type]='"skill_type"' [selectError]='"Skill Type"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- gender -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"' [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("gender") && getVal(row,val) == "hasError"'
                                        class=" hasError" [placeholder]="genderkey"
                                        [type]='"gender"' [selectError]='"Gender"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- blood -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("blood") && getVal(row,val) == "hasError"'
                                        class=" hasError" [placeholder]="bloodgrpkey"
                                        [type]='"blood_Group"' [selectError]='"Blood Group"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- salutation -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("prefix") && getVal(row,val) == "hasError"'
                                        class=" hasError" [placeholder]="prefixkey"
                                        [type]='"salutation"' [selectError]='"Prefix"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- nationality -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"' [className]="getClassName(getVal(row,'rowNum'),val,k )"  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("nationality") && getVal(row,val) == "hasError"'
                                        class=" hasError" [placeholder]="natkey"
                                        [type]='"nationality"' [selectError]='"Nationality"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- salary pay mode -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("salary") && !val.toLowerCase().includes("taxable") && getVal(row,val) == "hasError"'
                                        class=" hasError" [placeholder]="paymodekey"
                                        [type]='"payment_mode"' [selectError]='"Salary Pay Mode"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- YesNo -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                    *ngIf='(val.toLowerCase().includes("is going to hostel") || val.toLowerCase().includes("is going to school") || val.toLowerCase().includes("is dependant")) && getVal(row,val) == "hasError"'
                                    class=" hasError" [placeholder]="yesnokey"
                                    [type]='"yesno"' [selectError]='""'
                                    [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- Marriage Status -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("marital") && getVal(row,val) == "hasError"'
                                        class=" hasError" [placeholder]="marstatuskey"
                                        [type]='"marital_status"' [selectError]='"Marital Status"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>



                                    <!-- relation -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("relation") && getVal(row,val) == "hasError"'
                                        class=" hasError" [placeholder]="relationkey"
                                        [type]='"relation"' [selectError]='"Relation"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- Country Code -->
                                    <ng-select appendTo="body"  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("phone code") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Country Code')}}"
                                        placeholder="+" style="width: 9.375rem;"
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]">
                                        <ng-container *ngFor="let li of phoneCode">
                                            <ng-option *ngIf="li.phone!=''"  [value]="li.phone">{{"+"+li.phone}}
                                            </ng-option>
                                        </ng-container>
                                    </ng-select>

                                    <!-- Employment type -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"  class="  hasError"
                                        *ngIf='val.toLowerCase().includes("employment type") && getVal(row,val) == "hasError"'
                                        [isrequired]='"true"' [placeholder]="emptypekey"
                                        [type]='"employment_Type"' [selectError]='"Employment Type"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- Employment status -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"  class="  hasError"
                                        *ngIf='val.toLowerCase().includes("employment status") && getVal(row,val) == "hasError"'
                                        [isrequired]='"true"' [placeholder]="empstakey"
                                        [type]='"employment_Status"' [selectError]='"Employment Status"'
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <!-- manager -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("manager") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Manager')}}"
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"
                                        [items]="empDD" bindValue="id" bindLabel="fullname">
                                        <ng-template ng-label-tmp let-item="item"> 
                                            <span class="ng-value-label">
                                              <div class="d-inline-flex align-items-center">
                                                <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null" class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1" ><span>{{this.appservice.getFirstChar(item.fullname,2)}}</span></div>
                                                <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="item.profile_image !=  '' && item.profile_image !=  null" [src]="item.profile_image" >
                                                    {{item.fullname}}
                                               </div>
                                             </span>   </ng-template>
                                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                            <span class="ng-value-label">
                                              <div class="d-inline-flex align-items-center">
                                                <div *ngIf="item.profile_image ==  '' || item.profile_image ==  null" class="avatar-sm sq-24 rounded-circle {{item.color_code}} me-1" ><span>{{this.appservice.getFirstChar(item.fullname,2)}}</span></div>
                                                <img class="avatar-sm sq-24 img-fluid rounded-circle me-1" *ngIf="item.profile_image !=  '' && item.profile_image !=  null" [src]="item.profile_image" >
                                                    {{item.fullname}}
                                               </div>
                                             </span>
                                           </ng-template>
                                    </ng-select>

                                    <!-- company -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("company") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Company')}} "
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]" [items]="companyDD" class="form-ngselect"
                                        bindValue="company_name" bindLabel="company_name">
                                        <ng-template ng-label-tmp let-item="item" let-index="index"> 
                                            <div class="d-inline-flex align-items-center">
                                                 
                                              <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"  *ngIf="item.logo !=  '' && item.logo != null"
                                              src="{{item.logo}}">
                                              <span *ngIf="item.logo ==  ''|| item.logo == null" class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}" >
                                                <span>
                                                  {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                                                </span>
                                              {{item.company_name}}
                                            </div>
                                              </ng-template>
                                              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                                <div class="d-inline-flex align-items-center">
                                                   
                                                  <img class="avatar-sm me-8 sq-32 img-fluid rounded-circle"  *ngIf="item.logo !=  '' && item.logo != null"
                                                  src="{{item.logo}}">
                                                  <span *ngIf="item.logo ==  ''|| item.logo == null" class="avatar-sm sq-32 me-8 rounded-circle bg{{index%5}}" >
                                                    <span>
                                                      {{this.appservice.getFirstChar(item.company_name,1)}}</span>
                                                    </span>
                                                  {{item.company_name}}
                                                </div>
                                            </ng-template>
                                    </ng-select>

                                    <!-- business unit -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("business unit") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Business unit')}}"
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]">
                                        <ng-option *ngFor="let item of buDD" [value]="item.name">{{ item.name }}
                                        </ng-option>
                                    </ng-select>

                                    <!-- Department -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("department") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Department')}}"
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]">
                                        <ng-option *ngFor="let item of DepDD" [value]="item.name">{{ item.name }}
                                        </ng-option>
                                    </ng-select>

                                    <!-- designation -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("designation") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Designation')}}"
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]">
                                        <ng-option *ngFor="let item of DesignationDD" [value]="item.name">{{ item.name
                                            }}</ng-option>
                                    </ng-select>

                                    <!-- Branch -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("branch") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Branch')}}"
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]">
                                        <ng-option *ngFor="let item of BranchDD" [value]="item.name">{{ item.name }}
                                        </ng-option>
                                    </ng-select>

                                    <!-- Grade -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("grade") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Grade')}}"
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]">
                                        <ng-option *ngFor="let item of gradeDD" [value]="item.name">{{ item.name }}
                                        </ng-option>
                                    </ng-select>

                                    <!-- City -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("city") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('City')}} "
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]">
                                        <ng-option *ngFor="let item of cityDD" [value]="item.name">{{ item.name }}
                                        </ng-option>
                                    </ng-select>

                                    <!-- Country -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("country") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Country')}} "
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"
                                        >
                                        <ng-option *ngFor="let item of country_list" [value]="item.name">{{ item.name }}
                                        </ng-option>
                                    </ng-select>

                                    <!-- State -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("state") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('State')}} "
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"
                                        >
                                        <ng-option *ngFor="let item of state_list" [value]="item.name">{{ item.name }}
                                        </ng-option>
                                    </ng-select>

                                    <!-- Reason For Leaving -->
                                    <ng-select appendTo="body" [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                        *ngIf='val.toLowerCase().includes("reason for leaving") && getVal(row,val) == "hasError"'
                                        class="form-ngselect   hasError" placeholder="{{this.messageService.selectplaceholddisplay('Reason For Leaving')}} "
                                        [(ngModel)]="data[getVal(row,'rowNum')][k]"
                                        >
                                        <ng-option *ngFor="let item of reasonForLeaving" [value]="item.name">{{ item.name }}</ng-option>
                                    </ng-select>

                                    <!-- Exclude from final settlement -->
                                    <app-master-select [dropdownPosition]='"auto"' [appendTo]='"body"'  [class]="getClassName(getVal(row,'rowNum'),val,k )"
                                    *ngIf='val.toLowerCase().includes("exclude from final settlement") && getVal(row,val) == "hasError"'
                                    class=" hasError" [placeholder]="yesnokey"
                                    [type]='"yesno"' [selectError]='""'
                                    [(ngModel)]="data[getVal(row,'rowNum')][k]"></app-master-select>

                                    <div *ngIf='getVal(row,val) == "hasError" || getVal(row,val) == "hasErrorInput"'
                                        class="invalid-feedback">
                                        <div> {{getError(getVal(row,'rowNum'),val,k )}}</div>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <table  class="table table-striped form-table">
                        <thead>
                            <tr >
                                <th scope="col" class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value="" >
                                </th>
                                <th scope="col" >Employee name</th>
                                <th scope="col" >Employee code</th>
                                <th scope="col" >work email</th>
                                <th scope="col" >Mobile number</th>
                                <th scope="col" >blood group</th>
                            </tr>
                        </thead>
                        <tbody class="cell-16">

                            <tr>
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value="" >
                                </td>
                                <td >Ralph Edwards</td>
                                <td >L2PX0K</td>
                                <td >Ralph@skizzle.tech</td>
                                <td >(702) 555-0122</td>
                                <td  class="form-cell">
                                    <input type="text" class="form-control " value="U" >
                                </td>
                            </tr>
                            <tr>
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value="" >
                                </td>
                                <td >Kristin Watson</td>
                                <td >23LHLKU</td>
                                <td  class="form-cell">
                                    <input type="text" class="form-control " value="Kristin@zh" >
                                </td>
                                <td >(208) 555-0112</td>
                                <td > AB+ </td>
                            </tr>
                            <tr>
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value="" >
                                </td>
                                <td >Theresa Webb</td>
                                <td >AAPS0L</td>
                                <td >Theresa@skizzle.tech</td>
                                <td >(307) 555-0133</td>
                                <td  >
                                    B+
                                </td>
                            </tr>
                            <tr>
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value="" >
                                </td>
                                <td >Ralph Edwards</td>
                                <td >L2PX0K</td>
                                <td >Ralph@skizzle.tech</td>

                                <td  class="form-cell">
                                    <input type="text" class="form-control " value="(208) 555-0112" >
                                </td>
                                <td>
                                   B+
                                </td>
                            </tr>
                            <tr>
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value="" >
                                </td>
                                <td >Cameron Williamson </td>
                                <td >L2PX0K</td>
                                <td >Ralph@skizzle.tech</td>
                                <td  class="form-cell">
                                    <input type="text" class="form-control " value="(702) 555-0122" >
                                </td>

                                <td >
                                   B+
                                </td>
                            </tr>
                            <tr *ngFor="let item of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                <td class="form-cell">
                                    <input class="form-check-input m-0" type="checkbox" value="" >
                                </td>
                                <td >{{item}} </td>
                                <td >SD9212969</td>
                                <td >Cameron@skizzle.tech</td>
                                <td >(405) 555-0128</td>
                                <td >B+</td>
                            </tr>
                         </tbody>
                    </table>  -->
                </div>
            </div>

        </div>
        <div class="col-12" *ngIf="this.filterIt().length>pageSize">
            <ngb-pagination class="d-flex justify-content-end" [collectionSize]="filteredcount()" [(page)]="page"
                [pageSize]="pageSize" [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    </div>
</div>
<app-view-loader *ngIf="dataArranged.length <= 0"></app-view-loader>

<footer class="submit-footer">
    <button class="btn btn-outline-danger text-uppercase btn-sm" type="button"  (click)="processDelete()">Delete</button>
    <a class="btn btn-primary btn-sm ms-auto " (click)="saveData()">Done</a>
</footer>

<div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">Are you sure?</h4>
            </div>
            <div class="modal-body">
                <!-- <p class="mb-0">23 Error data found. Do you still want to proceed without corrections?</p> -->
                <p class="mb-0">{{deleteMessage}}</p>
            </div>
            <div class="modal-footer" *ngIf="isDelete == true">
                <button type="button" class="btn btn-outline-primary btn-sm"
                    (click)="deleteToggle = false">Cancel</button>
                <button type="button" class="btn btn-danger btn-sm" (click)="deleteSelected()">Delete</button>
            </div>
            <div class="modal-footer" *ngIf="isSave == true">
                <button type="button" class="btn btn-outline-primary btn-sm"
                    (click)="deleteToggle = false">Cancel</button>
                <button type="button" class="btn btn-danger btn-sm" (click)="uploadDataWithError()">Upload</button>
            </div>
        </div>
    </div>
</div>
