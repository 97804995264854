<div class="container-fluid px-32 py-24">
  <div class="row row-16">
    <div class="col-12 d-flex gap-8  align-items-center">
      <span class="text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
      <button class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
      (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8">Loan Approval</h5>
        <ng-container *ngIf="requestitem?.request_data?.status != 'Invalid'">
          <span class="badge px-16 ms-auto fs-14 badge-warning py-1"
              *ngIf="requestitem.approved_status == 'Pending'">Pending</span>
          <span class="badge px-16 ms-auto fs-14 badge-success py-1"
              *ngIf="requestitem.approved_status == 'Approved'">Accepted</span>
          <span class="badge px-16 ms-auto fs-14 badge-danger py-1"
              *ngIf="requestitem.approved_status == 'Rejected'">Rejected</span>
          <span class="text-light-400">|</span>
          <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
        </ng-container>
      </div>
      <span class=" badge-custom {{requestitem.request_type_color_code}}">Loan Approval</span>
      <span *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
      class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>
    <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar>
    <div class="col-12">
      <p class="mb-0 fs-14" [innerHTML]="requestitem?.message">
    </div>
    <app-view-loader *ngIf="loader1"></app-view-loader>
    <div class="col-12" *ngIf="!loader1">
      <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
        <div class="row row-16 ">
          <div class="col-12 align-items-center d-flex">
            <p class="title mb-8">LOAN REQUEST DETAILS</p>
          </div>
          <div class="col-sm-4 col-md-3">
            <div class="detail-title">Name</div>
            <div class="detail-desc">{{requestitem?.requests?.from_person?.first_name +" " +(requestitem?.requests?.from_person?.middle_name != null? requestitem?.requests?.from_person?.middle_name: "") +" " +requestitem?.requests?.from_person?.last_name}}</div>
          </div>
          <div class="col-sm-4 col-md-3">
            <div class="detail-title">Employee code</div>
            <div class="detail-desc">{{requestitem?.requests?.from_person?.employee_code}}</div>
          </div>
          <div class="col-sm-4 col-md-3 col-12">
            <div class="detail-title">Loan type</div>
            <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.loan_type}}</div>
          </div>
          <div class="col-sm-4 col-md-3 col-12">
            <div class="detail-title">Interest type</div>
            <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.interest_type}}</div>
          </div>
          <div class="col-sm-4 col-md-3 col-12">
            <div class="detail-title">Maximum amount</div>
            <div class="detail-desc">{{requestitem?.requests?.amount_limit | currency : requestitem?.requests?.currency}}</div>
          </div>
          <div class="col-sm-4 col-md-3 col-12">
            <div class="detail-title">Maximum EMI</div>
            <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.max_number_emis}}</div>
          </div>
          <div class="col-12">
            <div class="detail-title">Eligibility</div>
            <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.loan_eligibility_days}} Days from Date of Joining</div>
          </div>
          <div class="col-sm-4 col-md-6 col-12">
            <div class="detail-title">Interest penalty on payment shortfall rate%</div>
            <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.interest_penality}}</div>
          </div>
          <div class="col-sm-4 col-md-3 col-12">
            <div class="detail-title">Loan amount</div>
            <div class="detail-desc">{{requestitem?.requests?.loan_amount | currency : requestitem?.requests?.currency}}</div>
          </div>
          <div class="col-sm-4 col-md-3 col-12">
            <div class="detail-title">Number of EMI</div>
            <div class="detail-desc">{{requestitem?.requests?.no_emis}}</div>
          </div>
          <div class="col-sm-4 col-md-3 col-12">
            <div class="detail-title">Loan required date</div>
            <div class="detail-desc">{{requestitem?.requests?.requested_date != null ? this.appservice.dateFormatDisplay(requestitem?.requests?.requested_date) : '-'}}</div>
          </div>
          <div class="col-12">
            <div class="detail-title">Remarks</div>
            <div class="detail-desc">{{requestitem?.requests?.remark != null ? requestitem?.requests?.remark : '-'}}</div>
          </div>
          <div class="col-12">
            <div class="detail-title mb-8">Document</div>
            <div class="detail-desc" *ngIf="requestitem?.requests?.document == ''">-</div>
            <div style="width:12.9375rem;" *ngIf="requestitem?.requests?.document != ''">
              <div class="p-12 bg-secondary radius-8 d-flex align-items-center">
                <i class="bi bi-file-earmark-text-fill me-16 flex-center text-accent2 fs-40"></i>
                <div class="d-flex flex-column justify-content-start gap-8 fs-12 fw-500 overflow-hidden">
                  <span class="tips text-uppercase fs- fw-700 text-trim"
                    *ngIf="requestitem?.requests?.document != ''">{{
                    this.appservice.getDocName(requestitem?.requests?.document) }}</span>
                  <span class="tips-helper " *ngIf="requestitem?.requests?.document != ''">{{
                    this.appservice.getDocName(requestitem?.requests?.document) }}</span>
                  <div class="d-flex gap-8">
                    <div class="link-primary" (click)="viewDocument(requestitem?.requests?.document)">
                      <i class="bi bi-eye me-1"></i>View
                    </div>
                    <div class="link-primary"
                      (click)="this.appservice.downloadFile(requestitem?.requests?.document,this.appservice.getDocName(requestitem?.requests?.document))">
                      <i class="bi bi-download me-1"></i>Download
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
        <div class="row row-16">
          <div class="col-12 d-flex">
            <p class="title mb-8">Existing loans</p>
            <a (click)="viewExisting()" class="ms-auto fs-12 fw-500 link-primary1" *ngIf="requestitem?.requests?.total_loans != 0;">View Details</a>
          </div>
          <ng-container *ngIf="requestitem?.requests?.total_loans == 0; else data">
            <div class="col-12 text-center detail-title">
              <p class="fs-12">No existing loans</p>
            </div>
          </ng-container>
          <ng-template #data>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title"> Number of loans</div>
              <div class="detail-desc">{{requestitem?.requests?.total_loans}}</div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title"> Total outstanding amount</div>
              <div class="detail-desc">{{requestitem?.requests?.total_outstanding == null ? 0 : requestitem?.requests?.total_outstanding | currency : requestitem?.requests?.currency}}</div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <form class="mt-20" [formGroup]="form">
      <ng-container *ngIf="!requestitem?.achieved_status">
        <div class="col-12">
          <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..."
            class="form-control mb-8 textarea-comment" formControlName="comment"
            [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
          <div *ngIf="f.comment.errors" class="invalid-feedback">
            <div *ngIf="f.comment.errors?.maxlength">{{this.messageService.fieldlengthvalidation('word','500')}}
            </div>
          </div>
        </div>
        <div class="col-12 d-flex gap-16">
          <button type="button" class=" ms-auto btn btn-outline-danger  text-uppercase btn-sm fw-400"
            (click)="confirm('Rejected')">Decline</button>
          <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400"
            (click)="confirm('Approved')">Confirm</button>
        </div>
      </ng-container>
    </form>
  </div>
</div>

<!-- View Status -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>
    </form>
  </div>
</ng-container>

<!-- un supported view format alert -->
<app-alert-toggle *ngIf="alertToggle==true" (modals)="closed($event)" [alertToggle]="alertToggle"
  [alertMsg]="investalertMsg"></app-alert-toggle>

<!-- Viewing the Document -->
<app-view-file *ngIf="modalToggle== true" (modal)="close($event)" [modalToggle]="modalToggle"
  [currentDocName]="currentDocName" [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>

<!-- View Existing Loan Details -->
<app-view-loan-details *ngIf="viewExistingLoan" [from]="'loanInbox'" [viewDetails]="viewExistingLoan" [navData]="navData" [loanData]="viewExistingData" (close)="panelClose($event)" [loader]="billLoader"></app-view-loan-details>
