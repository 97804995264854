<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Payroll Configuration</h3>
      <p class="text-helper mb-md-0">This page enables you to configure payroll settings based on the companies you
        added under organization structure.</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <div class="" style="width:22.6875rem;">
          <app-searchbar [searchlist]="searchlistdata" [searchplaceholder]='searchString'
            (searchEmitter)="searchresult($event)" (keywordsearchEmitter)="searchconfig($event)" [isLoading]="isLoading">
          </app-searchbar>
        </div>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';selectItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
          </div>
        </div>
        <button *ngIf="permissions.a" class="btn flex-shrink-0 btn-primary btn-icon btn-add"
          routerLink="/configuration/configuration-setup"><i class="icon-plus"></i>ADD CONFIGURATION</button>
      </div>
    </div>
    <!-- <div class="col-12 mt-16" *ngIf="alertInfo">
            <div role="alert" class="alert alert-warning mb-0 show">
                <p class="mb-0 fs-14">This page enables you to configure payroll settings based on the companies you
                    added under general configuration.</p>
                <div class="btn-close pointer" (click)="alertInfo=false"></div>
            </div>
        </div> -->
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16 mb-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>

  <div *ngIf="nodata && !loader;else all_data" class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg50.png'" [noDataText]="'Start Configuring Your Payroll Settings '"
      [noDataPara]="'Set up payroll schedules, investment declarations, reimbursement options and more for streamlined payroll management. Use \‘Add Configuration\’ button to customize payroll settings to fit your company\’s needs.'"
      [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngFor="let company of items,let i = index">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div class="card card-custom1 card-hover">
            <div class="card-body p-0 d-flex align-items-start">
              <div class="d-flex view-section" (click)="detailedView(company.id)">
                <div *ngIf="company.logo == '' || company.logo == null" class="bg{{(i+1)%5}} sq-48 avatar-box me-16">
                  {{this.appService.getFirstChar(company.company_name,2)}}</div>
                <img *ngIf="company.logo != '' && company.logo != null" class="logo-img img-fluid"
                  src="{{company.logo}}">
                <!-- <div  class="logo-img bg{{i%5}}">SW</div> -->
                <div class="overflow-hidden me-auto">
                  <h6 class="mb-0 tips">{{company.company_name}}</h6>
                  <span class="tips-helper">{{company.company_name}}</span>
                  <span
                    class="fs-10 text-uppercase fw-500 {{(company.is_active == true) ? 'text-success' : 'text-danger'}}">{{(company.is_active==
                    true) ? 'Active' : 'Deleted'}}
                  </span>
                </div>
              </div>
              <div ngbDropdown class="d-inline-block dropdown-section"
                *ngIf="company.is_active == true && permissions?.e">
                <button class="btn-reset p-0 h-100" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                  <i class="icon-three-dots-vertical fs-16"></i>
                </button>
                <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                  <button ngbDropdownItem (click)="editCompany(company.id)">
                    <i class="icon-pencil-square me-1"></i> Edit
                  </button>
                  <!-- <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem
                                        (click)="deleteToggle=true;deleteId=company.id">
                                        <i class="icon-trash  me-1"></i> Delete
                                    </button> -->
                </div>
              </div>

            </div>


          </div>


        </div>

      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
  </ng-template>
  <!-- <div class="loader text-center" [style.display]="infinityloader">
        <img class="animate-rotate" src="assets/icons/Light/Large/Spinner.svg">
    </div> -->
  <!-- <app-infinity-loader  *ngIf="infinityloader"></app-infinity-loader>  -->


</div>

<div *ngIf="permissions" class="side-panel" style="--sidepanel-width:45rem;"
  [class.side-pane-active]='viewConfiguration === true'>
  <form class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewConfiguration = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">Payroll Configuration</p>
            <a *ngIf="viewData?.is_active && permissions?.e"
              class="link-primary fs-14 d-flex align-items-center ms-auto" (click)="editCompany(viewData?.id)">
              <i class="icon-pencil-square me-1"></i> Edit
            </a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">GENERAL CONFIGURATION</span></div>
                  <div class="col-12">
                    <div class="detail-title">Have you run payroll earlier this financial year?</div>

                      <div class="detail-desc"><i
                        class="bi-record-circle-fill me-8 text-light-500"></i>{{viewData?.prior_payroll==true?'Yes, We have already run payroll this
                      financial year':'No, we are going to run our first payroll'}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">PAYROLL SCHEDULE</span></div>

                  <div class=" col-sm-6 col-12">
                    <div class="detail-title">Company name</div>
                    <div class="detail-desc">{{viewData?.company_name}}</div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span class="fs-12 fw-500 text-success text-uppercase" *ngIf="viewData?.is_active">ACTIVE</span>
                      <span class="fs-12 fw-500 text-danger text-uppercase"
                        *ngIf="viewData?.is_active==false">DELETED</span>
                    </div>
                  </div>
                  <!-- <div class=" col-sm-6 col-12">
                                        <div class="detail-title">Have you run payroll earlier this financial year?</div>
                                        <div class="detail-desc">{{viewData?.prior_payroll==true?'Yes, we have already run payroll this financial year':'No, we are going to run our first payroll'}}</div>
                                    </div> -->
                  <div class=" col-sm-6 col-12">
                    <div class="detail-title">Pay frequency</div>
                    <div class="detail-desc">{{viewData?.pay_frequency}}</div>
                  </div>

                  <div class=" col-sm-6 col-12">
                    <div class="detail-title">Which pay cycle you want to start using SkizzleHR
                    </div>
                    <div class="detail-desc">{{viewData?.first_pay_cycle}} {{viewData?.first_pay_year}}</div>
                  </div>
                  <!-- <div class=" col-sm-6 col-12">
                    <div class="detail-title">Pay period</div>
                    <div class="detail-desc">
                      {{viewData?.pay_period?ordinal_suffix_of(viewData.pay_period_day):'Calendar days'}}</div>
                  </div>  -->
                  <div class=" col-sm-6 col-12">
                    <div class="detail-title">Pay day</div>
                    <div class="detail-desc">{{viewData?.pay_day_status?ordinal_suffix_of(viewData.pay_day):'Last day of
                      the month'}} </div>
                  </div>
                  <div class="col-12">
                    <div class="fs-14 fw-500">Work days calculation </div>
                  </div>


                  <div class=" col-sm-12 col-12">
                    <div class="detail-title">How do you want to capture employee's attendance details for payroll?
                    </div>
                    <div class="detail-desc"><i
                        class="bi-record-circle-fill me-8 text-light-500"></i>{{viewData?.attendance_lop==false?'Automatically
                      fetch the attendance details from the time and attendance module':'Manually upload the attendance
                      details'}}</div>
                  </div>
                  <ng-container *ngIf="viewData?.attendance_lop==true">
                    <div class=" col-sm-12 col-12">
                      <div class="detail-title">Pay cycle</div>
                      <div class="detail-desc">{{viewData?.work_day_status?(viewData.work_day):'Calendar days'}}</div>
                    </div>
                  </ng-container>
                  <div class="col-12">
                    <div class="detail-title">Workdays in a month for calculating one day’s salary</div>
                    <div class="detail-desc">
                      {{viewData?.pay_period?(viewData.pay_period_day):'As per pay cycle'}}</div>
                  </div>
                  <ng-container *ngIf="viewData?.attendance_lop==true">

                    <div class=" col-sm-12 col-12">
                      <div class="detail-title">Exclude weekoff’s </div>
                      <div class="detail-desc">{{viewData?.exclude_weekoff_status==true?'Yes':'No'}}</div>

                      <div class="detail-desc d-flex gap-4 flex-wrap mt-2"
                        *ngIf="viewData?.exclude_weekoff_status && viewData?.exclude_weekoff!=null && viewData?.exclude_weekoff!=''">
                        <ng-container *ngFor="let rights of viewData?.exclude_weekoff, let i=index;">
                          <span class="badge3" title="{{rights}}"> {{ (rights.length>25)? (rights |
                            slice:0:25)+'..':(rights) }}
                          </span>
                        </ng-container>
                      </div>
                      <!-- <div class="detail-desc" *ngIf="!(viewData?.exclude_weekoff_status)">No</div> -->

                    </div>
                  </ng-container>


                  <!-- <ng-container *ngIf="viewData?.attendance_lop==false">

                                    <div class=" col-sm-6 col-12">
                                        <div class="detail-title">Exclude holidays</div>
                                        <div class="detail-desc">{{viewData?.exclude_holiday_status==true?'Yes':'No'}}</div>
                                    </div>

                                    </ng-container> -->
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">INVESTMENT DECLARATION</span></div>
                  <!-- <div class=" col-12">
                                        <div class="detail-title">Cut-off date for Proposed investment declaration in a month</div>
                                        <div class="detail-desc">{{ordinal_suffix_of(viewData?.proposed_declaration_cuttoff)}}</div>
                                    </div> -->
                  <div class="col-12 fs-14">Cut-off date for investment declaration in a month</div>
                  <div class=" col-sm-6 col-12 mt-8">
                    <div class="detail-title">From</div>
                    <div class="detail-desc">{{viewData?.proposed_declaration_fromdate == null? '-'
                      :ordinal_suffix_of(viewData?.proposed_declaration_fromdate)}}</div>
                  </div>
                  <div class=" col-sm-6 col-12 mt-8">
                    <div class="detail-title">To</div>
                    <div class="detail-desc">{{viewData?.proposed_declaration_todate == null ? '-' :
                      ordinal_suffix_of(viewData?.proposed_declaration_todate)}}</div>
                  </div>
                  <div class="col-12 fs-14">Cut-off date for proposed investment declaration in a financial year </div>
                  <div class=" col-sm-6 col-12 mt-8">
                    <div class="detail-title">Month</div>
                    <div class="detail-desc">{{viewData?.proposed_declaration_fy_month}}</div>
                  </div>
                  <div class=" col-sm-6 col-12 mt-8">
                    <div class="detail-title">Date</div>
                    <div class="detail-desc">{{ordinal_suffix_of(viewData?.proposed_declaration_fy_date)}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Require approval of any changes in proposed investment declaration
                      submitted before financial year cut-off date </div>
                    <div class="detail-desc">{{viewData?.proposed_required_approval_beforecutoff==true?'Yes':'No'}}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title">Allow employee to submit proposed investment declaration after financial
                      year cut-off date </div>
                    <div class="detail-desc">{{viewData?.proposed_allow_submit_aftercuttoff==true?'Yes':'No'}}</div>
                  </div>
                  <div class="col-12" *ngIf="viewData?.proposed_allow_submit_aftercuttoff==true">
                    <div class="detail-title">Require approval of any changes in proposed investment declaration after
                      the financial year cut-off date </div>
                    <div class="detail-desc">{{viewData?.proposed_required_approval_aftercuttoff==true?'Yes':'No'}}
                    </div>
                  </div>
                  <div class="col-12 fs-14">Cut-off date for confirmed investment declaration in a financial year </div>
                  <div class=" col-sm-6 col-12 mt-8">
                    <div class="detail-title">Month</div>
                    <div class="detail-desc">{{viewData?.confirmed_declaration_fy_month}}</div>
                  </div>
                  <div class=" col-sm-6 col-12 mt-8">
                    <div class="detail-title">Date</div>
                    <div class="detail-desc">{{ordinal_suffix_of(viewData?.confirmed_declaration_fy_date)}}</div>
                  </div>
                  <div class="col-12">
                    <div class="detail-title"></div>
                    <div class="detail-desc"></div>
                  </div>
                  <div class=" col-sm-6 col-12">
                    <div class="detail-title">Do you want to show the confirmed investment alert in the announcement?
                    </div>
                    <div class="detail-desc">{{viewData?.send_confirm_investment_alert==true?'Yes':'No'}} </div>
                  </div>
                  <div class=" col-sm-6 col-12" *ngIf="viewData?.send_confirm_investment_alert==true">
                    <div class="detail-title">Pay period</div>
                    <div class="detail-desc">
                      {{viewData?.specify_days}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">LOAN</span></div>
                  <div class=" col-12">
                    <div class="detail-title">Can the loan amount and EMI be edited by task owner when processing the loan</div>
                    <div class="detail-desc">{{viewData?.can_edit_loanAmount_emi_by_owner == false ? 'No' : 'Yes'}}</div>
                  </div>
                  <div class=" col-12">
                    <div class="detail-title">Can the loan interest be edited by the task owner when processing the loan</div>
                    <div class="detail-desc">{{viewData?.can_edit_loanInterest_by_owner == false ? 'No' : 'Yes'}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">REIMBURSEMENT AND FBP</span></div>
                  <div class=" col-12">
                    <div class="detail-title">Monthly entitlement day </div>
                    <div class="detail-desc">{{viewData?.reimbursement_entitlement_day == null ? '-' :
                      ordinal_suffix_of(viewData?.reimbursement_entitlement_day)}}</div>
                  </div>
                  <div class="col-12 fs-14">Cut-off date for reimbursement claim request in a month</div>
                  <div class=" col-sm-6 col-12 mt-8">
                    <div class="detail-title">From</div>
                    <div class="detail-desc">{{viewData?.reimbursement_request_fromdate == null ? '-' :
                      ordinal_suffix_of(viewData?.reimbursement_request_fromdate)}}</div>
                  </div>
                  <div class=" col-sm-6 col-12 mt-8">
                    <div class="detail-title">To</div>
                    <div class="detail-desc">{{viewData?.reimbursement_request_todate == null ? '-' :
                      ordinal_suffix_of(viewData?.reimbursement_request_todate)}}</div>
                  </div>
                  <div class=" col-12">
                    <div class="detail-title">Specify maximum duration for FBP declaration </div>
                    <div class="detail-desc">{{viewData?.fbp_duration_day == null ? '-' : viewData?.fbp_duration_day}}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="fs-14 fw-500">Reimbursement type</div>
                  </div>
                  <div class="col-12 d-flex mt-8">
                    <i class="bi bi-record-circle-fill text-light-400 me-8"></i>
                    <span class="fs-12">{{viewData?.reimbursement_type}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>
<!-- <div class="modal  modal-alert {{deleteToggle == true ? 'show': ''}}" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header gap-16">
                <i class="bi bi-exclamation-triangle text-danger"></i>
                <h4 class="modal-title">Delete Company?</h4>
            </div>
            <div class="modal-body">
                <p class="mb-0">{{this.messageService.Deletemsgdisplay('company')}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary btn-sm"
                    (click)="deleteToggle = false">Cancel</button>
                <button type="button" class="btn btn-danger btn-sm" [disabled]="deleteClicked"
                    (click)="confirmDelete()">Delete</button>
            </div>
        </div>
    </div>
</div> -->
