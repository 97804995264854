<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row mb-24">
    <div class="col-md-4 col-lg-6">
      <h3>Form 16</h3>
      <p class="text-helper mb-md-0">
        Form 16 is a TDS certificate that certifies the employee's salary earned during the year
      </p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end gap-16">
        <!-- <div class="" style="width: 363px">
            <div class="">
              <app-searchbar [searchlist]='searchlistdata' [searchplaceholder]="selectkey" (searchEmitter)="searchresults($event)" (keywordsearchEmitter)="searchform16($event)"></app-searchbar>
            </div>

          </div> -->
        <div class=" d-inline-flex">
          <app-financial-year [(ngModel)]="selectedYear" (selectYear)="yearChange($event)"></app-financial-year>
        </div>
        <!-- <div ngbDropdown class="d-inline-block">
            <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
                <i class="icon-filter-left fs-13"></i>
            </button>
            <div ngbDropdownMenu class="dropdown-menu-end" >
              <button ngbDropdownItem [class]="{'active':currentFilterYear=='all' }" (click)="yearFilter('all')" >All</button>
              <button ngbDropdownItem [class]="{'active':currentFilterYear=='previous year' }" (click)="yearFilter('previous year')">Previous Year</button>
              <button ngbDropdownItem [class]="{'active':currentFilterYear=='current year' }" (click)="yearFilter('current year')">Current Year</button>
            </div>
          </div> -->

      </div>
    </div>
  </div>

  <!-- Loader updations -->
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-md-6" [cardType]="'card10'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <!--No data found-->
  <div *ngIf="(nodatafound && !loader);else all_data" class="row" style="height: calc(100vh - 11rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg8.png'" [noDataText]="'Oops! Form 16 not found.'"
      [noDataPara]="'It looks like Form 16 is not yet generated for the chosen year. Select a different year or check back later!'"
      [hasPermission]="false"> </app-nodata-view>
  </div>
  <!--End-->
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <ng-container *ngIf="loader==false">
        <div class="col-md-3 col-sm-6" *ngFor="let data of form16List let i = index">
          <div class="card card-c5">
            <div class="card-body fs-16 {{data.color_code}} flex-center">{{data.year}}</div>
            <div class="card-body p-16">
              <div class="fs-14">Form 16</div>
              <div class="d-flex mt-2 gap-8 ">
                <a class="d-flex align-items-center fs-12 fw-500 link-primary"
                  (click)="this.appService.downloadFile(data.data,'Form 16 '+data.year)">
                  <i class="bi bi-download me-8 fs-12"></i>Download</a>
                <a class="d-flex align-items-center fs-12 fw-500 link-primary"
                  (click)="errorToggle=true; viewDocument(data.data);">
                  <i class="bi bi-eye me-8 fs-12"></i>View</a>
              </div>
            </div>
          </div>
        </div>

      </ng-container>

      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-md-6" [cardType]="'card10'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>
    </div>
  </ng-template>

  <!-- Viewing the Document -->
  <ng-container *ngIf="modalToggle== true">
    <app-view-file (modal)="close($event)" [modalToggle]="modalToggle" [currentDocName]="currentDocName"
      [currentExtension]="currentExtension" [fileName]="fileName"></app-view-file>
  </ng-container>
