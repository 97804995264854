import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/app.global';
import { digitdecimalValidator, noDecimalValidator } from '../../validators/2digitdecimal.validators';
import { MessageService } from 'src/app/message.global';
import { LoanProcessService } from 'src/app/@core/services/loan-process.service';
import * as moment from 'moment';
@Component({
  selector: 'app-view-loan-details',
  templateUrl: './view-loan-details.component.html',
  styleUrls: ['./view-loan-details.component.scss']
})
export class ViewLoanDetailsComponent implements OnInit {

  // Inputs
  @Input() viewDetails : any
  @Input() loanData    : any
  @Input() from        : any = ''
  @Input() loader      : any
  @Input() navData     : any

  @Output() close  = new EventEmitter()
  // VAR initialisations
  editAdjust           : boolean = false;
  status              = "Reduce";
  viewAdjust           : boolean = false;
  submitted: boolean = false
  activeToggle         : any     = ''

  page            = 1
  pageSize        = 20

  adjustLoanData:any
  newLoanAmt = 0
  loanTypeDetails:any
  adjustData:any

  listOfMonth         = [ {id:'01',value:"January"},
    {id:'02',value:"February"},
    {id:'03',value:"March"},
    {id:'04',value:"April"},
    {id:'05',value:"May"},
    {id:'06',value:"June"},
    {id:'07',value:"July"},
    {id:'08',value:"August"},
    {id:'09',value:"September"},
    {id:'10',value:"October"},
    {id:'11',value:"November"},
    {id:'12',value:"December"},
  ];
  currentYear= new Date().getFullYear();
  yearList : any = [this.currentYear,this.currentYear+1,this.currentYear+2];
  reduceForm: FormGroup = this.fb.group({
    "request_status":"Reduce",
    "emi_id":[],
    "emi_reduce_type":false,
    "reducing_emi_amount":['', [Validators.required,Validators.min(0.1),digitdecimalValidator()]],
    "comment"          : [null,[Validators.required,Validators.maxLength(500)]],
  });

  SettledForm: FormGroup = this.fb.group({
    "request_status":"Settled",
    "loan_id":[],
    "outstanding_amount":[],
    "comment"          : [null,[Validators.required,Validators.maxLength(500)]],
  });

  RestructureForm: FormGroup = this.fb.group({
    "request_status":"Restructure",
    "loan_id":[],
    "amount_out_system":['', [Validators.required,Validators.min(0.1),digitdecimalValidator()]],
    "start_date":"2024-09-22",
    "comment": [null,[Validators.required,Validators.maxLength(500)]],
    "remaing_number_emis":12,
    "new_number_emis":  [null, [Validators.required, Validators.min(1)]],

  });
  monthForm:  FormGroup = this.fb.group({
    "year":  [null, [Validators.required]],
    "month":  [null, [Validators.required]],
  })


  constructor(
    public appServ     : AppService, public fb:FormBuilder, public messageService : MessageService,private LPS:LoanProcessService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes : SimpleChanges){
    if(changes['loanData'] && changes['loanData'].currentValue){
      this.activeToggle = this.loanData?.id
    }
  }

  get r() {return  this.reduceForm.controls; }
  get s() {return  this.SettledForm.controls; }
  get f() {return  this.RestructureForm.controls; }
  get m() {return  this.monthForm.controls; }

  setForms(loan:any){
    this.adjustLoanData = loan;
    this.yearList = [this.adjustLoanData.pay_year,this.adjustLoanData.pay_year+1,this.adjustLoanData.pay_year+2];
    this.editAdjust = true;
    this.setReduceForm();
  }
  setReduceForm(){
    this.submitted=false;
    this.status = "Reduce";
    this.reduceForm.controls['emi_id'].setValue(this.adjustLoanData?.id);
    this.reduceForm.controls['reducing_emi_amount'].setValue(this.adjustLoanData?.emi_amount);
    this.reduceForm?.get('reducing_emi_amount')?.setValidators([Validators.required,Validators.max(this.adjustLoanData?.emi_amount),digitdecimalValidator()])
  }
  setsettleForm(){
    this.submitted=false;
    this.status = "Settled";
    this.SettledForm.controls['loan_id'].setValue(this.loanData?.id);
    this.SettledForm.controls['outstanding_amount'].setValue(this.loanData?.to_be_recovered);
  }

  setrestructureForm(){
    this.LPS.getLoanTypeDetails(this.loanData?.loan_type_id).subscribe((res:any)=>{
      this.loanTypeDetails = res?.data
      // Validators.max(loanTypeDetails?.amount_limit),
      let minamt = this.loanData?.to_be_recovered - this.loanTypeDetails?.amount_limit
      this.RestructureForm.get('amount_out_system')?.setValidators([Validators.required, Validators.min(minamt),digitdecimalValidator(),Validators.max(this.loanData?.to_be_recovered)])
      this.RestructureForm.get('new_number_emis')?.setValidators([Validators.max(this.loanTypeDetails?.max_number_emis),Validators.required, Validators.min(1),noDecimalValidator()])
    })
    this.submitted=false;
    this.status = "Restructure";
    this.RestructureForm.controls['loan_id'].setValue(this.loanData?.id);
    this.RestructureForm?.get('amount_out_system')?.setValidators([Validators.required,Validators.max(this.loanData?.to_be_recovered),digitdecimalValidator()])

  }

  reduce(){
    this.LPS.adjust(this.reduceForm.value).subscribe((res:any)=>{
     console.log(res)
    });
  }
  settle(){
    this.LPS.adjust(this.SettledForm.value).subscribe((res:any)=>{
      console.log(res)
     });
  }
  restructure(){
    this.LPS.adjust(this.RestructureForm.value).subscribe((res:any)=>{
      console.log(res)
     });
  }
  calcNewLoan(){
    this.newLoanAmt = this.loanData?.to_be_recovered-this.RestructureForm.value.amount_out_system
  }
  viewAdjustFn(loan:any){
    this.adjustLoanData = loan;
    this.viewAdjust = true
    this.LPS.adjustDetails(loan.id).subscribe((res:any)=>{
      this.adjustData = res.body.data
     });
  }

  monthConversion(month:any){
    return moment().month(month).format("MM")
  }

  selectItemList(id:any){
    this.loader = true
    this.LPS.hrLoanDetails(id).subscribe((res:any)=>{
      this.loanData = res?.body.data[0]
      this.activeToggle = this.loanData?.id
      this.loader = false
    })
  }
  editAdjusted(){
    this.viewAdjust = false;
    this.setForms(this.adjustLoanData)
  }

  updatedate(){
    let sDate = this.m.year.value+"-"+this.m.month.value+"-"+'01'
    this.RestructureForm.controls['start_date'].setValue(sDate);

    if(this.adjustLoanData.pay_year == this.m.year.value && parseInt(this.monthConversion(this.adjustLoanData.pay_month)) >= parseInt(this.m.month.value)){
      this.RestructureForm.controls['start_date'].setErrors({'invalidDate': true});
      this.monthForm.controls['month'].setErrors({'invalidDate': true});      
    } else {
      this.monthForm.controls['month'].setErrors(null)
    }
  }
  
}
