<div class="container-fluid p-24">

  <div class="row row-16 ">
    <div class="col-lg-8 ">
      <h3>Salary Structure</h3>
      <p class="text-helper mb-md-0">View the breakup of your salary components that comprise your CTC.</p>
    </div>

    <app-table-loader *ngIf="loader"></app-table-loader>
    <ng-container *ngIf="!loader">

      <div *ngIf="salarystructdata.length == 0;else all_data" class="row" style="height: calc(100vh - 15rem);">
        <app-nodata-view class="vstack" [noDataImg]="'bg2.png'" [noDataText]="'Salary Structure Not Assigned'"
          [noDataPara]="'Details of your salary structure will be available here once it is assigned.Stay tuned!. '"
          [hasPermission]="false"> </app-nodata-view>
      </div>
      <ng-template #all_data>
        <div class="col-lg-4 d-flex fs-14 fw-500 gap-8 align-items-end justify-content-end">
          <span class="link-primary1 text-nowrap" (click)="downLoad(salaryData.pdf_url)"><i
              class="bi bi-download me-1"></i>Download salary structure</span>
          <span class="text-light-400">|</span>
          <span class="link-primary1 text-nowrap" (click)="salaryRevisionHistory = true"><i
              class="bi bi-eye me-1"></i>Salary revision history</span>

        </div>
        <div class="col-12 ">
          <div class="card card-c2 px-24 py-16 flex-row align-items-center justify-content-between">
            <div class="bg29 p-16 radius-4 d-flex flex-column " style="width:15rem">
              <div class="d-flex mb-10">
                <div class=" me-8 ">
                  <img class="avatar-circle sq-40 " src="{{empData?.profile_image}}"
                    *ngIf="(empData?.profile_image!='') && (empData?.profile_image!= null)">
                  <div class="avatar-circle sq-40 {{empData?.color_code}} avatar-border"
                    *ngIf="(empData?.profile_image =='') || (empData?.profile_image == null)">
                    {{this.appservice.getFirstChar(empData?.first_name,2)}}</div>
                </div>
                <div>
                  <div class="fs-14 fw-600 text-trim">{{empData?.first_name}} {{empData?.middle_name}}
                    {{empData?.last_name}}</div>
                  <div class="fs-11 fw-500 text-light-400">EMP CODE : {{empData?.employee_code}}</div>
                </div>
              </div>
              <div class="badge badge-success flex-center w-100  fs-12 fw-500 py-2">Effective date :
                {{this.appservice.dateFormatDisplay(salaryData?.effective_from)}}
              </div>
            </div>
            <div class="indicater4 indicater-bg2 ps-12 fs-12">
              <div class="text-uppercase text10 fw-500 mb-8">CTC</div>
              <div class=" text-light-500">Monthly : <span class="fw-500">{{salarystructdata?.ctc_monthly | currency:
                  salaryData.currency}}</span>
              </div>
              <div class=" text-light-500">Yearly : <span class="fw-500">{{salarystructdata?.ctc_yearly | currency:
                  salaryData.currency}}</span>
              </div>
            </div>
            <div class="indicater4 ps-12 indicater-bg10 fs-12">
              <div class="text-uppercase fw-500 mb-8 text-accent1">GROSS</div>
              <div class=" text-light-500">Monthly : <span class="fw-500">{{salarystructdata?.gross_monthly | currency:
                  salaryData.currency}}</span>
              </div>
              <div class=" text-light-500">Yearly : <span class="fw-500">{{salarystructdata?.gross_yearly | currency:
                  salaryData.currency}}</span>
              </div>
            </div>
            <div class="indicater4 ps-12 indicater-bg4 fs-12">
              <div class="text-uppercase text7 fw-500 mb-8">DEDUCTIONS</div>
              <div class=" text-light-500">Monthly : <span class="fw-500">{{salarystructdata?.deduction_monthly |
                  currency:
                  salaryData.currency}}</span></div>
              <div class=" text-light-500">Yearly : <span class="fw-500">{{salarystructdata?.deduction_yearly |
                  currency:
                  salaryData.currency}}</span></div>
            </div>
            <div class="indicater4 ps-12 fs-12">
              <div class="text-uppercase text-accent2 fw-500 mb-8">NET TAKE HOME</div>
              <div class=" text-light-500">Monthly : <span class="fw-500">{{salarystructdata?.net_take_home_monthly |
                  currency:
                  salaryData.currency}}</span></div>
              <div class=" text-light-500">Yearly : <span class="fw-500">{{salarystructdata?.net_take_home_yearly |
                  currency:
                  salaryData.currency}}</span></div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card card-c2">
            <div class="card-body p-0" *ngIf="gross_benefits?.length>0">
              <ngb-accordion class="custom-accordion1 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-1">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button fw-500" *ngIf="gross_benefits?.length>0">
                      Gross benefits
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card card-c2 overflow-hidden">
                      <div class="table-responsive">
                        <table class="table table-striped  table-sm ">
                          <thead>
                            <tr>
                              <th scope="col">Component Name</th>
                              <th scope="col">Monthly PAY</th>
                              <th scope="col">Yearly PAY</th>
                              <!-- <th scope="col">Month</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let gross_benefits of gross_benefits;let i=index">

                              <td>{{gross_benefits?.name}}</td>
                              <td>{{gross_benefits?.monthly_renum | currency: salaryData.currency}}</td>
                              <td>{{gross_benefits?.year_renum | currency: salaryData.currency}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="card-body p-0" *ngIf="other_benefits?.length>0">
              <ngb-accordion class="custom-accordion1 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-1">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button fw-500" *ngIf="other_benefits?.length>0">
                      Other benefits
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card card-c2 overflow-hidden">
                      <div class="table-responsive">
                        <table class="table table-striped  table-sm ">
                          <thead>
                            <tr>
                              <th scope="col">Component Name</th>
                              <th scope="col">Monthly PAY</th>
                              <th scope="col">Yearly PAY</th>
                              <!-- <th scope="col">Month</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let other_benefits of other_benefits;let i=index">

                              <td>{{other_benefits?.name}}</td>
                              <td>{{other_benefits?.monthly_renum | currency: salaryData.currency}}</td>
                              <td>{{other_benefits?.year_renum | currency: salaryData.currency}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="card-body p-0" *ngIf="contributions?.length>0">
              <ngb-accordion class="custom-accordion1 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-1">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button fw-500" *ngIf="contributions?.length>0">
                      Contribution/Retirals
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card card-c2 overflow-hidden">
                      <div class="table-responsive">
                        <table class="table table-striped  table-sm ">
                          <thead>
                            <tr>
                              <th scope="col">Component Name</th>
                              <th scope="col">Monthly PAY</th>
                              <th scope="col">Yearly PAY</th>
                              <!-- <th scope="col">Month</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let contribution of contributions;let i=index">
                              <td>{{contribution?.name}}</td>
                              <td>{{contribution?.monthly_renum | currency: salaryData.currency}}</td>
                              <td>{{contribution?.year_renum | currency: salaryData.currency}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
            <div class="card-body p-0" *ngIf="recurring_deductions?.length>0">
              <ngb-accordion class="custom-accordion1 border-0" #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel id="ngb-panel-1">
                  <ng-template ngbPanelHeader>
                    <button ngbPanelToggle class="accordion-button fw-500" *ngIf="recurring_deductions?.length>0">
                      Recurring deductions
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="card card-c2 overflow-hidden">
                      <div class="table-responsive">
                        <table class="table table-striped  table-sm ">
                          <thead>
                            <tr>
                              <th scope="col">Component Name</th>
                              <th scope="col">Monthly PAY</th>
                              <th scope="col">Yearly PAY</th>
                              <!-- <th scope="col">Month</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let recurring_deductions of recurring_deductions;let i=index">
                              <td>{{recurring_deductions?.name}}</td>
                              <td>{{recurring_deductions?.monthly_renum | currency: salaryData.currency}}</td>
                              <td>{{recurring_deductions?.year_renum | currency: salaryData.currency}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
<!-- SALARY REVISION HISTORY VIEW START -->
<div class="side-panel" style="--sidepanel-width:59.0625rem;" [class.side-pane-active]='salaryRevisionHistory === true'>
  <div class="side-panel-container">
    <header class="side-panel-head">
      <h5>Salary revision history </h5>
      <a class="toggle-panel" (click)="salaryRevisionHistory = false"><i class=" icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <app-salary-revision-history-view *ngIf='salaryRevisionHistory === true' [employeeId]="employeeId"
        [buttonActivate]="false" [from]="from"></app-salary-revision-history-view>
    </div>
  </div>
</div>
<!-- SALARY REVISION HISTORY VIEW end -->
