import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';
import { EmployeeImportSetupComponent } from './employee/employee-import-setup/employee-import-setup.component';
import { EmployeeComponent } from './employee/employee/employee.component';
import { Form16SetupComponent } from './form-16/form16-setup/form16-setup.component';
import { Form16Component } from './form-16/form16/form16.component';
import { FreezPublishSalaryComponent } from './run-payroll/freez-publish-salary/freez-publish-salary.component';

// import { ImportAttendanceComponent } from './run-payroll/import-attendance/import-attendance.component';
// import { PayrollComponent } from './salary-dashboard/payroll/payroll.component';
import { ProcessSalaryComponent } from './run-payroll/process-salary/process-salary.component';
import { RemindersComponent } from './run-payroll/reminders/reminders.component';
// import { SalaryReconciliationComponent } from './salary-dashboard/salary-reconciliation/salary-reconciliation.component';
import { SalaryStructureSetupComponent } from './salary/salary-structure-setup/salary-structure-setup.component';
import { SalaryComponent } from './salary/salary/salary.component';
import { EmployeeHrEditComponent } from './employee/employee-hr-edit/employee-hr-edit.component';
import { TemplateComponent } from './template/template/template.component';
import { TemplateSetupComponent } from './template/template-setup/template-setup.component';
import { EmployeeReportComponent } from './reports/employee/employee-report/employee-report.component';
import { EmployeeReportConfigureComponent } from './reports/employee/employee-report-configure/employee-report-configure.component';
import { ScheduledReportsComponent } from './scheduled-reports/scheduled-reports.component';
import { ReportSetupComponent } from './reports/report-setup/report-setup.component';
import { ErrorComponent } from './error/error.component';
import { SalaryDashboardComponent } from './salary-dashboard/salary-dashboard/salary-dashboard.component';
import { PayrollReportComponent } from './reports/payroll/payroll-report/payroll-report.component';
import { PayrollReportConfigureComponent } from './reports/payroll/payroll-report-configure/payroll-report-configure.component';
import { DeacivateGuard } from 'src/app/@core/auth/deacivate.guard';
import { PayslipTemplateComponent } from './template/payslip-template/payslip-template.component';
import { TaxslipTemplateComponent } from './template/taxslip-template/taxslip-template.component';
import { SalaryRevisionComponent } from './salary-revision/salary-revision.component';
import { SalaryRevisionSetupComponent } from './salary-revision/salary-revision-setup/salary-revision-setup.component';
import { AttendanceAndLopComponent } from './run-payroll/attendance-and-lop/attendance-and-lop.component';
import { RunPayrollComponent } from './run-payroll/run-payroll.component';
import { SalaryReconciliationComponent } from './run-payroll/salary-reconciliation/salary-reconciliation.component';
import { AlertsComponent } from './run-payroll/alerts/alerts.component';
// import { ReminderComponent } from './run-payroll/reminder/reminder.component';
import { SalaryRevisionArrearsComponent } from './run-payroll/salary-revision-arrears/salary-revision-arrears.component';
import { VariablePaymentsAndDeductionsComponent } from './run-payroll/variable-payments-and-deductions/variable-payments-and-deductions.component';
import { OverridePtEsiLwsTdsComponent } from './run-payroll/override-pt-esi-lws-tds/override-pt-esi-lws-tds.component';
import { PublishSalaryComponent } from './run-payroll/publish-salary/publish-salary.component';
import { HeldSalaryComponent } from './run-payroll/held-salary/held-salary.component';
import { IncomeTaxComponent } from './income-tax/income-tax.component';
import { ChallanDetailsComponent } from './income-tax/challan-details/challan-details.component';
import { ChallanSetupComponent } from './income-tax/challan-setup/challan-setup.component';
import { ChallanUpdateComponent } from './income-tax/challan-update/challan-update.component';
import { PriorPayrollComponent } from './prior-payroll/prior-payroll/prior-payroll.component';

import { PriorPayrollSetupComponent } from './prior-payroll/prior-payroll-setup/prior-payroll-setup.component';
import { FinalSettlementComponent } from './final-settlement/final-settlement/final-settlement.component';
import { FinalSettlementSetupComponent } from './final-settlement/final-settlement-setup/final-settlement-setup.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { InviteEmployeeComponent } from './invite-employee/invite-employee.component';
import { SendNewUserComponent } from './invite-employee/send-new-user/send-new-user.component';
import { ResetOldUserComponent } from './invite-employee/reset-old-user/reset-old-user.component';
import { ShiftMappingComponent } from './employee-shift/shift-mapping/shift-mapping.component';
import { ShiftMappingSetupComponent } from './employee-shift/shift-mapping-setup/shift-mapping-setup.component';
import { LeaveYearEndComponent } from './leave-year-end/leave-year-end.component';
import { LeaveYearEndDetailsComponent } from './leave-year-end/leave-year-end-details/leave-year-end-details.component';
import { PendingLeaveRequestComponent } from './leave-year-end/pending-leave-request/pending-leave-request.component';
import { EmployeeAttendanceComponent } from './employee-attendance/employee-attendance.component';
import { EmployeeAttendanceViewComponent } from './employee-attendance/employee-attendance-view/employee-attendance-view.component';
import { LeaveBalanceComponent } from './leave-balance/leave-balance.component';
import { LeaveBalanceViewComponent } from './leave-balance/leave-balance-view/leave-balance-view.component';
import { ChallanComponent } from './income-tax/challan/challan.component';
import { ReturnsComponent } from './income-tax/returns/returns.component';
import { ShiftMappingImportComponent } from './employee-shift/shift-mapping-import/shift-mapping-import.component';
import { FinancialYearPendingRequestComponent } from './run-payroll/financial-year-pending-request/financial-year-pending-request.component';
import { FinancialYearPendingRequestViewComponent } from './run-payroll/financial-year-pending-request/financial-year-pending-request-view/financial-year-pending-request-view.component';
import { LeaveBalanceImportComponent } from './leave-balance/leave-balance-import/leave-balance-import.component';
import { LeaveReportComponent } from './reports/leave/leave-report/leave-report.component';
import { LeaveReportConfigureComponent } from './reports/leave/leave-report-configure/leave-report-configure.component';
// import { LocationComponent } from './location/location.component';
import { AttendanceReportsComponent } from './reports/attendance/attendance-reports/attendance-reports.component';
import { AttendanceReportConfigureComponent } from './reports/attendance/attendance-report-configure/attendance-report-configure.component';
import { LiveLocationComponent } from './live-location/live-location.component';
import { LocationComponent } from './location/location.component';
import { GeoLocationComponent } from './location/geo-location/geo-location.component';
import { AssignGeoLocationComponent } from './location/assign-geo-location/assign-geo-location.component';
import { AssignEmployeeLocationSetupComponent } from './location/assign-employee-location-setup/assign-employee-location-setup.component';
import { ExpenseManagementComponent } from './expense-management/expense-management.component';
import { ExpenseReimbursementComponent } from './run-payroll/expense-reimbursement/expense-reimbursement.component';
import { PolicyDocumentsComponent } from './policy-documents/policy-documents/policy-documents.component';
import { ManageDocumentsSetupComponent } from './policy-documents/manage-documents-setup/manage-documents-setup.component';
import { ManageDocumentsComponent } from './policy-documents/manage-documents/manage-documents.component';
import { DocumentsCategoryComponent } from './policy-documents/documents-category/documents-category.component';
import { AttendanceDashboardComponent } from './attendance-dashboard/attendance-dashboard.component';
import { AttendanceAlertComponent } from './attendance-dashboard/attendance-alert/attendance-alert.component';
import { AttendanceReminderComponent } from './attendance-dashboard/attendance-reminder/attendance-reminder.component';
import { PortalAccessComponent } from './portal-access/portal-access.component';
import { ExpenseReportComponent } from './reports/expense/expense-report/expense-report.component';
import { ExpenseReportConfigureComponent } from './reports/expense/expense-report-configure/expense-report-configure.component';
import { ManageAdvanceComponent } from './manage-advance/manage-advance.component';
import { OutstandingAdvanceDetailsComponent } from './manage-advance/outstanding-advance-details/outstanding-advance-details.component';
import { EmployeeLettersComponent } from './employee-letters/employee-letters.component';
import { ChallanGenerationComponent } from './challan-generation/challan-generation.component';

import { ManageAssetComponent } from './manage-asset/manage-asset.component';

import { AssetReportComponent } from './reports/asset/asset-report/asset-report.component';
import { AssetReportConfigureComponent } from './reports/asset/asset-report-configure/asset-report-configure.component';
import { LeaveReportConfigurationComponent } from './reports/leave/leave-report-configuration/leave-report-configuration.component';
import { EmployeeReportConfigurationComponent } from './reports/employee/employee-report-configuration/employee-report-configuration.component';
import { AssetReportConfigurationComponent } from './reports/asset/asset-report/asset-report-configuration/asset-report-configuration.component';
import { ExpenseReportConfigurationComponent } from './reports/expense/expense-report-configuration/expense-report-configuration.component';
import { PayrollReportConfigurationComponent } from './reports/payroll/payroll-report-configuration/payroll-report-configuration.component';
import { AttendanceReportConfigurationComponent } from './reports/attendance/attendance-report-configuration/attendance-report-configuration.component';
import { LoanDetailsComponent } from './loan-details/loan-details.component';
import { InvestmentDeclarationComponent } from './investment-declaration/investment-declaration.component';
import { ViewInvestmentDeclarationComponent } from './investment-declaration/view-investment-declaration/view-investment-declaration.component';

const routes: Routes = [LayoutService.childRoutes([
  { path: 'employee', component: EmployeeComponent },
  { path: 'employee/employee-setup', component: EmployeeImportSetupComponent },
  { path: 'employee/employee-hr-edit', component: EmployeeHrEditComponent },
  { path: 'employee/employee-hr-edit/:id', component: EmployeeHrEditComponent },

  { path: 'salary-structure', component: SalaryComponent },
  { path: 'salary-structure/:tab', component: SalaryComponent },
  { path: 'salary-structure/assign-salary-structure/:id', component: SalaryStructureSetupComponent },

  { path: 'salary-revision', component: SalaryRevisionComponent },
  { path: 'salary-revision/salary-revision-setup/:id', component: SalaryRevisionSetupComponent },

  { path: 'salary-dashboard', component: SalaryDashboardComponent },
  { path: 'salary-dashboard/:year/:month/:company', component: SalaryDashboardComponent },
  // { path: 'salary-dashboard/import-attendance/:year/:month', component:ImportAttendanceComponent },
  // { path: 'salary-dashboard/reminders/:year/:month/:company', component:RemindersComponent },
  // { path: 'salary-dashboard/process-salary/:year/:month/:company', component:ProcessSalaryComponent },
  { path: 'run-payroll/salary-reconciliation/:year/:month/:company', component: SalaryReconciliationComponent },
  // { path: 'salary-dashboard/freez-publish-salary/:year/:month/:company', component:FreezPublishSalaryComponent },
  // { path: 'salary-dashboard/attendance-&-lop/:year/:month/:company', component:AttendanceAndLopComponent },

  { path: 'form-16', component: Form16Component },
  { path: 'form-16/generate-form-16', component: Form16SetupComponent },
  { path: 'form-16/generate-form-16/:financialyear', component: Form16SetupComponent },

  { path: 'template', component: TemplateComponent },
  { path: 'payslip-template', component: PayslipTemplateComponent },
  { path: 'taxslip-template', component: TaxslipTemplateComponent },
  { path: 'template/template-setup/:id', component: TemplateSetupComponent },
  { path: 'template/template-setup', component: TemplateSetupComponent },

  { path: 'employee-report', component: EmployeeReportComponent },
  { path: 'employee-report/employee-report-config', component: EmployeeReportConfigureComponent },
  { path: 'employee-report/employee-report-config/:name/:id', component: EmployeeReportConfigureComponent, canDeactivate: [DeacivateGuard] },

  { path: 'employee-report/employee-report-configure', component: EmployeeReportConfigurationComponent },
  { path: 'employee-report/employee-report-configure/:name/:id', component: EmployeeReportConfigurationComponent, canDeactivate: [DeacivateGuard] },

  { path: 'leave-report', component: LeaveReportComponent },
  { path: 'leave-report/leave-report-config', component: LeaveReportConfigureComponent },
  { path: 'leave-report/leave-report-config/:name/:id', component: LeaveReportConfigureComponent, canDeactivate: [DeacivateGuard] },

  { path: 'leave-report/leave-report-configure', component: LeaveReportConfigurationComponent },
  { path: 'leave-report/leave-report-configure/:name/:id', component: LeaveReportConfigurationComponent, canDeactivate: [DeacivateGuard] },

  { path: 'expense-report', component: ExpenseReportComponent },
  { path: 'expense-report/expense-report-config', component: ExpenseReportConfigureComponent },
  { path: 'expense-report/expense-report-config/:name/:id', component: ExpenseReportConfigureComponent, canDeactivate: [DeacivateGuard] },

  { path: 'expense-report/expense-report-configure', component: ExpenseReportConfigurationComponent },
  { path: 'expense-report/expense-report-configure/:name/:id', component: ExpenseReportConfigurationComponent, canDeactivate: [DeacivateGuard] },

  { path: 'scheduled-reports', component: ScheduledReportsComponent },
  { path: 'scheduled-reports/reports-setup', component: ReportSetupComponent },
  { path: 'scheduled-reports/reports-setup/:id/:isfilter', component: ReportSetupComponent },
  { path: 'scheduled-reports/reports-setup/:edit/:id/:isfilter', component: ReportSetupComponent },


  { path: 'employee-reports-config', component: EmployeeReportConfigureComponent },
  { path: 'error-log', component: ErrorComponent },

  { path: 'payroll-report', component: PayrollReportComponent },
  { path: 'payroll-report/payroll-report-config', component: PayrollReportConfigureComponent },
  { path: 'payroll-report/payroll-report-config/:name/:id', component: PayrollReportConfigureComponent, canDeactivate: [DeacivateGuard] },

  { path: 'payroll-report/payroll-report-configure', component: PayrollReportConfigurationComponent },
  { path: 'payroll-report/payroll-report-configure/:name/:id', component: PayrollReportConfigurationComponent, canDeactivate: [DeacivateGuard] },

  { path: 'run-payroll', component: RunPayrollComponent },
  { path: 'run-payroll/:year/:month/:company', component: RunPayrollComponent },
  { path: 'run-payroll/alerts/:year/:month/:company', component: AlertsComponent },
  // { path: 'run-payroll/reminder', component:ReminderComponent },
  { path: 'run-payroll/variable-payment-&-deduction/:year/:month/:company', component: VariablePaymentsAndDeductionsComponent },
  { path: 'run-payroll/salary-revision-and-arrears/:year/:month/:company', component: SalaryRevisionArrearsComponent },
  { path: 'run-payroll/overrider/:year/:month/:company', component: OverridePtEsiLwsTdsComponent },
  { path: 'run-payroll/process-salary/:year/:month/:company', component: ProcessSalaryComponent },
  { path: 'run-payroll/freez-publish-salary/:year/:month/:company', component: FreezPublishSalaryComponent },
  { path: 'run-payroll/attendance-&-lop/:year/:month/:company', component: AttendanceAndLopComponent },
  { path: 'run-payroll/reminders/:year/:month/:company', component: RemindersComponent },
  { path: 'run-payroll/publish-salary/:year/:month/:company', component: PublishSalaryComponent },
  { path: 'run-payroll/held-salary/:year/:month/:company', component: HeldSalaryComponent },
  { path: 'prior-payroll/:year/:month/:company', component: PriorPayrollComponent },
  { path: 'prior-payroll-setup/:year/:month/:company', component: PriorPayrollSetupComponent },
  { path: 'run-payroll/expense-reimbursement/:year/:month/:company', component: ExpenseReimbursementComponent },


  {
    path: 'challan', component: IncomeTaxComponent, children: [
      { path: 'add-challan', component: ChallanComponent },
      { path: 'generate-return', component: ReturnsComponent },
      { path: 'add-challan/:company/:financialyear', component: ChallanComponent },

    ]
  },
  // { path: 'challan/add-challan/:company/:financialyear', component:IncomeTaxComponent },
  { path: 'challan/challan-details', component: ChallanDetailsComponent },
  { path: 'challan/challan-details/:id/:quarter/:financialyear/:company', component: ChallanDetailsComponent },
  { path: 'challan/challan-setup', component: ChallanSetupComponent },
  { path: 'challan/challan-setup/:id/:month/:company/:currency/:financialyear', component: ChallanSetupComponent },
  { path: 'challan/challan-setup/:edit/:editid/:month/:company/:currency/:financialyear', component: ChallanSetupComponent },
  { path: 'challan/challan-update', component: ChallanUpdateComponent },
  { path: 'challan/challan-update/:id/:month/:company/:currency/:financialyear', component: ChallanUpdateComponent },
  { path: 'challan/challan-setup/:adjustAmount/:excessamount/:monthid/:currency/:excessid/:financialyear/:company/:monthname/:taxamount', component: ChallanSetupComponent },

  { path: 'final-settlement', component: FinalSettlementComponent },
  { path: 'final-settlement-setup', component: FinalSettlementSetupComponent },
  { path: 'final-settlement/final-settlement-setup/:id', component: FinalSettlementSetupComponent },
  { path: 'final-settlement/final-settlement-setup/:id/:inboxid', component: FinalSettlementSetupComponent },
  { path: 'audit-log', component: AuditLogComponent },
  {
    path: 'invite-employees', component: InviteEmployeeComponent, children: [
      { path: 'invite-send-new', component: SendNewUserComponent },
      { path: 'invite-reset-old', component: ResetOldUserComponent }
    ]
  },

  { path: 'employee-shift', component: ShiftMappingComponent },
  { path: 'employee-shift/employee-shift-import', component: ShiftMappingImportComponent },
  { path: 'employee-shift/shift-mapping-setup', component: ShiftMappingSetupComponent },
  { path: 'employee-shift/shift-mapping-setup/:id', component: ShiftMappingSetupComponent },

  { path: 'leave-year-end', component: LeaveYearEndComponent },
  { path: 'leave-year-end/leave-year-end-details/:id/:year', component: LeaveYearEndDetailsComponent },
  { path: 'leave-year-end/pending-leave-request/:id/:typeId', component: PendingLeaveRequestComponent },

  { path: 'employee-attendance', component: EmployeeAttendanceComponent },
  { path: 'employee-attendance/employee-attendance-view/:employee/:year/:month', component: EmployeeAttendanceViewComponent },
  { path: 'my-team-attendance/employee-attendance-view/:employee/:year/:month', component: EmployeeAttendanceViewComponent },

  { path: 'leave-balance', component: LeaveBalanceComponent },
  { path: 'leave-balance-import', component: LeaveBalanceImportComponent },
  { path: 'leave-balance/leave-balance-view/:empId', component: LeaveBalanceViewComponent },

  { path: 'pending-request/:year/:month/:company', component: FinancialYearPendingRequestComponent },
  { path: 'pending-request-details/:year/:month/:company/:request', component: FinancialYearPendingRequestViewComponent },

  { path: 'attendance-report', component: AttendanceReportsComponent },
  { path: 'attendance-report/attendance-report-config', component: AttendanceReportConfigureComponent },
  { path: 'attendance-report/attendance-report-config/:name/:id', component: AttendanceReportConfigureComponent, canDeactivate: [DeacivateGuard] },

  { path: 'attendance-report/attendance-report-configure', component: AttendanceReportConfigurationComponent },
  { path: 'attendance-report/attendance-report-configure/:name/:id', component: AttendanceReportConfigurationComponent, canDeactivate: [DeacivateGuard] },

  // { path: 'location', component: LocationComponent },
  { path: 'employee-live-location', component: LiveLocationComponent },

  {
    path: 'geo-fencing', component: LocationComponent, children: [
      { path: 'geo-locations', component: GeoLocationComponent },
      { path: 'assign-geo-location', component: AssignGeoLocationComponent },
    ]
  },

  {
    path: 'document', component: PolicyDocumentsComponent, children: [
      { path: 'policy-documents', component: ManageDocumentsComponent, },
      { path: 'category', component: DocumentsCategoryComponent, },

    ]
  },

  // { path: 'geo-location-import', component: ImportGeoLocationComponent },
  // { path: 'geo-location-employee-import', component: ImportLocationAssignEmployeesComponent },
  { path: 'assign-geo-location/assign-geo-location-setup', component: AssignEmployeeLocationSetupComponent },
  { path: 'assign-geo-location/assign-geo-location-setup/:id', component: AssignEmployeeLocationSetupComponent },
  { path: 'expense-management', component: ExpenseManagementComponent },
  // { path: 'policy-documents', component: PolicyDocumentsComponent },
  { path: 'document/manage-documents-setup', component: ManageDocumentsSetupComponent },
  { path: 'document/manage-documents-setup/:id', component: ManageDocumentsSetupComponent },
  { path: 'attendance-dashboard', component: AttendanceDashboardComponent },
  { path: 'attendance-dashboard/:year/:month/:company', component: AttendanceDashboardComponent },
  { path: 'attendance-alert/alerts/:year/:month/:company', component: AttendanceAlertComponent },
  { path: 'attendance-reminder/reminders/:year/:month/:company', component: AttendanceReminderComponent },
  { path: 'disable-portal-access', component: PortalAccessComponent },
  { path: 'document/manage-documents-setup/:id', component: ManageDocumentsSetupComponent },
  { path: 'manage-assets', component: ManageAssetComponent },
  { path: 'document/manage-documents-setup/:id', component: ManageDocumentsSetupComponent },
  { path: 'manage-advance', component: ManageAdvanceComponent },
  { path: 'outstanding-advance', component: OutstandingAdvanceDetailsComponent },
  { path: 'employee-letters-hr', component: EmployeeLettersComponent },

  { path: 'asset-report', component: AssetReportComponent },
  { path: 'asset-report/asset-report-config', component: AssetReportConfigureComponent },
  { path: 'asset-report/asset-report-config/:name/:id', component: AssetReportConfigureComponent, canDeactivate: [DeacivateGuard] },

  { path: 'asset-report/asset-report-configure', component: AssetReportConfigurationComponent },
  { path: 'asset-report/asset-report-configure/:name/:id', component: AssetReportConfigurationComponent, canDeactivate: [DeacivateGuard] },

  { path: 'generate-challan', component: ChallanGenerationComponent },
  { path: 'loan-details', component: LoanDetailsComponent },
  { path: 'hr-investment-declaration', component: InvestmentDeclarationComponent },
  { path: 'view-investment-declaration/:year/:activeToggle/:emp_id', component: ViewInvestmentDeclarationComponent },
])
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [DeacivateGuard]
})
export class HrRoutingModule { }


