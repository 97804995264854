import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutService } from 'src/app/@shared/components/layout/layout.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HolidayCalenderComponent } from './holiday-calender/holiday-calender.component';
import { Form16Component } from './my-finance/form16/form16.component';
import { PayslipComponent } from './my-finance/payslip/payslip.component';
import { TaxslipComponent } from './my-finance/taxslip/taxslip.component';
import { BankDetailsComponent } from './profile/bank-details/bank-details.component';
import { DocumentsComponent } from './profile/documents/documents.component';
import { EducationComponent } from './profile/education/education.component';
import { FamilyComponent } from './profile/family/family.component';
import { HealthInsuranceComponent } from './profile/health-insurance/health-insurance.component';
import { PersonalComponent } from './profile/personal/personal.component';
import { PreviousEmployerComponent } from './profile/previous-employer/previous-employer.component';
import { ProfessionalsComponent } from './profile/professionals/professionals.component';
import { ProfileComponent } from './profile/profile.component';
import { SnapshotComponent } from './profile/snapshot/snapshot.component';
// import { LeaveBalanceComponent } from './leave-balance/leave-balance.component';
import { MyLeaveBalanceComponent } from './my-leave-balance/my-leave-balance.component';
import { MyAttendanceComponent } from './my-attendance/my-attendance.component';
import { MyReimbursementComponent } from './my-reimbursement/my-reimbursement.component';
import { MySalaryStructureComponent } from './my-finance/my-salary-structure/my-salary-structure.component';
import { MyItDeclarationsComponent } from './my-finance/my-it-declarations/my-it-declarations.component';
import { ExpenseReceiptsComponent } from './expenses/expense-receipts/expense-receipts.component';
import { BulkExpenseComponent } from './expenses/bulk-expense/bulk-expense.component';
import { TripsComponent } from './expenses/trips/trips/trips.component';
import { TripsSetupComponent } from './expenses/trips/trips-setup/trips-setup.component';
import { ExpenseReportsComponent } from './expenses/expense-reports/expense-reports.component';
import { ExpenseReportsSetupComponent } from './expenses/expense-reports-setup/expense-reports-setup.component';
import { AdvanceComponent } from './expenses/advance/advance.component';
import { TicketsComponent } from './expenses/tickets/tickets.component';
import { DocumentRepositoryComponent } from './document-repository/document-repository.component';
import { AcknowledgeFileComponent } from './acknowledge-file/acknowledge-file.component';
import { MyAssetComponent } from './my-asset/my-asset.component';
import { MyLetterComponent } from './my-letter/my-letter.component';
import { MyLetterViewComponent } from './my-letter/my-letter-view/my-letter-view.component';
import { MyLoanComponent } from './my-loan/my-loan.component';

const routes: Routes = [LayoutService.childRoutes([
  { path: 'profile', redirectTo: '/profile/snapshot', pathMatch: 'full' },
  {
    path: 'profile', component: ProfileComponent, children: [
      { path: 'snapshot', component: SnapshotComponent, },
      { path: 'professionals', component: ProfessionalsComponent, },
      { path: 'education', component: EducationComponent, },
      { path: 'personal', component: PersonalComponent, },
      { path: 'family', component: FamilyComponent, },
      { path: 'health-insurance', component: HealthInsuranceComponent, },
      { path: 'previous-emp', component: PreviousEmployerComponent, },
      { path: 'bank-details', component: BankDetailsComponent, },
      { path: 'documents', component: DocumentsComponent, },
    ]
  },
  { path: 'change-password', component: ChangePasswordComponent, },
  { path: 'payslip', component: PayslipComponent, },
  { path: 'taxslip', component: TaxslipComponent, },
  { path: 'form16', component: Form16Component, },
  { path: 'my-salary-structure', component: MySalaryStructureComponent, },
  { path: 'my-it-declarations', component: MyItDeclarationsComponent, },
  { path: 'holiday-calendar', component: HolidayCalenderComponent, },
  // { path: 'leave-balance', component:LeaveBalanceComponent,},
  { path: 'my-leave-balance', component: MyLeaveBalanceComponent, },
  { path: 'my-attendance', component: MyAttendanceComponent, },
  { path: 'reimbursement', component: MyReimbursementComponent, },
  { path: 'expense-receipt', component: ExpenseReceiptsComponent, },
  { path: 'bulk-expense', component: BulkExpenseComponent },
  { path: 'trips', component: TripsComponent },
  { path: 'trips-setup', component: TripsSetupComponent },
  { path: 'expense-reports', component: ExpenseReportsComponent },
  // { path: 'expense-reports-setup', component: ExpenseReportsSetupComponent },
  { path: 'expense-advance', component: AdvanceComponent },
  { path: 'tickets', component: TicketsComponent },
  { path: 'request/expense-reports-setup', component: ExpenseReportsSetupComponent },
  { path: 'document-repository', component: DocumentRepositoryComponent, },
  { path: 'acknowledge-file/:id', component: AcknowledgeFileComponent },
  { path: 'my-asset', component: MyAssetComponent },
  { path: 'my-letters', component: MyLetterComponent },
  { path: 'letters-view', component: MyLetterViewComponent },
  { path: 'my-loan', component: MyLoanComponent },


])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
