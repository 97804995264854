import { Component, OnInit,Inject,ChangeDetectorRef,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UnamepatternValidator } from 'src/app/@shared/validators/unamepattern.validators';
import { spaceValidator } from 'src/app/@shared/validators/space.validators';
import { gstValidator } from 'src/app/@shared/validators/gst.validators';
import { digitdecimalValidator } from 'src/app/@shared/validators/2digitdecimal.validators';
import { MessageService } from 'src/app/message.global';
import { AppService } from 'src/app/app.global';
import { AssetPolicyService } from 'src/app/@core/services/asset-policy.service';
import { NumberpatternValidator } from 'src/app/@shared/validators/numberpattern.validators';
import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { AssetSettingsService } from 'src/app/@core/services/asset-settings.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ApprovalMatrixService } from 'src/app/@core/services/approval-matrix.service';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { ExistDatasService } from 'src/app/@core/services/exist-datas.service';
import { Subject } from 'rxjs'
import { debounceTime} from 'rxjs/operators'
import { AssetFilterComponent } from 'src/app/@shared/components/asset-filter/asset-filter.component';
import { EmpcodeValidator } from 'src/app/@shared/validators/empcode.validators';
import { SearchbarComponent } from 'src/app/@shared/components/searchbar/searchbar.component';

import { delay,switchMap  } from 'rxjs/operators';

@Component({
  selector: 'app-manage-asset',
  templateUrl: './manage-asset.component.html',
  styleUrls: ['./manage-asset.component.scss']
})
export class ManageAssetComponent implements OnInit {

  editInventory               = false;
  viewInventory               = false;
  viewInventoryHistory        = false;
  categoryDD:any              = []
  subCategoryDD:any           = []
  permissions:any             = []
  landingData:any             = []
  currency:string             = "currency";
  id:any                      = 0
  activeToggle                = ''
  offset                      = 0;
  listlength                  = 0;
  direction                   = '';
  defaultlimit:any            = 20;
  DetailsList:any             = [];
  infinityloader              = false;
  warrentyMinDate             = new Date(new Date().getFullYear() - 20, 11, 31);
  warrentyMaxDate             = new Date(new Date().getFullYear() + 20, 11, 31);
  loader                      = true;
  searchKeyword               = "";
  datafilter                  = "";
  currentId:any               = "";
  searchlistdata:any          = [] ;
  historyData:any             = [];
  nodatafound                 = false;
  viewLoader                  = false;
  importPanel                 = false;
  fileName                    = ""
  fileSize                    = ""
  data:any                    = [];
  isUploaded                  = false
  invalidFormat               = false
  ws:any                      = null;
  errorToggle                 = false;
  deleteToggle                = false
  errorMsg                    = ""
  deleteClicked               = false
  inputData:any               = [];
  deleteId                    = 0
  chunkLen                    = 10
  percentage:any              = 0;
  iscompleted                 = false;
  isDataUpload                = 0;
  isProgressStart             = false
  failedData:any              = [];
  successDataCount            = 0;
  saveComplete                = false
  downloadMsg                 = false
  body                        = ""
  exportData:any              = [];
  successMsg                  = ''
  confirmMsg                  = false
  uploadedFile:any            = [];
  listOfFiles:any             = [];
  fileError                   = false
  fileErrorMsg                = ""
  disabled                    = false
  list:any                    = [];
  selectedItem                = [];
  viewNodata                  = false
  saveClicked                 = false
  empDD:any                   = [];
  header                      = "Add";
  assetName_list:any          = []
  assetCode_list:any          = []
  assetSerial_list:any        = [];
  assetCategory_list:any      = [];
  assetSubCategory_list:any   = [];
  assetAssigned_list:any      = [];
  filterpanel                 = false
  validated : boolean         = false
  inventoryUnit : any         = []
  editflag :boolean           = false
  saveClick                   = false;
  historyLoader               = false;
  value:any
  paymentPlaceholder          = this.messageService.selectplaceholddisplay('Payment type');
  currencyPlaceHolder         = this.messageService.selectplaceholddisplay('currency');
  statusPlaceHolder           = this.messageService.selectplaceholddisplay('status');
  searchPlaceholder           = this.messageService.searchdisplay('inventory');
  applicable                  = [
                                  { id: 1, name: 'BU' },
                                  { id: 2, name: 'Company' },
                                  { id: 3, name: 'Department' },
                                  { id: 4, name: 'Designation' },
                                  { id: 5, name: 'Employee' },
                                  { id: 6, name: 'Grade' },
                                ];

// File Upload
modalToggle                   = false;
allowedExtension              = "";
alertMsg:any                  = '';
alertToggle                   = false;
fileName1                     = "";
currentDocName:any;
viewDocName:any;
currentExtension:any;

searchData : Subject<void> = new Subject();

filterForm                    = this.fb.group({
  status                        : [null],
  asset__name                   : [null],
  asset__code                   : [null],
  asset__serial_number          : [null],
  asset__asset_sub_category_id  : [null],
  asset__asset_category_id      : [null],
  assigned_by                   : [null],
  assigned_on                   : '',
  returned_on                   : '',
})

private subjectKeyUp          = new Subject<any>();
@ViewChild(AssetFilterComponent) child:any;
@ViewChild(SearchbarComponent) search:any;
sLoading = false;

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: MatDateFormats,
    public fb:FormBuilder,
    public messageService:MessageService,
    public appService:AppService,
    public assetPolicyService:AssetPolicyService,
    public assetService:AssetSettingsService,
    public notificationService:NotificationService,
    private ams:ApprovalMatrixService,
    private employeeService:EmployeeService,
    public existDataService :  ExistDatasService,
    private changeDetectorRef: ChangeDetectorRef


  ) { }
  inventoryForm: FormGroup = this.fb.group({
    name                  : ['', [Validators.required,UnamepatternValidator(),Validators.maxLength(100)]],
    code                  : ['', [Validators.required,EmpcodeValidator(),Validators.maxLength(10)]],
    serial_number         : ['', [Validators.required,UnamepatternValidator(), Validators.maxLength(30)]],
    asset_category        : [null,[Validators.required]],
    asset_sub_category    : [null,[Validators.required]],
    description           : ['', [spaceValidator(), Validators.maxLength(500)]],
    vendor                : ['', [UnamepatternValidator(),Validators.maxLength(30)]],
    gst_number            : ['', [gstValidator()]],
    invoice_number        : ['', [NumberpatternValidator(),Validators.maxLength(30)]],
    invoice_date          : [null],
    payment_type          : [null],
    currency              : [null],
    cost_per_item         : [,[digitdecimalValidator(),Validators.maxLength(10)]],
    other_cost            : [,[digitdecimalValidator(),Validators.maxLength(10)]],
    gst                   : [,[digitdecimalValidator(),Validators.maxLength(10)]],
    manufacturing_date    : [null],
    warranty_period       : [null,[NumberpatternValidator(),Validators.maxLength(3)]],
    warranty_expiry_date  : [null],
    last_audit_date       : [null],
    end_of_life_date      : [null],
    status                : [null, [Validators.required]],
    asset_attachments     : [null],
    applicable            : [null,[Validators.required]],
    assignees             : [null,[Validators.required]],
    employee              : [null],
    assigned_on           : [null],
    warranty_notification_enable : [false],
    warranty_notification_days : [null],

 })
  ngOnInit(): void {
    this.getCategoryList();
    this.getPermission();
    this.getEmployeeList()
    this.getLandingScreenData(this.activeToggle)

    this.searchData.pipe(delay(1000),
    switchMap(() =>  this.assetService.getAssetManagementData(this.activeToggle,this.defaultlimit,this.offset,this.searchKeyword,this.datafilter))).subscribe((res: any) => {
      this.listlength = res.body.results?.length;
      if(this.offset ==0){
        this.landingData = [];
      }
      if (this.listlength == 0) {
        this.nodatafound = this.offset == 0 ? true :false;
      }else {
        this.nodatafound = false;
        this.DetailsList = res.body.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);

      // this.assetService.getInventoryDropdown(this.activeToggle,this.datafilter,'name','').subscribe((res:any)=>{

      //   this.searchlistdata = res.body;

      // })

    });

    this.subjectKeyUp.pipe(debounceTime(700)).subscribe((d:any)=>{
      this.existService(d.fieldName,d.fieldValue);
    })
  }
  keyUp(fieldName :any, fieldValue:any){
    this.subjectKeyUp.next({fieldName,fieldValue})
  }
  existService(fieldName :any, fieldValue:any){
    if(!this.editflag){
      this.value ={
        "page"  :"Asset",
        "field" :fieldName,
        "value" : fieldValue
      }
    }else{
       this.value ={
        "id":this.id,
        "page"  :"Asset",
        "field" :fieldName,
        "value" : fieldValue
      }
    }
    const fc = this.inventoryForm.get(fieldName);
    this.existDataService.getSpecificName(this.value).subscribe((res:any)=>{
      this.inventoryUnit = res.body
      this.loader = false
      if(this.inventoryUnit.data ==='invalid'){
        fc?.setErrors({
          'exist' : true
        })
      }
    })
  }
  get f() { return this.inventoryForm.controls; }
  getPermission(){
    this.permissions = this.appService.getPermissions('');
    if(this.permissions == undefined){
      setTimeout(() => {
        this.getPermission();
      }, 1000);
    }
    this.getDateFormat();
  }
  getCategoryList(){
    this.assetPolicyService.assetCategoryDropDown(true).subscribe((res:any)=>{
      this.categoryDD = res;
    })
  }

  getSubCategoryList(){

    this.subCategoryDD  = [];
    let  data           = this.inventoryForm.get('asset_category')?.value
    this.inventoryForm.get('asset_sub_category')?.setValue(null)
    if(data!=null){
      this.assetService.getAssetSubcategory(data).subscribe((res:any)=>{
        this.subCategoryDD  = res.body
      })
    }
  }
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }
  clear(value:any,index:number){
    let data = this.inventoryForm.value.assignees;
    if(this.inventoryForm.get('assignees')?.disabled==false){

      data.splice(index, 1);
      this.inventoryForm.patchValue({
        assignees: data
      });
    }
  }
  getDateFormat(){
    this.matDateFormat.display.dateInput = this.appService.getdatepickerformat();
    if(this.matDateFormat.display.dateInput == ''){
      setTimeout(() => {
        this.getDateFormat();
      }, 1000);
    }
  }
  saveInventory(){
    let formData          = this.inventoryForm.getRawValue();
    this.saveClick        = true
    let key               = this.inventoryForm.get('applicable')?.value.toLowerCase()
    let assignRight:any   = {
      applicable  : this.inventoryForm.get('applicable')?.value
    }
    if(key=='BU')
    key = 'businessunit'
    assignRight[key]                  = this.inventoryForm.get('assignees')?.value
    formData['applicability']         = assignRight
    formData['end_of_life_date']      = this.inventoryForm.get('end_of_life_date')?.value !=null && this.inventoryForm.get('end_of_life_date')?.value !=''? this.appService.dateFormatConvert(this.inventoryForm.get('end_of_life_date')?.value):null
    formData['invoice_date']          = this.inventoryForm.get('invoice_date')?.value !=null && this.inventoryForm.get('invoice_date')?.value !=''? this.appService.dateFormatConvert(this.inventoryForm.get('invoice_date')?.value):null
    formData['last_audit_date']       = this.inventoryForm.get('last_audit_date')?.value !=null && this.inventoryForm.get('last_audit_date')?.value !=''? this.appService.dateFormatConvert(this.inventoryForm.get('last_audit_date')?.value):null
    formData['manufacturing_date']    = this.inventoryForm.get('manufacturing_date')?.value !=null && this.inventoryForm.get('manufacturing_date')?.value !=''? this.appService.dateFormatConvert(this.inventoryForm.get('manufacturing_date')?.value):null
    formData['warranty_expiry_date']  = this.inventoryForm.get('warranty_expiry_date')?.value !=null && this.inventoryForm.get('warranty_expiry_date')?.value !=''? this.appService.dateFormatConvert(this.inventoryForm.get('warranty_expiry_date')?.value):null
    formData['assigned_on']           = this.inventoryForm.get('assigned_on')?.value !=null && this.inventoryForm.get('assigned_on')?.value !=''? this.appService.dateFormatConvert(this.inventoryForm.get('assigned_on')?.value):null
    delete formData['assignees'];
    delete formData['applicable'];

    this.assetService.saveOrUpdateAsset(formData,this.id).subscribe((res:any)=>{
      this.editInventory    = false;
      this.uploadedFile     = [];
      this.saveClick        = false;
      this.loader           = true;
      this.inventoryForm.reset();
      this.offset           = 0;
      this.landingData      = [];

      this.getLandingScreenData(this.activeToggle);
      this.changeDetectorRef.detectChanges();

    })
  }
  onScrollDown() {
    this.infinityloader = true;
    this.offset            += this.defaultlimit;
    if(this.listlength){
      this.getLandingScreenData(this.activeToggle);
      this.loader       = false;
      this.direction    = "down";
    }else{
      this.infinityloader = false;
    }
  }
  addItems(itemlist:any) {
    for (let i = 0; i < itemlist; ++i) {
      this.landingData.push(this.DetailsList[i]);
    }

    this.loader = false;
    this.sLoading = false;

  }
  searchInventory(searchKeyword:any){
    this.sLoading       = true;
    this.loader         = true;
    this.offset         = 0;
    this.landingData    = [];
    this.searchKeyword = searchKeyword;
    if(searchKeyword != ""){
      // this.getLandingScreenData(this.activeToggle);
      this.searchData.next();
    }
  }
  searchresults(data:any){
    this.loader = true;
    if(data == ""){
      this.searchKeyword  = "";
      this.offset         = 0;
      this.landingData    = [];
      // this.getLandingScreenData(this.activeToggle);

      this.searchData.next();
    }
    else{
        this.assetService.getSingleInventoryData(data).subscribe((res: any) => {
          this.landingData    = [res.body];

          this.loader         = false;
        });
    }
  }
  getLandingScreenData(status:any){
    this.activeToggle = status
    this.loader       = true;
    this.assetService.getAssetManagementData(status,this.defaultlimit,this.offset,this.searchKeyword,this.datafilter).subscribe((res:any)=>{

      this.listlength = res.body.results.length;
      if(this.offset ==0){
        this.landingData = [];
      }
      if (this.listlength == 0) {
        this.nodatafound = this.offset == 0 ? true :false;
      }else {
        this.nodatafound = false;
        this.DetailsList = res.body.results;
      }
      this.infinityloader = false;
      this.addItems(this.listlength);

    })
    // this.assetService.getInventoryDropdown(this.activeToggle,this.datafilter,'name','').subscribe((res:any)=>{

    //   this.searchlistdata = res.body;

    // })
  }
  statusFilter(status:any){
    this.loader       = true;
    this.activeToggle = status;
    this.DetailsList  = []
    this.landingData  = [];
    this.offset       = 0;
    this.search.clearBar();

    this.searchData.next();

    // this.getLandingScreenData(this.activeToggle)
  }
  closeModel(){

    this.viewInventory = false
  }
  viewHistory(){
    this.viewInventoryHistory = true
    this.historyLoader        = true
    this.viewNodata           = false;
    this.assetService.getSingleInventoryHistory(this.currentId).subscribe((res:any)=>{
      this.historyData  = res.body
      if(this.historyData.length==0)
      this.viewNodata      = true;
      this.historyLoader   = false;
    })
  }
  clearData(){
    this.fileName       = '';
    this.fileSize       = '';
    this.inputData      = [];
    this.iscompleted    = false;
    this.importPanel    = false
    this.isUploaded     = false
    this.getLandingScreenData(this.activeToggle)
}
  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    const extension     = target.files[0].name.split('.').pop();
    if(extension=='xlsx' || extension=='csv'){
      this.invalidFormat = false;
      if (target.files.length !== 1) throw new Error('Cannot use multiple files');
      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        const bstr: string      = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellText: false, raw: false,cellDates:true });
        const wsname: string    = wb.SheetNames[0];
        this.ws                 = wb.Sheets[wsname];
        var ws                  = wb.Sheets[wsname];
        this.fileName           = target.files[0].name;
        this.fileSize           = this.appService.formatSizeUnits(target.files[0].size)
        this.data               = <any>(XLSX.utils.sheet_to_json(ws, { header: 1,raw: false,rawNumbers: false, dateNF: 'dd-mm-yyyy',blankrows:false}));

        this.processData();
      };
      reader.readAsBinaryString(target.files[0]);
    }
    else{
      this.invalidFormat = true;
    }
  }
  processData(){
    if(this.data.length <=1){
      this.errorToggle = true;
      this.errorMsg    = "No data found in the uploaded file.";
      this.clearData()
    } else {
      let headCount = false
      var headerRow = this.data[0];
      let Heading   = ['Asset Code','Asset Name','Serial no.', 'Asset category', 'Asset subcategory','Description','Invoice number','Invoice date','GST no.','Payment type','Currency','Cost(per item)','Other cost','GST amount','Vendor','Warranty period','Manufacturing date','Warranty expiry','Last audit date','End of life','Asset Status','Managed by','Assigned to','Return date','Assigned on']
      let success   = Heading.every(function(val:any) {

        return headerRow.indexOf(val) !== -1;
      });

      if(headerRow.length!=Heading.length)
      headCount = true

      if(success == false || headCount==true){
        this.errorToggle = true;
        this.errorMsg    = this.messageService.invalidExcelFormat();
        this.clearData();
      }
      else
      this.prepareUploadData();
    }
  }
  prepareUploadData(){

    this.data.forEach((item:any, key:any) => {
      let applicability:any

      if(key != 0){
        if(item[21] && item[21].includes('|')){

          let splits = item[21].split('|');
          let value  = splits[0].trim()
           applicability = {
            applicable : value
          }
          let data        = splits[1];

          let splitted     = data.split(',');
          for(let i=0;i<splitted.length;i++){
            splitted[i] = splitted[i].trim()
          }

          applicability[value.toLowerCase()] = splitted
        }
        else applicability = item[21]

        this.inputData.push({
        "code"                  : item[0]?item[0]:"",
        "name"                  : item[1]?item[1]:"",
        "serial_number"         : item[2]?item[2]:"",
        "asset_category"        : item[3]?item[3]:"",
        "asset_sub_category"    : item[4]?item[4]:"",
        "description"           : item[5]?item[5]:"",
        "invoice_number"        : item[6]?item[6]:"",
        "invoice_date"          : item[7] && moment(item[7], 'DD-MM-YYYY', true).isValid()?moment(item[7],'DD-MM-YYYY').format('YYYY-MM-DD'):item[7]?item[7]:null,
        "gst_number"            : item[8]?item[8]:"",
        "payment_type"          : item[9]?item[9]:"",
        "currency"              : item[10]?item[10]:"",
        "cost_per_item"         : item[11]?item[11]:"",
        "other_cost"            : item[12]?item[12]:"",
        "gst"                   : item[13]?item[13]:"",
        "vendor"                : item[14]?item[14]:"",
        "warranty_period"       : item[15]?item[15]:null,
        "manufacturing_date"    : item[16] && moment(item[16], 'DD-MM-YYYY', true).isValid()?moment(item[16],'DD-MM-YYYY').format('YYYY-MM-DD'):item[16]?item[16]:null,
        "warranty_expiry_date"  : item[17] && moment(item[17], 'DD-MM-YYYY', true).isValid()?moment(item[17],'DD-MM-YYYY').format('YYYY-MM-DD'):item[17]?item[17]:null,
        "last_audit_date"       : item[18] && moment(item[18], 'DD-MM-YYYY', true).isValid()?moment(item[18],'DD-MM-YYYY').format('YYYY-MM-DD'):item[18]?item[18]:null,
        "end_of_life_date"      : item[19] && moment(item[19], 'DD-MM-YYYY', true).isValid()?moment(item[19],'DD-MM-YYYY').format('YYYY-MM-DD'):item[19]?item[19]:null,
        "status"                : item[20]?item[20]:"",
        "employee"              : item[22]?item[22]:"",
        "returned_on"           : item[23] && moment(item[23], 'DD-MM-YYYY', true).isValid()?moment(item[23],'DD-MM-YYYY').format('YYYY-MM-DD'):item[23]?item[23]:null,
        "assigned_on"           : item[24] && moment(item[24], 'DD-MM-YYYY', true).isValid()?moment(item[24],'DD-MM-YYYY').format('YYYY-MM-DD'):item[24]?item[24]:null,
        "applicability"         : item[21]?applicability:""

        })

      }

    });
  }
  uploadData(){
    this.isDataUpload = 1;
    this.iscompleted  = false
    this.saveComplete = true;
    this.percentage   = 0;

    if(this.inputData.length>0){
      this.isProgressStart    = true
      this.failedData         = []
      var l                   = this.inputData.length;
      var cLoop               = Math.ceil(l/this.chunkLen)
      var p                   = 100/cLoop
      this.successDataCount   = 0
      this.processUploadData(0,p);
    }
  }
  processUploadData(k:any,p:any){
    var l                 = this.inputData.length;
    var x                 = k*this.chunkLen+this.chunkLen <= l? k*this.chunkLen+this.chunkLen: l;
    var saveDataArray:any = [];
    this.percentage       +=(p/4);
    for(var i= k*this.chunkLen; i<x; i++){
      saveDataArray.push(this.inputData[i])
    }
    this.assetService.uploadInventory({"data":saveDataArray}).subscribe((res:any)=>{
      this.body       = x+' out of '+l+" data uploaded successfully"
      this.confirmMsg = false;
      if(res.body.failed_data != undefined && res.body.failed_data.length != 0){
          res.body.failed_data.forEach((i:any, k:any) => {
              this.failedData.push(i)
          });
      }

      this.successDataCount += res.body.succesd_data_count;
      this.percentage +=((p/4)*3);
      if(x<l)
          this.processUploadData(k+1,p);
      else{
        this.iscompleted      = true
        this.isProgressStart  = false
        this.successDataCount = this.inputData.length- this.failedData.length;
          if(this.failedData.length == 0){
              this.notificationService.handleSuccessNotification(this.successDataCount+" inventory data uploaded successfully.","Success");
          this.clearData()

          } else {
              this.downloadMsg  = true;
              this.exportData   = [];
              for (let i = 0; i < this.failedData.length; i++) {
                let applicable:any = ""
                let applicability;
                let combinedData:any;
                if(this.failedData[i]['data']?.applicability!=""){

                  applicable         = this.failedData[i]['data']?.applicability?.applicable

                  if(applicable!="" && applicable!=undefined){

                    applicability = this.failedData[i]['data']?.applicability[applicable.toLowerCase()]
                    combinedData  = applicable +" | " +applicability.toString()
                  }
                  else
                  combinedData = this.failedData[i]['data']?.applicability
                }
                else
                combinedData = this.failedData[i]['data']?.applicability

                this.exportData.push({
                  "Asset Code"            : this.failedData[i]['data'].code,
                  "Asset Name"            : this.failedData[i]['data'].name,
                  "Serial no."            : this.failedData[i]['data'].serial_number,
                  "Asset category"        : this.failedData[i]['data'].asset_category,
                  "Asset subcategory"    : this.failedData[i]['data'].asset_sub_category,
                  "Description"           : this.failedData[i]['data'].description,
                  "Invoice number"        : this.failedData[i]['data'].invoice_number,
                  "Invoice date"          : this.failedData[i]['data'].invoice_date,
                  "GST no."               : this.failedData[i]['data'].gst_number,
                  "Payment type"          : this.failedData[i]['data'].payment_type,
                  "Currency"              : this.failedData[i]['data'].currency,
                  "Cost(per item)"        : this.failedData[i]['data'].cost_per_item,
                  "Other cost"            : this.failedData[i]['data'].other_cost,
                  "GST amount"            : this.failedData[i]['data'].gst,
                  "Vendor"                : this.failedData[i]['data'].vendor,
                  "Warranty period"       : this.failedData[i]['data'].warranty_period,
                  "Manufacturing date"    : this.failedData[i]['data'].manufacturing_date,
                  "Warranty expiry"       : this.failedData[i]['data'].warranty_expiry_date,
                  "Last audit date"       : this.failedData[i]['data'].last_audit_date,
                  "End of life"           : this.failedData[i]['data'].end_of_life_date,
                  "Asset Status"          : this.failedData[i]['data'].status,
                   "Managed by"           : combinedData,
                   "Assigned to"          :this.failedData[i]['data'].employee,
                   "Return date"          :this.failedData[i]['data'].returned_on,
                   "Assigned on"          :this.failedData[i]['data'].assigned_on,
                  'Error description'     : this.failedData[i].error
                });
              }
              this.successMsg = this.successDataCount+" out of "+l+" data uploaded successfully "
              this.errorMsg   = this.exportData.length+' inventory have error data'
          }
      }
    })
  }

  confirmDelete(id:any){
    this.deleteId       = id;
    this.deleteToggle   = true
    this.deleteClicked  = false
  }
  deleteInventory(){
    this.deleteClicked = true
    this.assetService.deleteInventory(this.deleteId,{'is_active':false}).subscribe((res:any)=>{
      this.deleteToggle  = false;
      this.deleteClicked = false
      this.offset        = 0;
      this.landingData   = [];
      this.getLandingScreenData(this.activeToggle)
    },
    (err: any) => {
      this.deleteToggle   = false
      this.deleteClicked = false
    })
  }
  getURL(event:any){
    this.fileError        = false;
    this.uploadedFile[this.uploadedFile.length-1]['url']    = event;
    this.uploadedFile[this.uploadedFile.length-1]['loader'] = false;
    this.listOfFiles.push(event)
    this.inventoryForm.get('asset_attachments')?.setValue(this.listOfFiles)
  }
  getloader(event:any){
    if(event==true){
      let file = {
        loader  : event,
        url     : '',
        size    : ''
      }
      this.uploadedFile.push(file)
    }
    else{
      this.uploadedFile[this.uploadedFile.length-1]['loader'] = event;
    }

  }
  getfileformat(event: any) {
    if (event == false) {
      this.fileError    = true;
      this.fileErrorMsg = this.messageService.validateFileFormat('png, jpg, jpeg');
    }
  }
  getFilename(event: any) {
    this.uploadedFile[this.uploadedFile.length-1]['size'] = this.appService.formatSizeUnits(event.size);
  }
  getvalidsize(event: any) {
    this.fileError    = true;
    this.fileErrorMsg = event;
  }
  removeSelectedFile(index:any) {

    this.uploadedFile.splice(index, 1);
    this.listOfFiles.splice(index,1)
    this.inventoryForm.get('asset_attachments')?.setValue(this.listOfFiles)

  }
  changeApplicable(init:any){
    if(init != 1)
    this.inventoryForm.get('assignees')?.setValue([]);
    let keyvalue = this.inventoryForm['controls'].applicable.value;
    if(this.inventoryForm['controls'].applicable.value == "Business Unit"){
      keyvalue = "BU";
    }
    this.assetService.applicableList(keyvalue).subscribe((res: any) => {
      this.list = [];
      if(this.inventoryForm['controls'].applicable.value == "Employee"){
        for(let key of res?.body){
          key.name = key.first_name+" ";
          if(key.middle_name !=null && key.middle_name!='')
            key.name +=key.middle_name+" ";
          if(key.last_name !=null)
            key.name +=key.last_name;
            key.name +=" ("+key.employee_code+")";
          this.list.push(key);
        }
      } else if(this.inventoryForm['controls'].applicable.value == "Company"){
        for(let key of res?.body){
          key.name = key.company_name;
          this.list.push(key);
        }
      }
      else
        this.list = res?.body;
        if (this.list.length != 0 && this.inventoryForm['controls'].applicable.value != "Employee") {
          this.selectedItem = this.list[0].id;
          this.selectAllForDropdownItems(this.list);
        }
    });
  }
  editInventoryData(id:any){
    this.editflag       = true;
    this.viewLoader     = true;
    this.editInventory  = true
    this.header         = "Edit"
    this.id             = id
    this.inventoryForm.get('warranty_notification_enable')?.setValue(false)
    this.checkValidator('warranty_notification_days')
    this.assetService.getSingleInventoryData(id).subscribe((res: any) => {
      Object.entries(res.body).forEach(([key, value]) => {
        if(key!='asset_attachments' && key!='applicability' && key!='assigned_to')
        this.inventoryForm.get(key)?.setValue(value)
        else if(key=='asset_attachments'){
          let data:any           = value;
          this.uploadedFile      = []

          for(let i=0;i<data.length;i++){
            let file = {
              loader  : false,
              url     : data[i],
              size    : ''
            }

            this.uploadedFile.push(file)
            this.listOfFiles.push(data[i]);
            this.inventoryForm.get('asset_attachments')?.setValue(value)

          }
        }
        else if(key=='assigned_to')
        {
          let empid:any= value
          this.inventoryForm.get('employee')?.setValue(empid?.id)
        }

        else{
          if(value!=null && value!=''){

            let applicable:any = value;
            this.inventoryForm.get('applicable')?.setValue(applicable?.applicable)
            this.changeApplicable(0)
            let applicabledata:any = applicable[applicable?.applicable]
            let applicableArray = [];
            for(let i=0;i<applicabledata.length;i++){
              applicableArray.push(applicabledata[i]['id'])
            }

            this.inventoryForm.get('assignees')?.setValue(applicableArray)
          }
        }
        if(key=='asset_category')
        this.getSubCategoryList();
        if(key=='assigned_to'){
          if(value!=""){
            this.inventoryForm.get('asset_category')?.disable();
            this.inventoryForm.get('asset_sub_category')?.disable();
            this.inventoryForm.get('assignees')?.disable();
            this.inventoryForm.get('applicable')?.disable();
          }
          else
          {
            this.inventoryForm.get('asset_category')?.enable();
            this.inventoryForm.get('asset_sub_category')?.enable();
            this.inventoryForm.get('assignees')?.enable();
            this.inventoryForm.get('applicable')?.enable();
          }
        }


      })
      this.viewLoader = false;

    })
    // this.viewLoader = false;
    this.changeDetectorRef.detectChanges();
  }
  getEmployeeList(){
    this.assetService.empDDList('','user__first_name').subscribe((res:any)=>{
      this.empDD = res?.body;
      let len = this.empDD?.length
      for (let i = 0; i < len; i++) {
      this.empDD[i]['fullname']  = "";
      if(this.empDD[i]['first_name'] != null)
       this.empDD[i]['fullname'] += this.empDD[i]['first_name']+" ";
      if(this.empDD[i]['middle_name'] != null)
       this.empDD[i]['fullname'] += this.empDD[i]['middle_name']+" ";
      if(this.empDD[i]['last_name'] != null)
       this.empDD[i]['fullname'] += this.empDD[i]['last_name'];
       this.empDD[i]['fullname']   +=" ("+this.empDD[i]['employee_code']+")";

      }
    })
  }
  addValidators(){

    this.setValidator('employee')
    this.setValidator('assigned_on')

    if (this.editflag) {
      const shouldEnable = this.inventoryForm.get('status')?.value === 'In Stock';
      this.enableControls(shouldEnable);
    }
  }

  enableControls(shouldEnable: boolean) {
    const controls = ['asset_category', 'asset_sub_category', 'assignees', 'applicable'];
    controls.forEach(control => {
      shouldEnable
        ? this.inventoryForm.get(control)?.enable()
        : this.inventoryForm.get(control)?.disable();
    });
  };

  setValidator(controls:any){

    if(this.inventoryForm.get('status')?.value=='In Use')
    this.inventoryForm.get(controls)?.setValidators([Validators.required])
    else{

      this.inventoryForm.get(controls)?.clearValidators();
      this.inventoryForm.get(controls)?.setValue(null)
    }
    this.inventoryForm.get(controls)?.updateValueAndValidity()
  }
  applyFilter(data:any){
    this.datafilter   = data;
    this.loader       = true;
    this.DetailsList  = []
    this.landingData  = [];
    this.offset       = 0;
    this.search.clearBar();
    this.getLandingScreenData(this.activeToggle);
    this.closeFilter()
  }
  resetfilter(){
    this.loader       = true;
    this.DetailsList  = []
    this.landingData  = [];
    this.offset       = 0;
    this.datafilter = "";
    this.search.clearBar();
    this.closeFilter()

  }
  clearcommonForm(control:any,index:any){
    let daata = this.filterForm.value[control];
    daata.splice(index, 1);
    this.filterForm.get(control)?.setValue(daata)
  }
  closeFilter(){
    this.filterpanel=false

  }
  getAssetName(){
   this.assetService.getInventoryDropdown('','','name','').subscribe((res:any)=>{
    this.assetName_list = res.body
    this.selectAllForDropdownItems(this.assetName_list);

   })
  }
  getAssetCode(){
    this.assetService.getInventoryDropdown('','','code','').subscribe((res:any)=>{
      this.assetCode_list = res.body
      this.selectAllForDropdownItems(this.assetCode_list);

     })
  }
  getSerialNumber(){
    this.assetService.getInventoryDropdown('','','serial_number','').subscribe((res:any)=>{
      this.assetSerial_list = res.body
      this.selectAllForDropdownItems(this.assetSerial_list);


     })
  }
  addInventory(){
    this.inventoryForm.reset()
    this.inventoryForm.enable();
    this.inventoryForm.get('warranty_notification_enable')?.setValue(false)
    this.checkValidator('warranty_notification_days')
    this.fileError     = false
    this.uploadedFile  = []
    this.listOfFiles   = []
    this.editInventory = true
    this.header        = 'Add'
    this.viewLoader    = false
    this.id            = 0
    this.saveClick     = false;
    this.editflag      = false;
    this.search.clearBar();
  }

  addheadercontent(){
    this.landingData  = [];
    this.offset       = 0;
    this.search.clearBar();
  }

  setWarrentyExpiry(){
    if(this.inventoryForm.get('invoice_date')?.value!=null && this.inventoryForm.get('invoice_date')?.value!='' && this.inventoryForm.get('warranty_period')?.value!=null && this.inventoryForm.get('warranty_period')?.value!='' && !(this.inventoryForm.get('warranty_period')?.errors)){
      let invoiceDate :any       = this.appService.dateFormatConvert(this.inventoryForm.get('invoice_date')?.value);
      const now                  = new Date(invoiceDate);
      let period:any             = Number(this.inventoryForm.get('warranty_period')?.value)
      const monthsAfter: Date    = monthsAfterFrom(now, period);
      this.inventoryForm.get('warranty_expiry_date')?.setValue(monthsAfter)
      let warrentyDate = this.appService.dateFormatConvert( this.inventoryForm.get('warranty_expiry_date')?.value);
      this.warrentyMaxDate = new Date(moment(warrentyDate,"YYYY-MM-DD").year(), moment(warrentyDate,"YYYY-MM-DD").month(), moment(warrentyDate,"YYYY-MM-DD").date());

      function monthsAfterFrom(date: Date, n: number): Date {
        const result = new Date(date);
        result.setMonth(result.getMonth() + n);
        return result;
      }
    }
    else{
      this.inventoryForm.get('warranty_expiry_date')?.setValue(null);
      this.warrentyMaxDate = new Date(new Date().getFullYear() + 20, 11, 31);
    }


  }

  /********* VIEW DOCUMENT FUNCTION ****************/
  viewDocument(filename:any){
    this.modalToggle            = true;
    this.fileName1               = filename;
    this.currentDocName         = this.appService.getDocName(filename);
    this.currentExtension       = this.fileName1.split('.').pop();
    if(this.currentExtension == 'zip' || this.currentExtension == 'docx' || this.currentExtension == 'xlsx' || this.currentExtension == 'doc' || this.currentExtension == 'dotx'){
      this.modalToggle          = false;
      this.alertToggle         = true;
      this.alertMsg             = 'The preview is not available for the' +' '+this.currentExtension+' '+'file';
    }
  }

  dateFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 20, 0, 1),
    new Date(new Date().getFullYear() + 20, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  emStatus:any;
  assignedToChangeFunction(e:any){
      if(e?.employment_status == 'Relieved' || e?.employment_status == 'Settled' || e?.employment_status == 'Terminated' || e?.employment_status == 'Retired'){
        this.emStatus = e?.employment_status;
        this.inventoryForm?.get('employee')?.setErrors({
          'releivedError' : true
        })
    }
  }

  checkValidator(control:any){
    if(this.inventoryForm.get('warranty_notification_enable')?.value == true){
      this.inventoryForm.get(control)?.setValidators([Validators.required,NumberpatternValidator(),Validators.maxLength(2)])
    }else{
      this.inventoryForm.get(control)?.clearValidators();
      this.inventoryForm.get(control)?.setValue(null)
    }
    this.inventoryForm.get(control)?.updateValueAndValidity();
  }

}
