<div class="container-fluid px-32 py-24">
  <div class="row row-16">
    <div class="col-12 d-flex gap-8  align-items-center">
      <span class="text-light-500 fs-12 text-nowrap">{{requestitem?.created_display}}</span>
      <button class="btn-reset btn-star ms-auto text-light-500 fs-12" [class.mail-stared]="requestitem['star']==true"
      (click)="performAction(2,0,requestitem['id'],'','',requestitem['star'])">
        <i class="bi toggle-star"></i>
      </button>
    </div>
    <div class="col-12 mt-8 card-ribbon">
      <div class="w-100 d-flex align-items-center gap-8 card-ribbon">
        <h5 class="mb-8">Loan Process</h5>
        <div class="d-flex align-items-center ms-auto gap-8">
          <span class="link-primary fs-14" (click)="requestViewStatus()">View Status</span>
        </div>
      </div>
      <span class="badge-custom {{requestitem.request_type_color_code}}">Loan Process </span>
      <span *ngIf="requestitem.is_mail_overdue && !requestitem?.achieved_status && requestitem?.request_data?.status != 'Invalid'"
      class="ribbon ribbon-right ribbon-danger" style="right:.5rem;bottom: 0;">Overdue</span>
    </div>
    <app-inbox-progress-bar [requestitem]="requestitem"></app-inbox-progress-bar>
    <div class="col-12">
      <p class="mb-0 fs-14" [innerHTML]="requestitem?.message"></p>
    </div>
    <app-view-loader *ngIf="loader1"></app-view-loader>
    <ng-container *ngIf="!loader1">
      <div class="col-12" >
        <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16">
            <div class="col-12">
              <p class="title mb-8">Employee Details</p>
            </div>
            <div class="col-sm-4 col-md-3">
              <div class="detail-title">Name</div>
              <div class="detail-desc">{{requestitem?.requests?.from_person?.first_name +" " +(requestitem?.requests?.from_person?.middle_name != null? requestitem?.requests?.from_person?.middle_name: "") +" " +requestitem?.requests?.from_person?.last_name}}</div>
            </div>
            <div class="col-sm-4 col-md-3">
              <div class="detail-title">Employee code</div>
              <div class="detail-desc">{{requestitem?.requests?.from_person?.employee_code}}</div>
            </div>
            <div class="col-sm-4 col-md-3">
              <div class="detail-title">Department</div>
              <div class="detail-desc">{{requestitem?.requests?.from_person?.department}}</div>
            </div>
            <div class="col-sm-4 col-md-3">
              <div class="detail-title">Designation</div>
              <div class="detail-desc">{{requestitem?.requests?.from_person?.designation}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card details-card indicater1 shadow-0 px-24 pb-24 pt-16">
          <div class="row row-16">
            <div class="col-12 d-flex">
              <p class="title mb-8">Loan request details</p>
              <a class="ms-auto fs-12 fw-500 link-primary1" (click)="existingLoanData()">View existing loan details</a>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Loan amount</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_amount | currency : requestitem?.requests?.currency}}</div>
              <span class="link-primary1 text-nowrap fs-12 fw-500 ms-14" *ngIf="requestitem?.requests?.can_edit_loanAmount_emi_by_owner" (click)="editModal('loan_amount')"><i class="icon-pencil-square me-1"></i>Edit</span>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Loan type</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.loan_type}}</div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Number of EMI</div>
              <div class="detail-desc">{{requestitem?.requests?.no_emis}}
                <span class="link-primary1 text-nowrap fs-12 fw-500 ms-14" *ngIf="requestitem?.requests?.can_edit_loanInterest_by_owner" (click)="editModal('number_of_emi')"><i class="icon-pencil-square me-1"></i>Edit</span>
              </div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Interest rate</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.interest_rate}}
                <span class="link-primary1 text-nowrap fs-12 fw-500 ms-14" *ngIf="requestitem?.requests?.can_edit_loanInterest_by_owner" (click)="editModal('interest_rate')"><i class="icon-pencil-square me-1"></i>Edit</span>
              </div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Interest type</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.interest_type}}</div>
            </div>
            <div class="col-md-3 col-sm-4 col-12">
              <div class="detail-title">Loan required date</div>
              <div class="detail-desc">{{requestitem?.requests?.requested_date != null ? this.appservice.dateFormatDisplay(requestitem?.requests?.requested_date) : '-'}}
              </div>
            </div>
            <div class=" col-12">
              <div class="detail-title">Interest penalty on payment shortfall rate%</div>
              <div class="detail-desc">{{requestitem?.requests?.loan_type_details?.interest_penality}}
                <span class="link-primary1 text-nowrap fs-12 fw-500 ms-14" *ngIf="requestitem?.requests?.can_edit_loanInterest_by_owner" (click)="editModal('interest_penality')"><i class="icon-pencil-square me-1"></i>Edit</span>
              </div>
            </div>
            <div class="col-12" [formGroup]="form">
              <label for="month" class="form-label required">Deduction start month</label>
              <ng-select class="form-ngselect" placeholder="{{this.msgServ.selectplaceholddisplay('month')}}" formControlName="start_month"
              [ngClass]="{'is-invalid' : (f.start_month.touched || f.start_month.dirty) && f.start_month.errors}">
                <ng-option *ngFor="let month of listOfMonth" [value]="month">{{month}}</ng-option>
              </ng-select>
              <div class="invalid-feedback" *ngIf="(f.start_month.touched || f.start_month.dirty) && f.start_month.errors">
                <div *ngIf="f.start_month?.errors?.required">
                  {{this.msgServ.validationDisplay('required')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <textarea name="" id="" rows="3" placeholder="Comment here... " class="form-control textarea-comment"></textarea>
      </div> -->
    </ng-container>
    <!-- <div class="col-12 d-flex gap-16  py-16  position-sticky bottom-0 bg-white" style="z-index: 11;">
      <button class=" btn btn-primary text-uppercase ms-auto btn-sm fw-400">Process</button>
    </div> -->
    <form class="mt-20" [formGroup]="form">
      <ng-container *ngIf="!requestitem?.achieved_status">
        <div class="col-12">
          <textarea name="comment" id="comment" rows="3" placeholder="Write comment here..."
            class="form-control mb-8 textarea-comment" formControlName="comment"
            [ngClass]="{ 'is-invalid': f.comment.errors}"></textarea>
          <div *ngIf="f.comment.errors" class="invalid-feedback">
            <div *ngIf="f.comment.errors?.maxlength">{{this.msgServ.fieldlengthvalidation('word','500')}}
            </div>
          </div>
        </div>
        <div class="col-12 d-flex gap-16  py-16  position-sticky bottom-0 bg-white" style="z-index: 11;">
          <button type="button" class=" btn btn-primary text-uppercase btn-sm fw-400 ms-auto" [disabled]="processBtn || !form.valid"
            (click)="processFn()">Process</button>
        </div>
      </ng-container>
    </form>
  </div>
</div>

<div class="modal  modal-alert {{alertAmountToggle == true ? 'show': ''}}" tabindex="-1" [formGroup]="form">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">Edit</h4>
        <button class="btn-reset ms-auto text-light-600">
          <i class="icon-close-lg fs-16" (click)="closeModal('loan_amount')"></i></button>
      </div>
      <div class="modal-body py-0">
        <label for="loan" class="form-label required">Loan Amount</label>
        <input type="text" class="form-control" autocomplete="off" placeholder="{{this.msgServ.placeholderdisp('amount')}}" pattern="^[0-9]*$" formControlName="loan_amount" name="loan_amount"
        [ngClass]="{'is-invalid' : (f.loan_amount.touched || f.loan_amount.dirty) && f.loan_amount.errors }" />
        <div *ngIf="(f.loan_amount.touched || f.loan_amount.dirty) && f.loan_amount.errors"
        class="invalid-feedback">
          <div *ngIf="f.loan_amount.errors.required">{{this.msgServ.validationDisplay('required')}}</div>
          <div *ngIf="f.loan_amount.hasError('min') && !(f.loan_amount.errors?.pattern)">
            {{this.msgServ.fieldlengthvalidation('minvalue',1)}}
          </div>
          <div *ngIf="f.loan_amount.hasError('max') && !(f.loan_amount.errors?.pattern)">
            {{this.msgServ.fieldlengthvalidation('value',this.requestitem?.requests?.amount_limit)}}
          </div>
          <div *ngIf=" f.loan_amount.errors?.pattern || (notanumber(f.loan_amount.errors?.pattern?.actualValue))">
            {{this.msgServ.validationDisplay('pattern')}}</div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="submitModalData('loan_amount')" [disabled]="submitClicked || !form.get('loan_amount')?.valid">submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{alertEMIToggle == true ? 'show': ''}}" tabindex="-1" [formGroup]="form">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">Edit</h4>
        <button class="btn-reset ms-auto text-light-600">
          <i class="icon-close-lg fs-16" (click)="closeModal('number_of_emi')"></i></button>
      </div>
      <div class="modal-body py-0">
        <label for="loan" class="form-label required">Number of EMI</label>
        <input type="text" class="form-control" autocomplete="off" placeholder="{{this.msgServ.placeholderdisp('emi')}}" formControlName="number_of_emi" name="number_of_emi" pattern="^[0-9]*$"
        [ngClass]="{'is-invalid' : (f.number_of_emi.touched || f.number_of_emi.dirty) && f.number_of_emi.errors }" />
        <div *ngIf="(f.number_of_emi.touched || f.number_of_emi.dirty) && f.number_of_emi.errors"
        class="invalid-feedback">
          <div *ngIf="f.number_of_emi.errors.required">{{this.msgServ.validationDisplay('required')}}</div>
          <div *ngIf="f.number_of_emi.hasError('min') && !(f.number_of_emi.errors?.pattern)">
            {{this.msgServ.fieldlengthvalidation('minvalue',1)}}
          </div>
          <div *ngIf="f.number_of_emi.hasError('max') && !(f.number_of_emi.errors?.pattern)">
            {{this.msgServ.fieldlengthvalidation('value',this.requestitem?.requests?.loan_type_details?.max_number_emis)}}
          </div>
          <div *ngIf=" f.number_of_emi.errors?.pattern || (notanumber(f.number_of_emi.errors?.pattern?.actualValue))">
            {{this.msgServ.validationDisplay('pattern')}}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="submitModalData('number_of_emi')" [disabled]="submitClicked || !form.get('number_of_emi')?.valid">submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{alertINT_rate == true ? 'show': ''}}" tabindex="-1" [formGroup]="form">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">Edit</h4>
        <button class="btn-reset ms-auto text-light-600">
          <i class="icon-close-lg fs-16" (click)="closeModal('interest_rate')"></i></button>
      </div>
      <div class="modal-body py-0">
        <label for="loan" class="form-label required">Interest rate</label>
        <input type="text" class="form-control" autocomplete="off" placeholder="{{this.msgServ.placeholderdisp('rate')}}" formControlName="interest_rate" name="interest_rate"
        [ngClass]="{'is-invalid' : (f.interest_rate.touched || f.interest_rate.dirty) && f.interest_rate.errors }" />
        <div *ngIf="(f.interest_rate.touched || f.interest_rate.dirty) && f.interest_rate.errors"
        class="invalid-feedback">
          <div *ngIf="f.interest_rate?.errors?.required">
              {{this.msgServ.validationDisplay('required')}}
          </div>
          <div *ngIf="f.interest_rate?.errors?.pattern || (notanumber(f.interest_rate?.errors?.pattern?.actualValue))">
            {{this.msgServ.validationDisplay('pattern')}}
          </div>
          <div *ngIf="f.interest_rate.hasError('max') && !(f.interest_rate.errors?.pattern)">
            {{this.msgServ.fieldlengthvalidation('value',this.requestitem?.requests?.loan_type_details?.interest_rate)}}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="submitModalData('interest_rate')" [disabled]="submitClicked || !form.get('interest_rate')?.valid">submit</button>
      </div>
    </div>
  </div>
</div>

<div class="modal  modal-alert {{alertINT_penality == true ? 'show': ''}}" tabindex="-1" [formGroup]="form">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header align-items-start gap-16">
        <h4 class="modal-title">Edit</h4>
        <button class="btn-reset ms-auto text-light-600">
          <i class="icon-close-lg fs-16" (click)="closeModal('interest_penality')"></i></button>
      </div>
      <div class="modal-body py-0">
        <label for="loan" class="form-label required">Interest penalty on payment shortfall rate%</label>
        <input type="text" class="form-control" autocomplete="off" placeholder="{{this.msgServ.placeholderdisp('rate')}}" formControlName="interest_penality" name="interest_penality"
        [ngClass]="{'is-invalid' : (f.interest_penality.touched || f.interest_penality.dirty) && f.interest_penality.errors }" />
        <div *ngIf="(f.interest_penality.touched || f.interest_penality.dirty) && f.interest_penality.errors"
        class="invalid-feedback">
          <div *ngIf="f.interest_penality?.errors?.required">
              {{this.msgServ.validationDisplay('required')}}
          </div>
          <div *ngIf="f.interest_penality?.errors?.pattern || (notanumber(f.interest_penality?.errors?.pattern?.actualValue))">
            {{this.msgServ.validationDisplay('pattern')}}
          </div>
          <div *ngIf="f.interest_penality.hasError('max') && !(f.interest_penality.errors?.pattern)">
            {{this.msgServ.fieldlengthvalidation('value',this.requestitem?.requests?.loan_type_details?.interest_penality)}}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm text-uppercase" (click)="submitModalData('interest_penality')" [disabled]="submitClicked || !form.get('interest_penality')?.valid">submit</button>
      </div>
    </div>
  </div>
</div>

<!-- View Status -->
<ng-container *ngIf="viewstatusPanel">
  <div class="side-panel" style="--sidepanel-width:32.1875rem;" [class.side-pane-active]='viewstatusPanel === true'>
    <form class="side-panel-container" autocomplete="off">
      <header class="side-panel-head">
        <h5>Activities</h5>
        <a class="toggle-panel" (click)="viewstatusPanel = false"><i class="icon-close-lg fs-16"></i></a>
      </header>
      <app-view-status [loader]="billLoader" [approvalRequired]="true" [viewStatusData]="viewStatusData"
        [viewStatusLevelCnt]="viewStatusLevelCnt" [viewStatusmaxLevel]="viewStatusmaxLevel"></app-view-status>
    </form>
  </div>
</ng-container>

<!-- View Existing Loan Details -->
<app-view-loan-details *ngIf="viewExistingLoan" [from]="'loanInbox'" [viewDetails]="viewExistingLoan" [navData]="navData" [loanData]="viewExistingData" (close)="panelClose($event)" [loader]="billLoader"></app-view-loan-details>
