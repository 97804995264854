<div class="container-fluid p-24" *ngIf="!viewempmap">
  <div class="row row-16 ">
    <div class="col-md-4 col-lg-6">
      <h3>Attendance</h3>
      <p class="text-helper mb-0 ">
        Regular attendance details with checkin and checkout option is provided under this section
      </p>
    </div>
    <div class="col-md-8 col-lg-6 d-flex flex-column " *ngIf="webcheckIn">
      <div class="card card-c2 p-8 ms-auto flex-row gap-16 bg-transparent align-items-center">
        <span class="fs-14 fw-500 text-nowrap px-8">{{currentDate | date:this.appservice.getdateformat()}}</span>
        <button style="min-width:16rem;" class="btn w-100 {{(checkinFlag==true) ? 'btn-primary' : 'btn-warning-2'}}"
          (click)="checkin_out();"><i
            class="{{(checkinFlag==true)?'icon-arrow-right-circle':'icon-arrow-left-circle'}} fs-20 me-8"></i>{{(checkinFlag==true)?'Check-in':'Check-out'}}
          at <span class="ms-1 text-end" style="min-width: 7ch;">{{currentDate
            |date:this.appservice.gettimeformat()}}</span>
        </button>
      </div>
    </div>
    <div class="col-12" *ngIf="validation != '' || validationShift!=''">
      <div role="alert" class="alert alert-warning mb-0  show">
        <p class="mb-0 fs-14  ">{{validation !=''?validation:validationShift}}</p>
        <div class="btn-close pointer" (click)="validation ='' && validationShift=''"></div>
      </div>
    </div>
    <div class="col-12">
      <div class="card card-c2 p-24">
        <div class="row row-16">
          <div class="col-12 d-flex align-items-center">
            <p class="mb-0 fw-500">Attendance Info (from 1st
              {{activeMonth.slice(0,3)}} to {{summaryTitle}})</p>
            <div class="input-group1 ms-auto fs-14">
              <div class="detail-title" *ngIf="loader" style="width:9rem">
                <ngx-skeleton-loader appearance="line"
                  [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader>
              </div>
              <div class="detail-title" *ngIf="loader" style="width:9rem"><ngx-skeleton-loader appearance="line"
                  [theme]="{'background-color': '#EAEDEF','height': '13px','max-width': '80px', 'width': '100%' }"></ngx-skeleton-loader>
              </div>
              <ng-select *ngIf="!loader" style="width:9rem" placeholder="Month" class="form-ngselect h-36"
                [(ngModel)]="activeYear" [clearable]="false" (change)="validateMonth()">
                <ng-option *ngFor="let yData of dropdownYear" [value]="yData">{{yData}}</ng-option>
              </ng-select>
              <ng-select *ngIf="!loader" style="width:9rem" placeholder="Year" class="form-ngselect h-36"
                [(ngModel)]="activeMonth" [clearable]="false" (change)="getattendance()">
                <ng-option *ngFor="let yData of dropdownMonth[activeYear]" [value]="yData">{{yData}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="card card-c2 p-24 h-100">
              <ng-container *ngIf="noGraphData && !attendanceLoader">
                <div class="row h-100">
                  <div class="col-12 gap-16 h-100 flex-center flex-column">
                    <img src="assets\images\no_data\bg6.png" style="width:7rem" class="img-fluid">
                    <p class="fs-14 fw-500 mb-0">No Attendance Info found</p>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="attendanceLoader">
                <div class="d-flex align-items-center justify-content-between my-auto">
                  <ngx-skeleton-loader appearance="circle"
                    [theme]="{ 'border-radius': '50%',height: '86px',width: '86px','background-color': '#EAEDEF'}"></ngx-skeleton-loader>
                  <div class="d-flex flex-column">
                    <ngx-skeleton-loader appearance="line"
                      [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"
                      [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px','margin-bottom':'2px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader appearance="line"
                      [theme]="{'background-color':'#EAEDEF','height': '20px','width': '150px'}"></ngx-skeleton-loader>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!attendanceLoader && !noGraphData">
                <app-doughnut-chart [radius]="57" [color]="attendanceColor" [data]="attendanceData"
                  [labels]="attendanceLabels" [id]="0" [showRupee]="false" [showDays]="'Days'"> </app-doughnut-chart>
              </ng-container>
            </div>
          </div>
          <div class="col-lg-8 col-md-6">
            <div class="card card-c2 p-24 h-100">
              <div class="row row-16">
                <div class="col-lg-3 col-sm-6 ">
                  <div class="card card-c2 h-100 p-16 gap-8">
                    <div class="hstack text-accent1 gap-8">
                      <div class="fs-20 fw-500 text-accent1" *ngIf="advanceDetails?.time_metrix?.overtime_monthly_hrs">
                        {{appservice.hhmmTimeFormat(advanceDetails?.time_metrix?.overtime_monthly_hrs)}} Hrs</div>
                      <div class="fs-22 fw-500 " *ngIf="!advanceDetails?.time_metrix?.overtime_monthly_hrs">0 Hrs</div>
                      <i class="icon-stopwatch-fill fs-20  ms-auto"></i>
                    </div>
                    <div class="fs-10 fw-500 text-uppercase  ">Overtime monthly hrs</div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                  <div class="card card-c2 h-100 p-16 gap-8">
                    <div class="hstack text-accent2 gap-8">
                      <div class="fs-22 fw-500 " *ngIf="advanceDetails?.time_metrix?.late_log_days">
                        {{advanceDetails?.time_metrix?.late_log_days}} Day(s)</div>
                      <div class="fs-22 fw-500 " *ngIf="!advanceDetails?.time_metrix?.late_log_days">0 Day(s)</div>
                      <i class="icon-snooze-fill  fs-20 ms-auto"></i>
                    </div>
                    <div class="fs-10 fw-500 text-uppercase  ">Late logs this month </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                  <div class="card card-c2 h-100 p-16 gap-8">
                    <div class="hstack text-accent1 gap-8">
                      <div class="fs-22  fw-500"
                        *ngIf="appservice.isValidDateTime(today+' '+advanceDetails?.time_metrix?.avg_working_hrs)">
                        {{today+"
                        "+advanceDetails?.time_metrix?.avg_working_hrs|date:'HH:mm'}} Hrs</div>
                      <div class="fs-22 fw-500"
                        *ngIf="!appservice.isValidDateTime(today+' '+advanceDetails?.time_metrix?.avg_working_hrs)">0
                        Hrs
                      </div>
                      <i class="icon-hourglass  fs-20 ms-auto"></i>
                    </div>
                    <div class="fs-10 fw-500 text-uppercase  ">Average working hours </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-6 ">
                  <div class="card card-c2 h-100 p-16 gap-8">
                    <div class="hstack text-accent2 gap-8">
                      <div class="fs-22 fw-500 " *ngIf="advanceDetails?.time_metrix?.on_time_monthly_percentage">
                        {{advanceDetails?.time_metrix?.on_time_monthly_percentage}} %</div>
                      <div class="fs-22 fw-500 " *ngIf="!advanceDetails?.time_metrix?.on_time_monthly_percentage">0 %
                      </div>
                      <i class="icon-clock-fill  fs-20 ms-auto"></i>
                    </div>
                    <div class="fs-10 fw-500 text-uppercase  ">ON TIME </div>
                  </div>
                </div>
                <div class="col-12">
                  <hr class="hr-1">
                </div>
                <div class="col-12">
                  <div class="card card-c2 p-16 hstack border-0 bg-warning gap-8 card-hover"
                    (click)="viewDetail = true">
                    <div class="avatar-circle cbg-3 flex-center fs-16 sq-24">
                      <i class="icon-calendar"></i>
                    </div>
                    <p class="mb-0 fs-14 fw-500">
                      Total paid days :
                      <span>{{advanceDetails?.attendance_details?.total_paid_days ?
                        advanceDetails?.attendance_details?.total_paid_days:'0'}} </span>
                    </p>
                    <span class="ms-auto fs-12 fw-700 text-accent1 text-uppercase">View details</span>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card card-c2 px-24 py-16">
              <div class="row row-16">
                <div class="col-12 d-flex align-items-center">
                  <div class="fs-16 fw-500">Calendar (For {{activeMonth.slice(0,3)}} {{activeYear}})</div>
                  <div class="btn-group ms-auto" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" [value]="1" [(ngModel)]="view"
                      [checked]="isChecked">
                    <label style="min-width: 9.0625rem;" class="btn btn-outline-accent2 text-uppercase fs-14 fw-600"
                      for="btnradio1">Calendar</label>
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" [value]="0" [(ngModel)]="view"
                      [checked]="!isChecked">
                    <label style="min-width: 9.0625rem;" class="btn btn-outline-accent2 text-uppercase fs-14 fw-600"
                      for="btnradio2">Table</label>
                  </div>
                </div>
                <ng-container *ngIf="view == 1 && !loader && !oLoader" >
                  <app-attendance-calendar  [visibleRange]="visibleRange" [activeMonth]="activeMonth" [activeYear]="activeYear"
                    [isEmployeeId]="isEmployeeId" [options]="options" (gotoAction)="gotoAction($event)"
                    [empDoj]="empDoj" (viewempmap)="viewempmapfn($event)"
                    (changeMonthYear)="changeMonthYear($event)"></app-attendance-calendar>
                </ng-container>
                <ng-container *ngIf="view == 0">
                  <app-attendance-table [activeMonth]="activeMonth" [activeYear]="activeYear"
                    [isEmployeeId]="isEmployeeId" [options]="options" (gotoAction)="gotoAction($event)"
                    [empDoj]="empDoj"></app-attendance-table>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal  modal-alert {{checkOutAction == true ? 'show': ''}}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered ">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="bi bi-exclamation-triangle text-danger"></i>
        <h4 class="modal-title">Are you sure?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">This will mark Check-out for the day and you won’t be able to reverse this action.</p>
      </div>
      <div class="modal-footer">
        <button (click)="checkOutAction = false" type="button"
          class="btn text-uppercase btn-outline-primary btn-sm">Cancel</button>
        <button type="button" class="btn text-uppercase btn-danger btn-sm" (click)="checkout();">YES</button>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="payDayDeduction" class="side-panel side-pane-active" style="--sidepanel-width:64.4375rem;"
  [class.side-pane-active]='payDayDeduction === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Paid Day Deduction </h5>
      <a class="toggle-panel" (click)="payDayDeduction = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card card-c2 p-0">
              <div class="table-responsive radius-4">
                <table class="table td-14 sticky-header td-white-space td-fs-14
                                      tr-fs-14  table-striped table-sm form-table">
                  <thead>
                    <tr>
                      <th class="fw-600">Attendance date</th>
                      <th class="fw-600" scope="col">value</th>
                      <th class="fw-600" scope="col">reason</th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr *ngIf="advanceDetails?.paid_day_deduction_data_list.length<=0">
                      <td colspan="3">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngFor="let val of advanceDetails?.paid_day_deduction_data_list">
                      <td>{{val.absent_date| date:this.appservice.getdateformat()}}</td>
                      <td>{{val.deduction_paid_days}}</td>
                      <td>{{val.reason}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="leaveBalanceDeduction" class="side-panel side-pane-active" style="--sidepanel-width:64.4375rem;"
  [class.side-pane-active]='leaveBalanceDeduction === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Leave Balance Deduction</h5>
      <a class="toggle-panel" (click)="leaveBalanceDeduction = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="row row-24 mb-24">
          <div class="col-12">
            <div class="card card-c2 p-0">
              <div class="table-responsive radius-4">
                <table class="table td-14 sticky-header td-white-space td-fs-14
                                      tr-fs-14  table-striped table-sm form-table">
                  <thead>
                    <tr>
                      <th class="fw-600">Attendance date</th>
                      <th class="fw-600" scope="col">value</th>
                      <th class="fw-600" scope="col">Leave code</th>
                      <th class="fw-600" scope="col">Leave name</th>
                      <th class="fw-600" scope="col">Reason</th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr *ngIf="advanceDetails?.leave_dudction_data_list.length<=0">
                      <td colspan="5">
                        <div class="card card-c2 flex-center p-16 bg-dark-500">
                          <span class="fw-500 fs-14">No data found</span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngFor="let val of  advanceDetails?.leave_dudction_data_list">
                      <td>{{val.leave_date_data| date:this.appservice.getdateformat()}}</td>
                      <td>{{val.value}}</td>
                      <td>{{val.leave_code}}</td>
                      <td><p class="w-30ch">{{val.leave_name}}</p></td>
                      <td>{{val.reason}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<app-holiday-setup *ngIf="holidayRequestFlag" [holidayRequestFlag]="holidayRequestFlag"
  [holidayFloatData]="holidayFloatData" [holidatSearchData]="holidatSearchData" [loader]="holiday_loader"
  [nodata]="holiday_nodata" [saveClicked]="saveClicked" (holidayReqClose)="holidayReqCloseFunction($event)"
  (holidaySearch)="holidaySearchFunction($event)" (holidayReqSave)="holidayReqSaveFunction($event)"></app-holiday-setup>
<div class="side-panel" style="--sidepanel-width:45rem;" [class.side-pane-active]='viewDetail === true'>
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <div class="card card-c2 px-24 py-16 ">
          <div class="row row-16">
            <div class="col-12">
              <p class="title mb-8">ATTENDANCE DETAILS</p>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 indicater4 indicater-bg10">
                <p class="mb-0 fs-12">Present</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.present">
                  {{advanceDetails?.attendance_details?.present}} Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.present"> 0 Days</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 indicater4 indicater-bg6">
                <p class="mb-0 fs-12">Paid Leave</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.paid_leave">
                  {{advanceDetails?.attendance_details?.paid_leave}} Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.paid_leave">0 Days</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 indicater4 indicater-bg8">
                <p class="mb-0 fs-12">Week Off</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.week_off">
                  {{advanceDetails?.attendance_details?.week_off}} Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.week_off">0 Days</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 indicater4 indicater-bg9">
                <p class="mb-0 fs-12">Holidays</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.holidays">
                  {{advanceDetails?.attendance_details?.holidays}} Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.holidays">0 Days</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 indicater4 indicater-bg5">
                <p class="mb-0 fs-12">Absent</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.absent">
                  {{advanceDetails?.attendance_details?.absent}} Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.absent">0 Days</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 indicater4 indicater-bg5">
                <p class="mb-0 fs-12">Unpaid Leave</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.unpaid_leave">
                  {{advanceDetails?.attendance_details?.unpaid_leave}} Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.unpaid_leave">0 Days</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 px-16 radius-8 indicater4 indicater-bg5">
                <ngb-accordion class="custom-accordion panel-white panel-p-0 border-0" #acc="ngbAccordion"
                  activeIds="ngb-panel-0">
                  <ngb-panel id="ngb-panel-1">
                    <ng-template ngbPanelHeader>
                      <button ngbPanelToggle class="accordion-button px-0 py-8">
                        <div class="hstack w-100 pe-16">
                          <p class="mb-0 fs-12 text-accent2">Deduction (Leave)</p>
                          <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.leave_deduction">
                            {{advanceDetails?.attendance_details?.leave_deduction}} Days</p>
                          <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.leave_deduction">0
                            Days
                          </p>
                        </div>
                      </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="card card-c2 p-0 overflow-hidden my-16">
                        <div class="table-responsive radius-4">
                          <table
                            class="table td-14 sticky-header td-fs-14 tr-fs-14   table-sm form-table">
                            <thead>
                              <tr>
                                <th class="fw-600">Attendance date</th>
                                <th class="fw-600" scope="col">value</th>
                                <th class="fw-600" scope="col">Leave code</th>
                                <th class="fw-600" scope="col">Leave name</th>
                                <th class="fw-600" scope="col">Reason</th>
                              </tr>
                            </thead>
                            <tbody class="">
                              <tr *ngIf="advanceDetails?.leave_dudction_data_list.length<=0">
                                <td colspan="5">
                                  <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                  </div>
                                </td>
                              </tr>
                              <tr *ngFor="let val of  advanceDetails?.leave_dudction_data_list">
                                <td>{{val.leave_date_data| date:this.appservice.getdateformat()}}</td>
                                <td>{{val.value}}</td>
                                <td>{{val.leave_code}}</td>
                                <td>{{val.leave_name}}</td>
                                <td>{{val.reason}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 px-16 radius-8 indicater4 indicater-bg5">
                <ngb-accordion class="custom-accordion panel-white panel-p-0 border-0" #acc="ngbAccordion"
                  activeIds="ngb-panel-0">
                  <ngb-panel id="ngb-panel-1">
                    <ng-template ngbPanelHeader>
                      <button ngbPanelToggle class="accordion-button px-0 py-8">
                        <div class="hstack w-100 pe-16">
                          <p class="mb-0 fs-12 text-accent2">Deduction (Paid days)</p>
                          <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.paid_day_deduction">
                            {{advanceDetails?.attendance_details?.paid_day_deduction}} Days</p>
                          <p class="mb-0 fw-500 ms-auto"
                            *ngIf="!advanceDetails?.attendance_details?.paid_day_deduction">0 Days
                          </p>
                        </div>
                      </button>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="card card-c2 p-0 overflow-hidden my-16">
                        <div class="table-responsive radius-4">
                          <table class="table td-14 sticky-header td-white-space td-fs-14
                                                tr-fs-14   table-sm form-table">
                            <thead>
                              <tr>
                                <th class="fw-600">Attendance date</th>
                                <th class="fw-600" scope="col">value</th>
                                <th class="fw-600" scope="col">reason</th>
                              </tr>
                            </thead>
                            <tbody class="">
                              <tr *ngIf="advanceDetails?.paid_day_deduction_data_list.length<=0">
                                <td colspan="3">
                                  <div class="card card-c2 flex-center p-16 bg-dark-500">
                                    <span class="fw-500 fs-14">No data found</span>
                                  </div>
                                </td>
                              </tr>
                              <tr *ngFor="let val of advanceDetails?.paid_day_deduction_data_list">
                                <td>{{val.absent_date| date:this.appservice.getdateformat()}}</td>
                                <td>{{val.deduction_paid_days}}</td>
                                <td>{{val.reason}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 indicater4 indicater-bg5">
                <p class="mb-0 fs-12">Arrears</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.arrears">
                  {{advanceDetails?.attendance_details?.arrears}} Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.arrears">0 Days</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 indicater4 indicater-bg8">
                <p class="mb-0 fs-12">Overtime</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.overtime">
                  {{advanceDetails?.attendance_details?.overtime}} Hrs</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.overtime">00:00 Hrs</p>
              </div>
            </div>
            <div class="col-12">
              <div class="card card-c2 py-8 px-16 hstack radius-8 bg8">
                <p class="mb-0 fs-12">Total Paid Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="advanceDetails?.attendance_details?.total_paid_days">
                  {{advanceDetails?.attendance_details?.total_paid_days}} Days</p>
                <p class="mb-0 fw-500 ms-auto" *ngIf="!advanceDetails?.attendance_details?.total_paid_days">0 Days</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-employee-map-view *ngIf="viewempmap" [data]="viewdata" [origin]="origin" [destination]="destination"
  (closepanel)="viewempmap=false"></app-employee-map-view>

  <ng-container *ngIf="alertToggle == true">
    <app-info-popup (modal)="alertToggle = false" [infoToggle]="alertToggle" [infoMsg]="alertMsg"></app-info-popup>
</ng-container>

<app-checkin-capture *ngIf="captureModal" [captureModal]="captureModal" [captureInfoModal]="this.WebcheckinValidations.selfie_login" (pictureTaken)="pictureTaken($event)" (close)="captureModal=false"></app-checkin-capture>


<app-checkin-verification *ngIf="verificationModal" [verificationModal]="verificationModal" [failedmodal]="failedmodal" [WebCamDataUrl]="WebCamDataUrl" [VerifyText]="VerifyText" [retake]="retake" [VerifyClass]="VerifyClass" (close)="verificationModal=false" (retakeFn)="retakeFn()"></app-checkin-verification>
