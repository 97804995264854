<div class="container-fluid p-24" *ngIf="permissions">
  <div class="row mb-16">
    <div class="col-md-4 col-lg-6">
      <h3>Attendance Capture Policy</h3>
      <p class="text-helper mb-md-0">Configure the policy for employees for attendance marking</p>
    </div>
    <div class="col-md-8 col-lg-6">
      <div class="d-flex justify-content-end  gap-16">
        <app-searchbar class="w-100" [searchlist]='searchlistdata' [searchplaceholder]='searchString'
          (searchEmitter)="searchresults($event)"
          (keywordsearchEmitter)="searchAttendanceCapture($event)" [isLoading]="isLoading"></app-searchbar>
        <div ngbDropdown class="d-inline-block">
          <button class="btn-square btn btn-secondary" placement="bottom-left" ngbDropdownToggle>
            <i class="icon-filter-left fs-13"></i>
          </button>
          <div ngbDropdownMenu class="dropdown-menu-end">
            <button ngbDropdownItem [class]="{'active':statusBtn=='All' }"
              (click)="statusBtn='All';selectItemList('')">All</button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Active' }"
              (click)="statusBtn='Active';selectItemList(true)">Active </button>
            <button ngbDropdownItem [class]="{'active':statusBtn=='Deleted' }"
              (click)="statusBtn='Deleted';selectItemList(false)">Deleted</button>
          </div>
        </div>
        <button routerLink="/attendance-capture-policy/attendance-capture-policy-setup"
          class="btn flex-shrink-0 btn-primary btn-icon btn-add" *ngIf="permissions?.a"><i class="icon-plus"></i>Add
          ATTENDANCE
          CAPTURE POLICY</button>
      </div>
    </div>
  </div>
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div class="row row-16" *ngIf="loader">
    <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
      *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
  </div>
  <div *ngIf="!listflag && !loader;
      else all_data " class="row" style="height: calc(100vh - 15rem);">
    <app-nodata-view class="vstack" [noDataImg]="'bg6.png'" [noDataText]="'Capture Attendance Your Way'"
      [noDataPara]="'Ready to set up your attendance policy? Click \'Add Capture Policy\' and get it done quickly!'"
      [hasPermission]="permissions?.a" (addData)="addNewdata()"> </app-nodata-view>
  </div>
  <ng-template #all_data>
    <div class="row row-16" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [fromRoot]="true"
      (scrolled)="onScrollDown()">
      <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let attendenceCaptureList of attendancecaptureArr">
        <div class="card card-custom1 card-hover">
          <div class="card-body p-0 d-flex align-items-start">
            <div class="d-flex view-section" (click)="viewAttencecaptureDetails(attendenceCaptureList.id)">
              <div class="logo-img {{attendenceCaptureList.color_code}}">
                {{this.appService.getFirstChar(attendenceCaptureList.name,2)}}</div>
              <div class="overflow-hidden">
                <h6 class="mb-1 tips">{{attendenceCaptureList?.name}}</h6>
                <span class="tips-helper">{{attendenceCaptureList?.name}}</span>
                <span
                  class="fs-10 text-uppercase fw-500 {{(attendenceCaptureList.is_active == true) ? 'text-success' : 'text-danger'}}">{{(attendenceCaptureList.is_active
                  == true) ? 'Active' : 'Deleted'}}</span>
              </div>
            </div>
            <div *ngIf="attendenceCaptureList.is_active==true && (permissions.e || permissions.d)" ngbDropdown
              class="dropdown-section d-inline-block " style="width: 1.625rem">
              <button class="btn-reset p-0" placement="bottom-left" id="dropdownBasic1" ngbDropdownToggle>
                <i class="icon-three-dots-vertical fs-16"></i>
              </button>
              <div ngbDropdownMenu class="bottom-left fs-14" aria-labelledby="dropdownBasic1">
                <button *ngIf="permissions.e" ngbDropdownItem
                  (click)="editAttencecapturePolicy(attendenceCaptureList.id)">
                  <i class="icon-pencil-square me-1"></i> Edit
                </button>
                <button *ngIf="permissions.d" class="text-danger" ngbDropdownItem
                  (click)="deleteAlert=true;deleteId=attendenceCaptureList.id">
                  <i class="icon-trash  me-1"></i> Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Loader updations -->
      <ng-container *ngIf="infinityloader">
        <app-common-loader class="col-xl-3 col-lg-4 col-md-6 col-sm-12" [cardType]="'card2'"
          *ngFor="let dummy of ' '.repeat(10).split(''), let x = index"></app-common-loader>
      </ng-container>

    </div>
  </ng-template>
</div>
<div class="side-panel side-pane-active" style="--sidepanel-width:64.4375rem;"
  [class.side-pane-active]='viewDetail === true' *ngIf="permissions">
  <div class="side-panel-container" autocomplete="off">
    <header class="side-panel-head">
      <h5>Details</h5>
      <a class="toggle-panel" (click)="viewDetail = false"><i class="icon-close-lg fs-16"></i></a>
    </header>
    <div class="side-panel-body" style="overflow-y: auto;">
      <div class="container-fluid p-32">
        <app-view-loader *ngIf="viewLoader"></app-view-loader>
        <ng-container *ngIf="!viewLoader">
          <div class="d-flex mb-16">
            <p class="text-uppercase fw-500 mb-0">Attendance capture policy</p>
            <a *ngIf="viewData.is_active && permissions?.e" (click)="editAttencecapturePolicy(viewData.id)"
              class="link-primary fs-14 d-flex align-items-center ms-auto">
              <i class="icon-pencil-square me-2"></i> Edit
            </a>
          </div>
          <div class="row row-24 mb-24">
            <div class="col-12">
              <div class="card details-card shadow-0 px-24 py-16">
                <div class="row row-24 ">
                  <div class="col-12 "><span class="title">Policy details</span></div>
                  <div class="col-sm-3 col-12">
                    <div class="detail-title">Policy name</div>
                    <div class="detail-desc">{{viewData?.name}}</div>
                  </div>
                  <div class="col-sm-3 col-12">
                    <div class="detail-title">Status</div>
                    <div class="detail-desc">
                      <span
                        class="fs-12 fw-500 text-uppercase {{(viewData.is_active == true) ? 'text-success' : 'text-danger'}}">{{viewData.is_active?
                        'ACTIVE' : 'DELETED'}}</span>

                    </div>
                  </div>
                  <div class=" col-12">
                    <div class="detail-title">Policy description</div>
                    <div class="detail-desc">
                      {{viewData?.attend_capture_desc!=null?viewData?.attend_capture_desc:'-'}}
                    </div>
                  </div>
                  <ng-container *ngIf="viewData?.web_checkin">
                    <div class="col-12">
                      <div class="d-flex align-items-center gap-8 fs-14">
                        <i class="icon-check-square-fill text-light-500"></i>Web checkin
                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Do you want to enable IP restriction</div>
                      <div class="detail-desc">{{viewData?.is_ip_restricted==true?'Yes':'No'}}
                      </div>
                    </div>
                    <ng-container *ngIf="viewData?.is_ip_restricted">
                      <ng-container *ngFor="let ips of viewData?.attendance_capture_iprange
                                            ">
                        <div class="col-lg-6 col-sm-6 col-12">
                          <div class="detail-title">From IP range</div>

                          <div class="detail-desc">{{ips?.from_ip_range}}</div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12">
                          <div class="detail-title">To IP range</div>
                          <div class="detail-desc">{{ips?.to_ip_range}}</div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="viewData?.mobile_checkin">
                    <div class="col-12 ">
                      <div class="d-flex align-items-center gap-8 fs-14">
                        <i class="icon-check-square-fill text-light-500"></i>Mobile check in
                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Does location need to be captured?</div>
                      <div class="detail-desc">
                        {{viewData?.location_captured==true?'Yes':'No'}}</div>
                    </div>

                    <div class="col-sm-12 col-12" *ngIf="viewData?.location_captured==true">
                      <div class="detail-title">Do you want to enable geo fencing?</div>
                      <div class="detail-desc">
                        {{viewData?.enable_geo_fencing==true?'Yes':'No'}}</div>

                    </div>
                    <ng-container *ngIf="viewData?.enable_geo_fencing==false && viewData?.location_captured==true">
                      <div class="col-sm-12 col-12">
                        <div class="detail-title">Require approval for remote check-in?</div>
                        <div class="detail-desc">
                          {{viewData?.location_request_approves==true?'Yes':'No'}}</div>

                      </div>
                    </ng-container>
                    <div class="col-sm-12 col-12">
                      <div class="detail-title">Enable real time location track?</div>
                      <div class="detail-desc">
                        {{viewData?.enable_realtime_tracking==true?'Yes':'No'}}</div>

                    </div>
                    <ng-container *ngIf="viewData?.enable_realtime_tracking">
                      <div class="col-sm-12 col-12">
                        <div class="detail-title">Location track interval (in minutes)
                        </div>
                        <div class="detail-desc">{{viewData?.track_intravel}}</div>
                      </div>
                      <div class=" col-12">
                        <div class="detail-title">Do you want to notify reporting manager regarding live location of
                          employees?</div>
                        <div class="detail-desc">
                          {{viewData?.live_location_notify==true?'Yes':'No'}}</div>
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="viewData?.is_selfie_enabled">
                    <div class="col-12 ">
                      <div class="d-flex align-items-center gap-8 fs-14">
                        <i class="icon-check-square-fill text-light-500"></i>Selfie attendance
                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Do you want to capture and save photos when employees check in/check out?</div>
                      <div class="detail-desc">
                        {{viewData?.is_save_photo==true?'Yes':'No'}}</div>
                    </div>
                    <ng-container *ngIf="viewData?.is_save_photo">
                      <div class="col-sm-12 col-12">
                        <div class="detail-title">Save photo when employee check-in/check-out from?</div>
                        <div *ngIf="viewData?.save_photo_checkin_from_mobile == true" class="d-flex align-items-center gap-8 fs-14"> <i class="icon-check-square-fill text-light-500"></i> Mobile</div>
                        <div *ngIf="viewData?.save_photo_checkin_from_mobile == false" class="d-flex align-items-center gap-8 fs-14"> <i  class="icon-square "></i> Mobile</div>
                        <div *ngIf="viewData?.save_photo_checkin_from_web == true" class="d-flex align-items-center gap-8 fs-14"> <i  class="icon-check-square-fill text-light-500"></i> Web</div>
                        <div *ngIf="viewData?.save_photo_checkin_from_web == false" class="d-flex align-items-center gap-8 fs-14"> <i  class="icon-square "></i> Web</div>
                      </div>
                    </ng-container>

                    <div class=" col-12">
                      <div class="detail-title">Do you want to capture and verify photos when employees check-in/check-out?</div>
                      <div class="detail-desc">
                        {{viewData?.is_photo_need_verification==true?'Yes':'No'}}</div>
                    </div>
                    <ng-container *ngIf="viewData?.is_photo_need_verification">
                      <div class="col-sm-12 col-12">
                        <div class="detail-title">Verify photo when employee check-in/check-out from?</div>
                        <div *ngIf="viewData?.verify_photo_checkin_from_mobile == true" class="d-flex align-items-center gap-8 fs-14"> <i  class="icon-check-square-fill text-light-500"></i> Mobile</div>
                        <div *ngIf="viewData?.verify_photo_checkin_from_mobile == false" class="d-flex align-items-center gap-8 fs-14"> <i  class="icon-square "></i> Mobile</div>
                        <div *ngIf="viewData?.verify_photo_checkin_from_web == true" class="d-flex align-items-center gap-8 fs-14"> <i  class="icon-check-square-fill text-light-500"></i> Web</div>
                        <div *ngIf="viewData?.verify_photo_checkin_from_web == false" class="d-flex align-items-center gap-8 fs-14"> <i  class="icon-square "></i> Web</div>
                      </div>
                    </ng-container>

                  </ng-container>


                  <ng-container *ngIf="viewData?.on_duty">
                    <div class="col-12 ">
                      <div class="d-flex align-items-center gap-8 fs-14">
                        <i class="icon-check-square-fill text-light-500"></i>On duty
                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Do you want to allow backdated on duty</div>
                      <div class="detail-desc">
                        {{viewData?.is_allow_backdated_onduty==true?'Yes':'No'}}</div>
                    </div>
                    <ng-container *ngIf="viewData?.is_allow_backdated_onduty">
                      <div class="col-sm-12 col-12">
                        <div class="detail-title">Maximum back days On duty an employee can
                          apply</div>
                        <div class="detail-desc">{{viewData?.max_on_duty_back_days}}
                          {{addSufix('backdated_onduty_daytype','max_on_duty_back_days')}}
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-sm-12 col-12">
                      <div class="detail-title">Maximum number of On duty days allowed in a
                        {{viewData?.max_on_duty_day_type==false?'Week':'Month'}}

                      </div>
                      <div class="detail-desc">
                        {{viewData?.max_on_duty_request_days}}</div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Do you want to allow future dated on duty?</div>
                      <div class="detail-desc">{{viewData?.is_future_on_duty==true?'Yes':'No'}}
                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Prior days notice requires for applying On duty
                      </div>
                      <div class="detail-desc">
                        {{viewData?.is_prior_notice_for_on_duty==true?'Yes':'No'}}
                        &nbsp;<span *ngIf="viewData?.is_prior_notice_for_on_duty">
                          {{viewData?.prior_day_for_on_duty}}
                        </span></div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="viewData?.is_wfh_allowed">
                    <div class="col-12 ">
                      <div class="d-flex align-items-center gap-8 fs-14">
                        <i class="icon-check-square-fill text-light-500"></i>Work from home
                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Do you want to allow backdated WFH?</div>
                      <div class="detail-desc">
                        {{viewData?.is_backdated_wfh_allowed==true?'Yes':'No'}}</div>
                    </div>
                    <ng-container *ngIf="viewData?.is_backdated_wfh_allowed">
                      <div class="col-sm-12 col-12">
                        <div class="detail-title">Maximum back days WFH an employee can apply
                        </div>
                        <div class="detail-desc">{{viewData?.max_wfh_back_days}}
                          {{addSufix('backdated_wfh_daytype','max_wfh_back_days')}}
                        </div>
                      </div>
                    </ng-container>
                    <div class="col-sm-12 col-12">
                      <div class="detail-title">Maximum number of WFH days allowed in a
                        {{viewData?.max_wfh_request_day_type==false?'Week':'Month'}}

                      </div>
                      <div class="detail-desc">
                        {{viewData?.max_wfh_request_days}}</div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Do you want to allow future dated WFH?</div>
                      <div class="detail-desc">{{viewData?.is_future_wfh==true?'Yes':'No'}}</div>
                    </div>
                    <div class=" col-12" *ngIf="viewData?.is_future_wfh">
                      <div class="detail-title">Prior days notice requires for applying WFH</div>
                      <div class="detail-desc">
                        {{viewData?.is_prior_notice_for_wfh==true?'Yes':'No'}}
                        &nbsp;<span *ngIf="viewData?.is_prior_notice_for_wfh">
                          {{viewData?.prior_day_for_wfh}}
                        </span></div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Restrict employees from raising WFH request on</div>
                      <div class="detail-desc">{{viewData?.request_raising_restricted == 'Holiday & Weekend' ? 'Holidays & Weekends' : viewData?.request_raising_restricted == 'Holiday' ? 'Holidays' : viewData?.request_raising_restricted == 'Weekend' ? 'Weekends' : 'None'}}</div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="viewData?.is_attend_regular_allowed">
                    <div class="col-12 ">
                      <div class="d-flex align-items-center gap-8 fs-14">
                        <i class="icon-check-square-fill text-light-500"></i>Attendance
                        regularization
                      </div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Do you want to allow backdated attendance
                        regularization</div>
                      <div class="detail-desc">
                        {{viewData?.is_backdated_attend_regular_allowed==true?'Yes':'No'}}</div>
                    </div>
                    <ng-container *ngIf="viewData?.is_backdated_attend_regular_allowed">
                      <div class=" col-12">
                        <div class="detail-title">Maximum back days attendance regularization an
                          employee can apply</div>
                        <div class="detail-desc">{{viewData?.max_attend_regular_back_days}} {{addSufix('backdated_attend_regular_daytype','max_attend_regular_back_days')}}
                        </div>
                      </div>
                    </ng-container>
                    <div class=" col-12">
                      <div class="detail-title">Maximum number of attendance regularization
                        allowed in a
                        {{viewData?.max_attend_regular_day_type==false?'Week':'Month'}}
                      </div>
                      <div class="detail-desc">
                        {{viewData?.max_attend_regular_request_days}}</div>
                    </div>
                    <div class=" col-12">
                      <div class="detail-title">Allow future dated attendance regularization</div>
                      <div class="detail-desc">
                        {{viewData?.is_future_attend_regular_allowed==true?'Yes':'No'}}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card details-card shadow-0 px-24 py-16">
              <div class="row row-24 ">
                <div class="col-12 "><span class="title">APPLICABILITY</span></div>
                <div class="col-12">
                  <div class="detail-title">Applicable to</div>
                  <div class="detail-desc">{{viewApplicable}}</div>
                </div>
                <div class="col-12 ">
                  <div class="detail-title">{{viewApplicable}}(s) </div>
                  <div class="detail-desc">
                    <div class="selected-values mt-1">
                      <ng-container *ngIf="viewApplicable == 'Employee'">
                        <li class="ng-value" *ngFor="let rights of viewRightsArray, let i=index;">
                          <div class="ng-value-label" title="{{rights.name}}">
                            <span class="d-inline-flex align-items-center">
                              <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                *ngIf="rights.img !=  '' && rights.img !=  null">
                              <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                              <span> {{rights.name}}</span>
                            </span>
                          </div>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="viewApplicable == 'Company'">
                        <li class="ng-value" *ngFor="let rights of viewRightsArray, let i=index;">
                          <div class="ng-value-label" title="{{rights.name}}">
                            <span class="d-inline-flex align-items-center">
                              <img class="img-fluid rounded-circle sq-24" src="{{rights.img}}"
                                *ngIf="rights.img !=  '' && rights.img !=  null">
                              <span class="avatar sq-24 avatar-circle {{rights.color_code}}"
                                *ngIf="rights.img ==  null || rights.img ==  ''">{{this.appService.getFirstChar(rights.name,1)}}</span>
                              <span> {{rights.name}}</span>
                            </span>
                          </div>
                        </li>
                      </ng-container>
                      <ng-container *ngIf="(viewApplicable != 'Employee') && (viewApplicable != 'Company')">
                        <div class="d-flex flex-wrap mt-8 gap-8">
                          <ng-container *ngFor="let rights of viewRightsArray, let i=index;">
                            <span class="badge3" title="{{rights}}"> {{ (rights.length>25)?
                              (rights | slice:0:25)+'..':(rights) }}
                            </span>
                          </ng-container>

                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div class="modal  modal-alert {{ deleteAlert == true ? 'show' : '' }}" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header gap-16">
        <i class="icon-trash text-danger"></i>
        <h4 class="modal-title">Delete Attendance Capture Policy?</h4>
      </div>
      <div class="modal-body">
        <p class="mb-0">
          {{this.messageService.Deletemsgdisplay('Attendance capture policy')}}
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm fw-500 text-uppercase"
          (click)="deleteAlert = false">
          Cancel
        </button>
        <button type="button" class="btn btn-danger btn-sm fw-500 text-uppercase" [disabled]="deleteClicked"
          (click)="confirmDelete(deleteId)">Delete</button>
      </div>
    </div>
  </div>
</div>
