import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { ReportsService } from 'src/app/@core/services/reports.service';
import { AppService } from 'src/app/app.global';
import { CtcPerSalaryComponent } from '../payroll-report-configure/ctc-per-salary/ctc-per-salary.component';
import { CtcRevisionComponent } from '../payroll-report-configure/ctc-revision/ctc-revision.component';
import { EmployeeStatutoryComponent } from '../payroll-report-configure/employee-statutory/employee-statutory.component';
import { EsiComponent } from '../payroll-report-configure/esi/esi.component';
import { FinalSettlementReportComponent } from '../payroll-report-configure/final-settlement-report/final-settlement-report.component';
import { InvestmentNotSubmittedComponent } from '../payroll-report-configure/investment-not-submitted/investment-not-submitted.component';
import { InvestmentRequestComponent } from '../payroll-report-configure/investment-request/investment-request.component';
import { LwfComponent } from '../payroll-report-configure/lwf/lwf.component';
import { MonthlyTDSComponent } from '../payroll-report-configure/monthly-tds/monthly-tds.component';
import { PaySalaryComponent } from '../payroll-report-configure/pay-salary/pay-salary.component';
import { PfComponent } from '../payroll-report-configure/pf/pf.component';
import { PtComponent } from '../payroll-report-configure/pt/pt.component';
import { ReimbursementRequestComponent } from '../payroll-report-configure/reimbursement-request/reimbursement-request.component';
import { SalaryOnHoldComponent } from '../payroll-report-configure/salary-on-hold/salary-on-hold.component';
import { SalaryRegisterComponent } from '../payroll-report-configure/salary-register/salary-register.component';
import { YtdSalaryDetailsComponent } from '../payroll-report-configure/ytd-salary-details/ytd-salary-details.component';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { MatSort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AttendanceReportsService } from 'src/app/@core/services/attendance-reports.service';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { PayComponentService } from 'src/app/@core/services/pay-component.service';
import { GratitudePaidComponent } from '../payroll-report-configure/gratitude-paid/gratitude-paid.component';
import { NpsEmployerComponent } from '../payroll-report-configure/nps-employer/nps-employer.component';
import { OvertimePaymentComponent } from '../payroll-report-configure/overtime-payment/overtime-payment.component';
import { TdsComputationComponent } from '../payroll-report-configure/tds-computation/tds-computation.component';
import { VariablePaidComponent } from '../payroll-report-configure/variable-paid/variable-paid.component';

@Component({
  selector: 'app-payroll-report-configuration',
  templateUrl: './payroll-report-configuration.component.html',
  styleUrls: ['./payroll-report-configuration.component.scss']
})
export class PayrollReportConfigurationComponent implements OnInit {

  // Variables Initialization
  reportId          : number = 0
  reportName        : string = ''
  formData          : string = ''
  closeControl      : any = ''
  resetFrom         : any = ''
  isLoading         : boolean = false
  noData            : boolean = false
  addColumnFilter   : boolean = false
  viewDetail        : boolean = false
  alertShow         : boolean = true
  reportDownload    : boolean = false
  applyFilterPop    : boolean = false
  resetFilterPop    : boolean = false
  saveFilterModalClicked : boolean = false
  resetFilterModalClicked: boolean = false
  initialLoaded     : boolean = false
  colRearranged     : boolean = false
  yearsList         : any = []
  calcHeaders       : any = []
  reimbursement_dropdown : any = []
  apiData           : any = []
  apiExcelData      : any = []
  displayedColumns  : any = []
  hint_dict         : any = {}
  resDataFilter     : any
  resHeaderFilter   : any
  variableDropdown  : any = []

  // Store initial state of columns
  initialFormState: any;

  // Sort
  ordering          : String = 'Employee Code'
  direction         : string = 'asc'
  sortProperty      : string = 'Employee Code'
  isSorted          : boolean = false

  // Page
  limit             : number = 20
  offset            : number = 0
  lastPageCount     : number = 0
  page              : number = 1
  pageNumber        : number = 1

  //Tag Filter Variables
  appliedFilter: any = {};
  tagMultiData: any = [];
  tagMultiCnt: any = 0;

  // Filter Memorize
  filterSubmitMem   : boolean = false
  addedColumns      : boolean = false

  @ViewChild(MonthlyTDSComponent, { static: false }) appMonthly!: MonthlyTDSComponent;
  @ViewChild(EsiComponent, { static: false }) appEsi!: EsiComponent;
  @ViewChild(PtComponent, { static: false }) appPt!: PtComponent;
  @ViewChild(PfComponent, { static: false }) appPf!: PfComponent;
  @ViewChild(CtcPerSalaryComponent, { static: false }) appCtc!: CtcPerSalaryComponent;
  @ViewChild(SalaryRegisterComponent, { static: false }) appsalaryRegister!: SalaryRegisterComponent;
  @ViewChild(PaySalaryComponent, { static: false }) appReleaseSalary!: PaySalaryComponent;
  @ViewChild(YtdSalaryDetailsComponent, { static: false }) appYtdSalaryDetails!: YtdSalaryDetailsComponent;
  @ViewChild(InvestmentRequestComponent, { static: false }) appInvestmentRequest!: InvestmentRequestComponent;
  @ViewChild(CtcRevisionComponent, { static: false }) appCTCRevision!: CtcRevisionComponent;
  @ViewChild(SalaryOnHoldComponent, { static: false }) appSalaryOnHold!: SalaryOnHoldComponent;
  @ViewChild(LwfComponent, { static: false }) appLwf!: LwfComponent;
  @ViewChild(EmployeeStatutoryComponent, {static: false}) appEmployee_statutory!: EmployeeStatutoryComponent
  @ViewChild(ReimbursementRequestComponent, {static: false}) appReimburse!: ReimbursementRequestComponent
  @ViewChild(FinalSettlementReportComponent, {static: false}) appFinalSettlement!: FinalSettlementReportComponent
  @ViewChild(InvestmentNotSubmittedComponent, {static: false}) investmentNotSubmitted!: InvestmentNotSubmittedComponent
  @ViewChild(VariablePaidComponent, { static: false }) appVarPaid!: VariablePaidComponent;
  @ViewChild(OvertimePaymentComponent, { static: false }) appOverPy!: OvertimePaymentComponent;
  @ViewChild(GratitudePaidComponent, { static: false }) appGratitude!: GratitudePaidComponent;
  @ViewChild(NpsEmployerComponent, { static: false }) appNPS!: NpsEmployerComponent;
  @ViewChild(TdsComputationComponent, { static: false }) appTDSComp!: TdsComputationComponent;

  // Form Initialization
  // Side Panel Form
  sidePanelForm     !: FormGroup

  // Filter Save Form
  filterSaveForm = this.fb.group({
    filter: true,
  });

  // Common OU Form
  OuFilterForm = this.fb.group({
    leave_policy: [''],
    leave_type: [''],
    company: [''],
    bu: [''],
    branch: [''],
    designation: [''],
    department: [''],
    employee_list: [''],
    grade: [''],
    employee_type: [''],
    employment_status: [''],
  })

  employeeForm = this.fb.group({
    salary_month          : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm
  });

  investmentForm = this.fb.group({
    investment_type       : ['',[Validators.required]],
    requested_on          : '',
    req_specific_month    : null,
    req_specific_year     : null,
    financial_year        : [null,[Validators.required]],
    ouFilterForm          : this.OuFilterForm
  });

  ptForm = this.fb.group({
    salary_month       : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm
  });

  lwfForm = this.fb.group({
    salary_month        : ['this_month',[Validators.required]],
    sm_specific_month   : null,
    sm_specific_year    : null,
    ouFilterForm        : this.OuFilterForm
  });

  salaryRegisterForm = this.fb.group({
    salary_month          : ['this_month',[Validators.required]],
    sm_specific_month     : null,
    sm_specific_year      : null,
    pay_group             : '',
    date_of_joining       : '',
    doj_specific_month    : null,
    doj_specific_year     : null,
    date_of_leaving       : '',
    dol_specific_month    : null,
    dol_specific_year     : null,
    ouFilterForm          : this.OuFilterForm
  });

  ctcSalaryStructureForm = this.fb.group({
    ctc_breakup           : ['monthly',[Validators.required]],
    pay_group             : ['',[Validators.required]],
    date_of_joining       : '',
    doj_specific_month    : null,
    doj_specific_year     : null,
    date_of_leaving       : '',
    dol_specific_month    : null,
    dol_specific_year     : null,
    ouFilterForm          : this.OuFilterForm
  });

  releseSalaryForm = this.fb.group({
    salary_month          : ['this_month',[Validators.required]],
    sm_specific_month     : null,
    sm_specific_year      : null,
    ouFilterForm          : this.OuFilterForm
  });

  esiForm = this.fb.group({
    salary_month          : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    pay_group             : '',
    ouFilterForm          : this.OuFilterForm
  });

  ytdSalaryForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    pay_group             : '',
    financial_year        : [null ,[Validators.required]],
  });

  salaryOnoldForm = this.fb.group({
    salary_month          : ['this_month',[Validators.required]],
    sm_specific_month     : null,
    sm_specific_year      : null,
    ouFilterForm          : this.OuFilterForm,
  });

  monthlyTDSForm = this.fb.group({
    salary_month          :['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm,
  })

  // Employee Statutory
  employeeStatutoryForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    date_of_joining       : '',
    doj_specific_month    : null,
    doj_specific_year     : null,
    date_of_leaving       : '',
    dol_specific_month    : null,
    dol_specific_year     : null,
    esi_applicable        :'',
    pf_applicable                    :'',
    pt_applicable                    : '',
    lwf_applicable                   :'',
    tax_regime            : ''
  })

  // CTC Revision
  ctcRevisionForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    date_of_joining       : '',
    doj_specific_month    : null,
    doj_specific_year     : null,
    date_of_leaving       : '',
    dol_specific_month    : null,
    dol_specific_year     : null,
    pay_group             :'',
    effective_month       : '',
    effective_specific_month : null,
    effective_specific_year : null,
    payout_month          : '',
    payout_specific_month : null,
    payout_specific_year : null
  })

  // Reimbursement Request
  reimbursementRequestForm = this.fb.group({
    ouFilterForm          : this.OuFilterForm,
    reimbursement_component : '',
    request_status        : '',
    requested_on          : ['',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    request_type          : '',
    financial_year        : ['',[Validators.required]]
  })

  // Final settlemnt
  finalSettlementForm = this.fb.group({
    settlement_date       : ['',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm,
    settlement_done_by    : ''
  })

  investmentnsForm = this.fb.group({
    investment_type       :this.fb.array( [true,true],
    c => {
      const atLestOneChecked = (c as FormArray).controls.find(
        x => x.value === true
      );
      if (atLestOneChecked) {
        return {};
      }
      return { required: true };
    }),
    financial_year        : [null,[Validators.required]],
    ouFilterForm          : this.OuFilterForm
  });

  variablePaidForm = this.fb.group({
    salary_month          : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    variable_component    : '',
    ouFilterForm          : this.OuFilterForm
  });

  overtimePaymentForm = this.fb.group({
    ot_payment_month      : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm
  });

  gratitudePaidForm = this.fb.group({
    financial_year        : ['',[Validators.required]],
    ouFilterForm          : this.OuFilterForm
  });

  npsForm = this.fb.group({
    salary_month          : ['this_month',[Validators.required]],
    specific_month        : null,
    specific_year         : null,
    ouFilterForm          : this.OuFilterForm
  });

  tdsComputationForm = this.fb.group({
    financial_year        : ['',[Validators.required]],
    ouFilterForm          : this.OuFilterForm
  });

  // Mat Table
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private fb       : FormBuilder,
    public route     : ActivatedRoute,
    public appService: AppService,
    private rprtServ : ReportsService,
    private cd       : ChangeDetectorRef,
    public router    : Router,
    private notify   :  NotificationService,
    private attendReprtServ : AttendanceReportsService,
    public pay_componentServ : PayComponentService
  ) { }

  ngOnInit(): void {
    // ID & Report Name from Params
    this.route.params.subscribe((params: Params) => {
      if (!isNaN(params['id'])) {
        this.reportId = params['id'];
      }
      if(params['name']){
        if(this.formatReportName(params['name']) == 'Pay Salary(Bank Transfer)Report'){
          this.reportName = 'Pay Salary (Bank Transfer) Report'
        }else{
          this.reportName = this.formatReportName(params['name'])
        }
        this.initialLoaded = true
        this.isLoading = true
        this.getDateFormat().then(() => {
          this.reportInitialSetups();
        });
      }
    })
    this.yearListFunction()
  }

  // Naming Setup
  formatReportName(name: string): string {
    return name
      // Handle acronyms followed by text in parentheses
      .replace(/([A-Z]+)\(([A-Za-z]+)\)/g, (_, acronym, fullText) => {
          let spacedText = fullText.replace(/([A-Z][a-z])/g, ' $1').trim();
          return `${acronym} (${spacedText})`;
      })
      // Handle camel case and ensure acronyms stay together
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .trim();
  }

  // Fn to setup initials for all reports
  reportInitialSetups(){
    this.isLoading = true
    this.apiExcelData = []
    this.appliedFilter = {};
    this.rprtServ.reportAPI(this.urlGenerator()?.url,this.limit,this.offset,this.ordering,this.formData).subscribe((res:any)=>{
      if(res?.data?.length != 0){
        this.noData = false;
        // Formatting dates and fields if needed
        for(let i = 0; i < res?.data?.length; i++){
          res.data[i]['Date Of Birth'] = (res.data[i]['Date Of Birth'] == null || res.data[i]['Date Of Birth'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date Of Birth']);
          res.data[i]['Request On'] = (res.data[i]['Request On'] == null || res.data[i]['Request On'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Request On']);
          res.data[i]['Approved On']  = (res.data[i]['Approved On'] == null || res.data[i]['Approved On'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Approved On']);
          res.data[i]['Salary Date'] = (res.data[i]['Salary Date'] == null || res.data[i]['Salary Date'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Salary Date']);
          res.data[i]['Salary Processing Date'] = (res.data[i]['Salary Processing Date'] == null || res.data[i]['Salary Processing Date'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Salary Processing Date']);
          res.data[i]['Salary Release Date'] = (res.data[i]['Salary Release Date'] == null || res.data[i]['Salary Release Date'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Salary Release Date']);
          res.data[i]['Date of Releaving'] = (res.data[i]['Date of Releaving'] == null || res.data[i]['Date of Releaving'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date of Releaving']);
          res.data[i]['Salary hold date'] = (res.data[i]['Salary hold date'] == null || res.data[i]['Salary hold date'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Salary hold date']);
          res.data[i]['Salary Revised from'] = (res.data[i]['Salary Revised from'] == null || res.data[i]['Salary Revised from'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Salary Revised from']);
          res.data[i]['Arrear with effect from'] = (res.data[i]['Arrear with effect from'] == null || res.data[i]['Arrear with effect from'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Arrear with effect from']);
          res.data[i]['Date of resignation'] = (res.data[i]['Date of resignation'] == null || res.data[i]['Date of resignation'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date of resignation']);
          res.data[i]['Date of settlement'] = (res.data[i]['Date of settlement'] == null || res.data[i]['Date of settlement'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date of settlement']);
          res.data[i]['Date of Relieving'] = (res.data[i]['Date of Relieving'] == null || res.data[i]['Date of Relieving'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date of Relieving']);
          res.data[i]['Date of relieving'] = (res.data[i]['Date of relieving'] == null || res.data[i]['Date of relieving'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date of relieving']);
          res.data[i]['DOJ'] = (res.data[i]['DOJ'] == null || res.data[i]['DOJ'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['DOJ']);
          res.data[i]['Requested On'] = (res.data[i]['Requested On'] == null || res.data[i]['Requested On'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Requested On']);
          res.data[i]['Rejected On'] = (res.data[i]['Rejected On'] == null || res.data[i]['Rejected On'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Rejected On']);
          res.data[i]['Claim Date'] = (res.data[i]['Claim Date'] == null || res.data[i]['Claim Date'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Claim Date']);
          res.data[i]['Bill Date'] = (res.data[i]['Bill Date'] == null || res.data[i]['Bill Date'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Bill Date']);
          if(this.reportName == 'Investment Request' || this.reportName == 'Final Settlement'){
            res.data[i]['Date of joining']  = (res.data[i]['Date of joining'] == null || res.data[i]['Date of joining'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date of joining']);
          }else{
            res.data[i]['Date Of Joining'] = (res.data[i]['Date Of Joining'] == null || res.data[i]['Date Of Joining'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date Of Joining']);
          }
          if(this.reportName == 'PT (Professional Tax)' || this.reportName == 'LWF (Labour Welfare Fund)'){
            res.data[i]['Salary Month'] = (res.data[i]['Salary Month'] == null || res.data[i]['Salary Month'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Salary Month']);
          }else{
            res.data[i]['Salary month'] = (res.data[i]['Salary month'] == null || res.data[i]['Salary month'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Salary month']);
          }
          if(this.reportName == 'CTC Revision'){
            res.data[i]['Date Of Leaving'] = (res.data[i]['Date Of Leaving'] == null || res.data[i]['Date Of Leaving'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date Of Leaving']);
          }else{
            res.data[i]['Date of leaving']   = (res.data[i]['Date of leaving'] == null || res.data[i]['Date of leaving'] == '') ? '' : this.appService.dateFormatDisplay(res.data[i]['Date of leaving']);
          }
          if(this.reportName != 'Gratuity Paid' && this.reportName != 'NPS Employer Contribution' && this.reportName != 'Overtime Payment'  && this.reportName != 'TDS Computation'){
            res.data[i]['Official Email'] = res.data[i]['Work Email']
          }
        }
        this.apiExcelData = res?.data
        const limitedData = res?.data.slice(0, 20);
        this.limit = 20
        this.apiData = new MatTableDataSource(limitedData);
        this.setDataSourceAttributes();
      }else{
        this.noData = true
      }
      this.settingFormVal(res?.applied_filter)
      this.lastPageCount = res?.count;
      this.resDataFilter = res?.data_filter;
      this.resHeaderFilter = res?.header_filter
      this.appliedFilter = res?.applied_filter;
      if(this.initialLoaded){
        this.rprtServ.setDataFilter(this.resDataFilter)
        this.rprtServ.setHeaderFilter(this.resHeaderFilter)
        this.initialLoaded = false;
      }
      // Columns setup by considering page & sort
      if(!this.isSorted){
        this.setColumnsPanel(res?.check_list,res?.header_list);
      }
      // Saved data setup for filter
      if(Object.keys(this.resDataFilter)?.length != 0){
        if(this.resDataFilter.investment_type=="True")
          this.resDataFilter.investment_type = [false,true]
        else if(this.resDataFilter.investment_type=="False")
          this.resDataFilter.investment_type = [true,false]

        this.urlGenerator()?.form.reset(this.resDataFilter)
        this.OuFilterForm.reset(this.resDataFilter)
        if(this.resDataFilter?.emp_status.length > 0){
          this.OuFilterForm.get('employment_status')?.setValue(this.resDataFilter.emp_status)
        }
        if(this.resDataFilter?.emp_type.length > 0){
          this.OuFilterForm.get('employee_type')?.setValue(this.resDataFilter.emp_type);
        }
        this.settingFormVal(this.resDataFilter)
      }
      // Download Report
      if(this.reportDownload){
        this.commonLogic(this.apiExcelData)
        this.reportDownload = false
      }
      this.isLoading = false
    })
  }

  // URL & Form collection
  urlGenerator(){
    let urlForm;
    if(this.reportName == 'PF (Provident Fund)'){
      urlForm = { url : 'pf-report', form : this.employeeForm}
    }else if(this.reportName == 'Investment Request'){
      urlForm = { url : 'investment-report', form : this.investmentForm}
    }else if(this.reportName == 'ESI (Employee State Insurance)'){
      urlForm = { url : 'esi-report', form : this.esiForm}
    }else if(this.reportName == 'PT (Professional Tax)'){
      urlForm = { url : 'pt-report', form : this.ptForm}
    }else if(this.reportName == 'Salary Register'){
      urlForm = { url : 'report-salary-register', form : this.salaryRegisterForm}
    }else if(this.reportName == 'YTD Salary Details'){
      urlForm = { url : 'report-salary-register-ytd', form : this.ytdSalaryForm}
    }else if(this.reportName == 'Pay Salary (Bank Transfer) Report'){
      urlForm = { url : 'report-release-salary', form : this.releseSalaryForm}
    }else if(this.reportName == 'CTC As Per Salary Structure'){
      urlForm = { url : 'report-ctc-salary-structure', form : this.ctcSalaryStructureForm}
    }else if(this.reportName == 'LWF (Labour Welfare Fund)'){
      urlForm = { url : 'lwf-report', form : this.lwfForm}
    }else if(this.reportName == 'Monthly TDS Deduction Report'){
      urlForm = { url : 'report-monthly-tds', form : this.monthlyTDSForm}
    }else if(this.reportName == 'Employee Statutory Report'){
      urlForm = { url : 'report-employee-statutory-details', form : this.employeeStatutoryForm}
    }else if(this.reportName == 'CTC Revision'){
      urlForm = { url : 'report-ctc-salary-revision', form : this.ctcRevisionForm}
    }else if(this.reportName == 'Reimbursement Request'){
      urlForm = { url : 'report-reimbursement-request', form : this.reimbursementRequestForm}
    }else if(this.reportName == 'Final Settlement'){
      urlForm = { url : 'final-settlement-report', form : this.finalSettlementForm}
    }else if(this.reportName == 'Investment Not Submitted'){
      urlForm = { url : 'investment-not-submitted-report', form : this.investmentnsForm}
    }else if(this.reportName == 'Variable Paid'){
      urlForm = { url : 'variable-paid-report', form : this.variablePaidForm}
    }else if(this.reportName == 'Overtime Payment'){
      urlForm = { url : 'overtime-paid-report', form : this.overtimePaymentForm}
    }else if(this.reportName == 'Gratuity Paid'){
      urlForm = { url : 'gratuity-paid-report', form : this.gratitudePaidForm}
    }else if(this.reportName == 'NPS Employer Contribution'){
      urlForm = { url : 'nps-employer-contribution-report', form : this.npsForm}
    }else if(this.reportName == 'TDS Computation'){
      urlForm = { url : 'tds-computation-report', form : this.tdsComputationForm}
    }else{
      urlForm = { url : 'report-salary-hold', form : this.salaryOnoldForm}
    }
    return urlForm
  }

  // Side Panel Date & Status
  settingFormVal(data:any){
    const reportConfig = {
      'PF (Provident Fund)': { controlName: 'salary_month', from: 'specific_month', to: 'specific_year', isMandatory : true},
      'Investment Request': { controlName: 'requested_on', from: 'req_specific_month', to: 'req_specific_year', isMandatory : false},
      'PT (Professional Tax)': { controlName: 'salary_month', from: 'specific_month', to: 'specific_year', isMandatory : true},
      'LWF (Labour Welfare Fund)': { controlName: 'salary_month', from: 'sm_specific_month', to: 'sm_specific_year', isMandatory : true},
      'Salary Register': [
        { controlName: 'salary_month', from: 'sm_specific_month', to: 'sm_specific_year', isMandatory : true},
        { controlName: 'date_of_joining', from: 'doj_specific_month', to: 'doj_specific_year', isMandatory : false},
        { controlName: 'date_of_leaving', from: 'dol_specific_month', to: 'dol_specific_year', isMandatory : false},
      ],
      'CTC As Per Salary Structure': [
        { controlName: 'date_of_joining', from: 'doj_specific_month', to: 'doj_specific_year', isMandatory : false},
        { controlName: 'date_of_leaving', from: 'dol_specific_month', to: 'dol_specific_year', isMandatory : false},
      ],
      'Pay Salary (Bank Transfer) Report': { controlName: 'salary_month', from: 'sm_specific_month', to: 'sm_specific_year', isMandatory : true},
      'ESI (Employee State Insurance)': { controlName: 'salary_month', from: 'specific_month', to: 'specific_year', isMandatory : true},
      'Salary On Hold': { controlName: 'salary_month', from: 'sm_specific_month', to: 'sm_specific_year', isMandatory : true},
      'Monthly TDS Deduction Report': { controlName: 'salary_month', from: 'specific_month', to: 'specific_year', isMandatory : true},
      'Employee Statutory Report': [
        { controlName: 'date_of_joining', from: 'doj_specific_month', to: 'doj_specific_year', isMandatory : false},
        { controlName: 'date_of_leaving', from: 'dol_specific_month', to: 'dol_specific_year', isMandatory : false},
      ],
      'CTC Revision': [
        { controlName: 'date_of_joining', from: 'doj_specific_month', to: 'doj_specific_year', isMandatory : false},
        { controlName: 'date_of_leaving', from: 'dol_specific_month', to: 'dol_specific_year', isMandatory : false},
        { controlName: 'effective_month', from: 'effective_specific_month', to: 'effective_specific_year', isMandatory : false},
        { controlName: 'payout_month', from: 'payout_specific_month', to: 'payout_specific_year', isMandatory : false},
      ],
      'YTD Salary Details' : { controlName: 'financial_year', from: '', to: '', isMandatory : false },
      'Variable Paid' : { controlName: 'salary_month', from: 'specific_month', to: 'specific_year', isMandatory : true},
      'Overtime Payment' : { controlName: 'ot_payment_month', from: 'specific_month', to: 'specific_year', isMandatory : true},
      'NPS Employer Contribution' : { controlName: 'salary_month', from: 'specific_month', to: 'specific_year', isMandatory : true},
      'Gratuity Paid' : { controlName : 'finantial_year' , from : '', to : '', isMandatory : false},
      'TDS Computation' : { controlName : 'finantial_year' , from : '', to : '', isMandatory : false},
      'Final Settlement' : { controlName: 'settlement_date', from: 'specific_month', to: 'specific_year', isMandatory : true }
    };
    const defaultConfig = { controlName: 'requested_on', from: 'specific_month', to: 'specific_year'};
    const config = reportConfig[this.reportName as keyof typeof reportConfig] || defaultConfig;
    if (Array.isArray(config)) {
      config.forEach(cfg => {
        this.updateFormControl(data, this.urlGenerator()?.form, cfg.controlName, cfg.from, cfg.to, cfg.isMandatory);
      });
    } else {
      this.updateFormControl(data, this.urlGenerator()?.form, config.controlName, config.from, config.to, config.isMandatory);
    }
  }

  // For Specific date setup
  updateFormControl(filter: any, formGroup: FormGroup, controlName: string, fromDateControl: string, toDateControl: string, isMandatory : boolean) {
    const dateRange = filter?.[controlName];
    let dateArray = dateRange?.split(',');
    let isHyphen = dateRange?.split('-');
    if (dateRange && dateArray.length === 2) {
      formGroup.patchValue({
        [controlName]: true,
        [fromDateControl]: new Date(dateArray[0]),
        [toDateControl]: new Date(dateArray[1])
      });
    } else if (isHyphen?.length === 2) {
      const atten_month = moment(isHyphen[1], 'M').format('MMMM');
      formGroup.patchValue({
        [controlName]: true,
        [fromDateControl]: atten_month,
        [toDateControl]: isHyphen[0]
      });
    } else {
      controlName = controlName == 'finantial_year' ? 'financial_year' : controlName
      formGroup.get(controlName)?.reset(dateRange);
    }
    if (dateRange === undefined && isMandatory) {
      formGroup.get(controlName)?.reset('this_month');
    }
  }

  // Columns Side Panel
  // Reset Columns Button
  resetColumns(){
    const formArray = this.sidePanelForm.get('columnsFormArr') as FormArray;
    formArray.clear();
    if(this.rprtServ.header_filter?.length > 0){
      this.resetFilterPop = true
      this.resetFrom = 'columnReset'
    }else{
      // Rebuild the FormArray with the initial state
      this.initialFormState.forEach((item:any) => {
        formArray.push(this.fb.group({
          name: [item.name],
          checkbox: [item.checkbox]
        }));
      });
      this.headersApply();
    }
  }

  // Apply Columns Button
  headersApply(){
    const checkedItems = this.columnsFormArrForm().controls
    .filter(control => control.get('checkbox')?.value)
    .map(control => control.get('name')?.value);
    const sortedCheckedItems = checkedItems.sort((a, b) => {
      const indexA = this.calcHeaders.indexOf(a);
      const indexB = this.calcHeaders.indexOf(b);
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    });
    // Table Columns setup
    this.displayedColumns = sortedCheckedItems
  }

  // Set Columns Panel from API
  setColumnsPanel(check_list: any, header_list: any) {
    this.calcHeaders = this.rprtServ.header_filter?.length > 0 ? this.rprtServ.header_filter : header_list
    // Preserving initial values for Reset
    this.initialFormState = check_list.map((item:any) => ({
      name: item,
      checkbox: header_list.includes(item)
    }));
    // Setting form array for columns
    this.sidePanelForm = this.fb.group({
      columnsFormArr: this.fb.array(
        check_list.map((item: any) => this.fb.group({
          name: [item],
          checkbox: [this.calcHeaders.includes(item)]
        }))
      )
    });
    this.headersApply()
  }

  // Get Columns Form Panel (Formarray)
  columnsFormArrForm(): FormArray {
    return this.sidePanelForm.get('columnsFormArr') as FormArray;
  }

  // Atleast One column should be checked
  onCheckboxChange(index: number) {
    const formArray = this.columnsFormArrForm();
    const checkboxControl = formArray.at(index).get('checkbox');
    const checkedItems = formArray.controls.filter(ctrl => ctrl.get('checkbox')?.value);
    if (checkedItems.length === 0) {
      // If it's the last item being unchecked, recheck it
      checkboxControl?.setValue(true, { emitEvent: false });
    }
  }

  // Filter Setup
  // Filter Panel ON and OFF
  panel(value: any) {
    this.viewDetail = value
  }

  // Reset Filter Filter
  resetFilterFn(val:any){
    this.page = 1;
    this.pageNumber = 0
    this.offset = 0
    this.resetFilterPop = val
    if (typeof val !== 'boolean') {
      this.reportInitialSetups()
    }else{
      this.resetFrom = 'filterReset'
    }
  }

  // Reset Filter Normal
  reportFnCall(reprt:any){
    this.formData = ''
    this.page = 1;
    this.pageNumber = 0
    this.offset = 0
    if(typeof this.resetFilterPop !== 'boolean' && Object.keys(this.rprtServ.data_filter)?.length == 0){
      this.reportInitialSetups()
      this.filterSubmitMem = false
    }else{
      if(Object.keys(this.rprtServ.data_filter)?.length > 0){
        this.resetFilterPop = true
        this.resetFrom = 'filterReset'
      }
    }
  }

  // Each report filter output
  FilterApiConfig(res:any){
    this.limit = res?.limit;
    this.offset = res?.offset;
    this.formData = res?.filterForm
    this.filterSubmitMem = !this.formData?.split('&').every((data:any)=>{
      const [key, value] = data?.split('=')
      if(key === 'ordering'){
        return true;
      }
      return (value === '[]' || value === '')
    })
    this.resetFilterFn(res)
    this.isSorted = true
    this.viewDetail = false
  }

  // Filter SAVE
  filterMemorization(curr:any){
    let isColumnVal = this.addedColumns || this.rprtServ.header_filter?.length > 0
    let isFilterVal = this.filterSubmitMem || Object.keys(this.rprtServ.data_filter)?.length > 0
    const checkedItems = this.columnsFormArrForm().controls
    .filter(control => control.get('checkbox')?.value)
    .map(control => control.get('name')?.value);
    const sortedCheckedItems = checkedItems.sort((a, b) =>{
      return this.displayedColumns.indexOf(a) - this.displayedColumns.indexOf(b)
    })
    let dataFilter = this.reportDataFunctions[this.reportName]?.() ?? "";
    //dataFilter is not null, undefined, or empty setup
    dataFilter = dataFilter || JSON.stringify({});
    let apiDataFilter;
    if (this.resetFrom === 'filterReset') {
      // If resetFrom is 'filterReset', set to empty object
      apiDataFilter = JSON.stringify({});
    } else if (this.resetFrom === 'columnReset') {
        // If resetFrom is 'columnReset', check data_filter length
        if (Object.keys(this.rprtServ.data_filter)?.length > 0) {
            apiDataFilter = dataFilter;
        } else {
            // If data_filter is empty, set to empty object
            apiDataFilter = JSON.stringify({});
        }
    } else if (isFilterVal === false) {
        // If isFilterVal is false, set to empty object
        apiDataFilter = JSON.stringify({});
    } else {
        // Default case: set to dataFilter
        apiDataFilter = dataFilter;
    }
    let apiHeaderFilter;
    if (this.resetFrom === 'filterReset') {
      // If resetFrom is 'filterreset' and header_filter has items, set to data
      if (this.rprtServ.header_filter?.length > 0) {
        apiHeaderFilter = JSON.stringify(sortedCheckedItems);
      } else {
        // Otherwise, set to empty array
        apiHeaderFilter = JSON.stringify([]);
      }
    } else if (isColumnVal || this.colRearranged) {
      // If not resetting, check if addedColumns or header_filter has items
      apiHeaderFilter = JSON.stringify(sortedCheckedItems);
    } else {
      // Default case: empty array
      apiHeaderFilter = JSON.stringify([]);
    }
    this.rprtServ.filterMemorize({ 'data_filter': apiDataFilter, 'header_filter': apiHeaderFilter, 'is_active': true, 'report': Number(this.reportId) }).subscribe((res: any) => {
      if(curr == 'tag'){
        this.notify.handleSuccessNotification("Updated Successfully","Success")
      }else{
        this.notify.handleSuccessNotification("Created Successfully","Success")
      }
      if(curr == 'reset'){
        if(this.resetFrom == 'filterReset'){
          this.rprtServ.setDataFilter({})
          this.filterSubmitMem = false
          this.isSorted = true
          this.initialLoaded = true
        }else{
          this.rprtServ.setHeaderFilter([])
          this.addedColumns = false
          this.isSorted = false
        }
      }else{
        this.filterSubmitMem = false
        this.addedColumns = false
      }
      this.applyFilterPop = false
      this.colRearranged =  false
      this.saveFilterModalClicked = false
      this.resetFilterPop = false
      this.resetFrom = ''
      this.page = 1
      this.pageNumber = 0
      this.offset = 0
      this.limit = 20
      this.reportInitialSetups()
      if(curr == 'save'){
        this.router.navigate(['/payroll-report']);
      }
    })
  }

  // Save data format from child
  reportDataFunctions: { [key: string]: () => any } = {
    'PF (Provident Fund)': () => this.appPf.getData(),
    'Investment Request': () => this.appInvestmentRequest.getData(),
    'ESI (Employee State Insurance)': () => this.appEsi.getData(),
    'PT (Professional Tax)': () => this.appPt.getData(),
    'Salary Register': () => this.appsalaryRegister.getData(),
    'YTD Salary Details': () => this.appYtdSalaryDetails.getData(),
    'Pay Salary (Bank Transfer) Report': () => this.appReleaseSalary.getData(),
    'CTC As Per Salary Structure': () => this.appCtc.getData(),
    'LWF (Labour Welfare Fund)': () => this.appLwf.getData(),
    'Monthly TDS Deduction Report': () => this.appMonthly.getData(),
    'Employee Statutory Report': () => this.appEmployee_statutory.getData(),
    'CTC Revision': () => this.appCTCRevision.getData(),
    'Reimbursement Request': () => this.appReimburse.getData(),
    'Final Settlement': () => this.appFinalSettlement.getData(),
    'Investment Not Submitted': () => this.investmentNotSubmitted.getData(),
    'Salary On Hold': () => this.appSalaryOnHold.getData(),
    'Variable Paid' : () => this.appVarPaid.getData(),
    'Overtime Payment' : () => this.appOverPy.getData(),
    'NPS Employer Contribution' : () => this.appNPS.getData(),
    'Gratuity Paid' : () => this.appGratitude.getData(),
    'TDS Computation' : () => this.appTDSComp.getData()
  };

  // Popups
  // Filter Mem button
  saveFilterModal(){
    this.saveFilterModalClicked = true;
    if(this.filterSaveForm.get('filter')?.value == true){
      this.filterMemorization('save')
    }else{
      this.filterSubmitMem = false
      this.addedColumns = false
      this.colRearranged = false
      setTimeout(()=>{
        this.router.navigate(['/payroll-report']);
        this.cd.detectChanges()
      },0)
    }
  }

  // Reset Saved Filter button
  resetFilterApply(){
    this.viewDetail = false
    this.filterMemorization('reset')
    // Filter reset
    if(this.resetFrom == 'filterReset'){
      this.resetFilterControllers()
    }
    this.resetFilterPop = false
  }

  // Reset from Parent
  resetFilterControllers(): void {
    type ReportAction = () => void;
    const reportActions : { [key: string]: ReportAction }= {
      'PF (Provident Fund)': () => this.appPf.resetFilterControllers('reset'),
      'Investment Request': () => this.appInvestmentRequest.resetFilterControllers('reset'),
      'ESI (Employee State Insurance)': () => this.appEsi.resetFilterControllers('reset'),
      'PT (Professional Tax)': () => this.appPt.resetFilterControllers('reset'),
      'Salary Register': () => this.appsalaryRegister.resetFilterControllers('reset'),
      'YTD Salary Details': () => this.appYtdSalaryDetails.resetFilterControllers('reset'),
      'Pay Salary (Bank Transfer) Report': () => this.appReleaseSalary.resetFilterControllers('reset'),
      'CTC As Per Salary Structure': () => this.appCtc.resetFilterControllers('reset'),
      'LWF (Labour Welfare Fund)': () => this.appLwf.resetFilterControllers('reset'),
      'Monthly TDS Deduction Report': () => this.appMonthly.resetFilterControllers('reset'),
      'Employee Statutory Report': () => this.appEmployee_statutory.resetFilterControllers('reset'),
      'CTC Revision': () => this.appCTCRevision.resetFilterControllers('reset'),
      'Reimbursement Request': () => this.appReimburse.resetFilterControllers('reset'),
      'Final Settlement': () => this.appFinalSettlement.resetFilterControllers('reset'),
      'Investment Not Submitted': () => this.investmentNotSubmitted.resetFilterControllers('reset'),
      'Salary On Hold': () => this.appSalaryOnHold.resetFilterControllers('reset'),
      'Variable Paid' : () => this.appVarPaid.resetFilterControllers('reset'),
      'Overtime Payment' : () => this.appOverPy.resetFilterControllers('reset'),
      'NPS Employer Contribution' : () => this.appNPS.resetFilterControllers('reset'),
      'Gratuity Paid' : () => this.appGratitude.resetFilterControllers('reset'),
      'TDS Computation' : () =>  this.appTDSComp.resetFilterControllers('reset')
    };

    if (this.reportName in reportActions) {
      reportActions[this.reportName]();
    }
  }

  // Close Button
  confirm(): boolean {
    if(this.filterSubmitMem == true || this.addedColumns == true || this.colRearranged == true){
      this.applyFilterPop = true
      return false
    }else{
      return true
    }
  }

  // Download Excel
  export(){
    this.limit = this.lastPageCount
    this.isSorted = true
    this.reportDownload = true
    this.reportInitialSetups()
  }

  // Download arrangements
  commonLogic(data: any) {
    let column = this.displayedColumns;
    const newArray = [];
    for (const obj of data) {
      const newObject: any = {};
      for (const key of column) {
        if (obj.hasOwnProperty(key)) {
          newObject[key] = obj[key];
        }
      }
      newArray.push(newObject);
    }
    if (data != undefined)
      this.exportExcel(newArray, this.reportName);
    this.cd.detectChanges()
  }

  public exportExcel(jsonData: any[], fileName: string): void {
    let excelHeaders = []
    let templateToExcel :any = []
    if (!this.noData) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
      const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      this.saveExcelFile(excelBuffer, fileName);
    }
    else {
      for (let i = 0; i < this.displayedColumns.length; i++) {
        excelHeaders.push(this.displayedColumns[i])
        templateToExcel = [excelHeaders, []];
      }
      const wss: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(templateToExcel);
      const wbb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wbb, wss, 'Sheet1');
      XLSX.writeFile(wbb, fileName + '.xlsx')
    }
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    FileSaver.saveAs(data, fileName + '.xlsx');
  }

  // Table COlumn Drop
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
    this.colRearranged = true
    this.calcHeaders = this.displayedColumns
  }

  // Sort
  onSort(val: any) {
    this.direction = (this.sortProperty === val && this.direction === 'desc') ? 'asc' : 'desc';
    this.sortProperty = (this.sortProperty === val) ? this.sortProperty : val;
    this.ordering = `${this.direction === 'desc' ? '-' : ''}${val}`;
    this.formData = this.formData?.replace(/ordering=.*?(?=&|$)/, `ordering=${this.ordering}`);
    this.isSorted = true
    this.reportInitialSetups()
  }

  // Api call with updated Limit
  pageChanged(val: any) {
    this.pageNumber = val - 1
    this.offset = this.rprtServ.calculateOffset(val - 1)
    this.isSorted = true
    this.reportInitialSetups()
  }

  // Tags
  // Show/Hide close icon
  isFilterVisible(reportName: string, filterKey: any): boolean {
    const reportFilters : { [key: string]: string[] } = {
    'PF (Provident Fund)': ['salary_month'],
    'Investment Request': ['financial_year','investment_type'],
    'ESI (Employee State Insurance)': ['salary_month'],
    'PT (Professional Tax)': ['salary_month'],
    'Salary Register': ['salary_month'],
    'YTD Salary Details': ['financial_year'],
    'Pay Salary (Bank Transfer) Report': ['salary_month'],
    'CTC As Per Salary Structure': ['ctc_breakup','pay_group'],
    'LWF (Labour Welfare Fund)': ['salary_month'],
    'Monthly TDS Deduction Report': ['salary_month'],
    'Employee Statutory Report': [''],
    'CTC Revision': [''],
    'Reimbursement Request': ['financial_year','requested_on'],
    'Final Settlement': ['settlement_date'],
    'Investment Not Submitted': ['financial_year','investment_type'],
    'Salary On Hold': ['salary_month'],
    'Variable Paid' : ['salary_month'],
    'Overtime Payment' : ['ot_payment_month'],
    'NPS Employer Contribution' : ['salary_month'],
    'Gratuity Paid' : ['finantial_year'],
    'TDS Computation' : ['finantial_year']
    };

    return reportName in reportFilters && !reportFilters[reportName].includes(filterKey);
  }

  // Call child fn for tag close
  handleTagClose(reportName: string, filterKey: any): void {
    const controlMap: { [key: string]: string } = {
      'emp_type': 'employee_type',
      'emp_status': 'employment_status'
    };

    this.closeControl = controlMap[filterKey] || filterKey;
    this.cd.detectChanges()
    type ReportAction = () => void;
    const reportActions : { [key: string]: ReportAction }= {
      'PF (Provident Fund)': () => this.appPf.applyTagClose1(),
      'Investment Request': () => this.appInvestmentRequest.applyTagClose1(),
      'ESI (Employee State Insurance)': () => this.appEsi.applyTagClose1(),
      'PT (Professional Tax)': () => this.appPt.applyTagClose1(),
      'Salary Register': () => this.appsalaryRegister.applyTagClose1(),
      'YTD Salary Details': () => this.appYtdSalaryDetails.applyTagClose1(),
      'Pay Salary (Bank Transfer) Report': () => this.appReleaseSalary.applyTagClose1(),
      'CTC As Per Salary Structure': () => this.appCtc.applyTagClose1(),
      'LWF (Labour Welfare Fund)': () => this.appLwf.applyTagClose1(),
      'Monthly TDS Deduction Report': () => this.appMonthly.applyTagClose(),
      'Employee Statutory Report': () => this.appEmployee_statutory.applyTagClose1(),
      'CTC Revision': () => this.appCTCRevision.applyTagClose1(),
      'Reimbursement Request': () => this.appReimburse.applyTagClose1(),
      'Final Settlement': () => this.appFinalSettlement.applyTagClose1(),
      'Investment Not Submitted': () => this.investmentNotSubmitted.applyTagClose1(),
      'Salary On Hold': () => this.appSalaryOnHold.applyTagClose1(),
      'Variable Paid' : () => this.appVarPaid.applyTagClose1(),
      'Overtime Payment' : () => this.appOverPy.applyTagClose1(),
      'NPS Employer Contribution' : () => this.appNPS.applyTagClose1(),
      'Gratuity Paid' : () => this.appGratitude.applyTagClose1(),
      'TDS Computation' : () => this.appTDSComp.applyTagClose1()
    };

    if (reportName in reportActions) {
      reportActions[reportName]();
    }
    if(Object.keys(this.rprtServ.data_filter)?.length > 0){
      this.filterMemorization('tag')
    }
  }

  // Utilities Used
  // Select ALL option for Multiselect
  selectAllForDropdownItems(items: any[]) {
    let allSelect = (items: any[]) => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };
    allSelect(items);
  }

  // Sort attribute for response
  setDataSourceAttributes() {
    if (this.apiData != undefined && this.apiData != null) {
      this.apiData.sort = this.sort;
    }
  }

  // More than 1 item in array
  tagMultiDataFunction(data: any) {
    this.tagMultiData = [];
    this.tagMultiCnt = 0;
    this.tagMultiData.push(data[0]);
    if (data?.length == 1) {
      return this.tagMultiData;
    } else {
      this.tagMultiCnt = '+' + JSON.stringify(data?.length - 1);
      return this.tagMultiData;
    }
  }

  // Tage Underscore
  removeUnderscoreAddUpperCase(str: any) {
    var i, frags = str.split('_');
    for (i = 0; i < frags?.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  // Excess data on click of tags
  onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  // MatDatePicker
  salFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date(new Date().getFullYear() + 50, 11, 31)]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // DOJ based filter date
  dojFilter(d: any) {
    let dateRange = [new Date(new Date().getFullYear() - 50, 0, 1),
    new Date()]
    return (d >= dateRange[0] && d <= dateRange[1])
  }

  // HTML for date split
  getDateRangeToDisplay(dateSetup: any, key: any): string {
    const dates = dateSetup?.[key];
    return dates
      ? `${this.appService.dateFormatDisplay(dates[0])} - ${this.appService.dateFormatDisplay(dates[1])}`
      : '';
  }

  // Year List
  yearListFunction(){
    let selectedYear = new Date().getFullYear();
    this.attendReprtServ.yearListDropdown().subscribe((res: any) => {
      if(res?.created_year == selectedYear){
        this.yearsList.push(res?.created_year)
      }
      else{
        for(let i=selectedYear;i>=res?.created_year;i--){
          this.yearsList.push(i)
        }
      }
    })
  }

  ngAfterViewInit(){
    this.getReimbursementDropdown()
    this.getVariableDropdown();
  }

  getReimbursementDropdown(){
    this.pay_componentServ.getPayComponentList(true,false).subscribe((res:any)=>{
      let reimburse_component = res
      reimburse_component.filter((item:any)=>{
        if(item.component_type_name == "Reimbursement"){
          this.reimbursement_dropdown.push(item)
        }
      })
      this.selectAllForDropdownItems(this.reimbursement_dropdown)
      this.cd.detectChanges()
    })
  }

  getVariableDropdown(){
    this.rprtServ.getVariableComponent().subscribe((res:any)=>{
      this.variableDropdown = res
      this.selectAllForDropdownItems(this.variableDropdown)
    })
  }

  // Date Format
  getDateFormat(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const dateFormat = this.appService.getdatepickerformat();
        if (dateFormat !== '') {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }

}
