import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { AppService } from 'src/app/app.global';
import { PreviousRouteService } from './previous-route.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class InvestmentDeclerationService {
  request_item: EventEmitter<any> = new EventEmitter();
  request_id: EventEmitter<any> = new EventEmitter();
  constructor(
    private http: HttpClient,
    public router: Router,
    private authService: AuthService,
    private websocket: WebSocket,
    public appservice:AppService,
    private previousRouteService: PreviousRouteService,
    private _location: Location
  ) { }

  API_PATH: string = environment.apiPath;
  httpOption = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  WEBSOCKET_URL: string =environment.webSocketEndPoint;

  public requestSocket(){
    this.websocket = new WebSocket(`${this.WEBSOCKET_URL}request/${this.appservice.getSubDomain()}_empreq_${this.authService.isUserId}/`);

    this.websocket.onopen = (event:any) => {
      // console.log('Open request live update socket: ', event);
    }

    this.websocket.onmessage = (event:any) => {
      // console.log('Inbox live update Item: ', event);
      // console.log('Requestttt live update data', JSON.parse(event.data));
      let data = JSON.parse(event.data);
      this.request_id.emit(data?.event['id']);
      this.request_item.emit(data);
    }

    this.websocket.onclose = (event:any) => {
      // console.log('Close request live update: ', event);
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          // Hide loading indicator
          if(event.url !== '/request'){
            this.closeSocket();
          }
      }
    });

    if (this.websocket.readyState === WebSocket.OPEN) {
        // console.log('connected to request live update websocket');
    }
  }

  public closeSocket(){
    if (this.websocket.readyState === WebSocket.OPEN) {
      this.websocket.close();
      // this.websocket.onclose = (event:any) => {
      //   // console.log('close inbox socket: ', event);
      // }
      this.websocket.addEventListener('close', (event) => {
        // console.log('The connection has been closed successfully.');
      });
    }
  }

  cityDropdownList() {
    return this.http.get(`${this.API_PATH}organization/api/v1/city-list-dropdown/?ordering=name&is_active=true`, this.httpOption)
  }
  createInvestment(body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/investment_declaration-create/`, body, this.httpOption)
  }
  requestDetails() {
    return this.http.get(`${this.API_PATH}request/api/v1/request-count/`, this.httpOption)
  }
  investmentDatas(empid: any, year: any) {
    return this.http.get(`${this.API_PATH}request/api/v1/investment_declaration-list/?proposed_confirm=false&employee=${empid}&year=${year}&get_data=proposed`, this.httpOption)
  }
  investLimitList() {
    return this.http.get(`${this.API_PATH}request/api/v1/investment-declaration-limit-list/`, this.httpOption)
  }
  taxRegimeCalculation(empid: any,body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/tax-regime-calculation/${empid}/`, body, this.httpOption)
  }
  donation100DropDown(year: any) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/general-dropdown/?&year=${year}&tags=80donation_100`, this.httpOption)
  }
  donation50DropDown(year: any) {
    return this.http.get(`${this.API_PATH}general_settings/api/v1/general-dropdown/?&year=${year}&tags=80donation_50`, this.httpOption)
  }

  requestList(status:any, limit:any, offset:any,filterdata:any,search:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-list/?status=${status}&limit=${limit}&offset=${offset}&${filterdata}&search=${search}`, this.httpOption)
  }
  requestAllList(limit:any, offset:any,search:any,filterdata:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-list/?limit=${limit}&offset=${offset}&search=${search}&${filterdata}`, this.httpOption)
  }
  requestSearch(status:any,filterdata:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-list-dropdown/?ordering=request_type__name&status=${status}&${filterdata}`, this.httpOption)
  }
  requestAllSearch(filterdata:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-list-dropdown/?ordering=request_type__name&${filterdata}`, this.httpOption)
  }
  singleRequest(id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-list/?req_id=${id}`, this.httpOption)
  }
  requestTypeList(search:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-type-list/?ordering=name&search=${search}`, this.httpOption)
  }
  requestTypeListfilter() {
    return this.http.get(`${this.API_PATH}request/api/v1/request-type-list/?ordering=name&external_request_type=True`, this.httpOption)
  }
  recentUsedRequest() {
    return this.http.get(`${this.API_PATH}request/api/v1/request-type-recent/`, this.httpOption)
  }
  investmentSingleData(id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/investment_declaration-update/${id}/`, this.httpOption)
  }
  requestViewStatus(id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${id}`, this.httpOption)
  }

  getCity(id: number) {
    return this.http.get(`${this.API_PATH}organization/api/v1/city-detail/${id}`, this.httpOption)
  }
  getSingleReqType(id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-type-list/?id=${id}`, this.httpOption)
  }
  holidayFloatList(datesort: any,search: any) {
    return this.http.get(`${this.API_PATH}request/api/v1/employee-holiday-list/?ordering=${datesort}&search=${search}`, this.httpOption)
  }
  holidayFloatCreate(body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/holiday-request-create-list/`, body, this.httpOption)
  }
  holidayFloatDropDown(){
    return this.http.get(`${this.API_PATH}request/api/v1/employee-holiday-list-dropdown/?ordering=holiday__holiday_name`, this.httpOption)
  }
  holidayFloatGet(id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/employee-holiday-list/?id=${id}`, this.httpOption)
  }
  holidayFloatView(request_id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=Holiday&request_id=${request_id}`, this.httpOption)
  }

  // getleavetypeList(){
  //   return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-dropdown/?is_active=1&ordering=leave_name`, this.httpOption);
  // }
  getleavetypeList(id: number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-type-employee-dropdown/?id=${id}&ordering=leave_name`, this.httpOption);
  }
  leaveRequestValidation(body: any) {
    return this.http.post(`${this.API_PATH}request/api/v2/leave-request-validation/`, body, this.httpOption)
  }
  leaveRequestCreate(body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/leave-request-create-list/`, body, this.httpOption)
  }
  leaveBalance(body: any){
    return this.http.post(`${this.API_PATH}request/api/v1/leave-balance/`, body, this.httpOption)
  }
  getLeavePolicy(id: number, empid: number){
    return this.http.get(`${this.API_PATH}attendance/api/v1/leave-policy-leave-type/?leave_type=${id}&employee=${empid}`, this.httpOption);
  }
  leaveRequestView(type:any,id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=${type}&request_id=${id}`, this.httpOption)
  }
  timeOffTrend(body: any){
    return this.http.post(`${this.API_PATH}request/api/v1/leave-trend/`, body, this.httpOption)
  }
  getWeekOffHolidayList(body: any){
    return this.http.post(`${this.API_PATH}request/api/v1/weekoff-holiday-list/`, body, this.httpOption)
  }
  getCompOffList(empid: number){
    return this.http.get(`${this.API_PATH}request/api/v1/compoff-available-list/?employee_id=${empid}`, this.httpOption);
  }

  // test start
  leaveRequestBalance(empId: any, leave_type:any, leave_num_days:any, from_date:any, to_date:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/leave-request-balance/?employee=${empId}&leave_type=${leave_type}&leave_num_days=${leave_num_days}&from_date=${from_date}&to_date=${to_date}`, this.httpOption)
  }
  leaveTransaction(empId: number, date:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/leave-transaction/?employee=${empId}&selected_date=${date}`, this.httpOption)
  }
  getLeaveRule(empId: any, leave_type:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/leave-rule/?employee_id=${empId}&leave_type=${leave_type}`, this.httpOption)
  }
  // test end

  getEmployeeRequestViewData(request_type:any='', id:any=''){
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=${request_type}&request_id=${id}`, this.httpOption)
  }

  // FBP Request

  getFBPCategoryList(employee_id: any,search:any){
    return this.http.get(`${this.API_PATH}request/api/v1/fbp-category-list/${employee_id}/?search=${search}`, this.httpOption)
  }
  getFBPCategorySummary(employee_id: any){
    return this.http.get(`${this.API_PATH}request/api/v1/fbp-category-summary/${employee_id}/`, this.httpOption)
  }
  getFBPCategorySingleList(employee_id: any,id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/fbp-category-list/${employee_id}/?pay_component__id=${id}`, this.httpOption)
  }

  fbpRequestCreate(body: any) {
    return this.http.post(`${this.API_PATH}request/api/v1/fbp-request-create/`, body, this.httpOption)
  }
  fbpRequestViewData(req_type:any,id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=${req_type}&request_id=${id}`, this.httpOption)
  }
  fbpDeclarationSearch(employee_id:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/fbp-category-list-dropdown/${employee_id}/`, this.httpOption)
  }

  reimbursementViewData(id: number) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=Reimbursement&request_id=${id}`, this.httpOption)
  }

  investmentDetailsFnF(employee_id:any) {
    return this.http.get(`${this.API_PATH}request/api/v1/request-count/?employee_id=${employee_id}`, this.httpOption)
  }
  upDateFnFInvestment(id:number,data:any){
    return this.http.put(`${this.API_PATH}request/api/v1/investment_declaration-update/${id}/`,data, this.httpOption)
  }

  requestCancel(body:any){
    return this.http.post(`${this.API_PATH}organization/api/v1/request-cancel/`, body, this.httpOption)
  }

  // View Status
  viewStatusCheckInData(empId:number,reqst_id:number){
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${empId}&request_section=Check-in Request&specific_id=${reqst_id}`,this.httpOption)
  }

  backClicked(url:any) {
    if(this.previousRouteService.getPreviousUrl() == '/login' || this.previousRouteService.getPreviousUrl() == url )
    this.router.navigate(['request']);
    else
    this._location.back();
  }
  shortLeaveTrend(body: any){
    return this.http.post(`${this.API_PATH}request/api/v1/short-leave-trend/`, body, this.httpOption)
  }

  // View SHORT LEAVE Request Data
  getShortRequestData(req_type_name:any,req_id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=${req_type_name}&request_id=${req_id}`, this.httpOption);
  }

  // Create API Request
  createSHLRequest(body:any){
    return this.http.post(`${this.API_PATH}request/api/v1/short-leave-request-create-list/`, body, this.httpOption)
  }

  // View Status
  viewStatusSHLData(empId:number,reqst_id:number){
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${empId}&request_section=Short Leave&specific_id=${reqst_id}`,this.httpOption)
  }

  // SHL Validation
  shlValidationAPI(body:any){
    return this.http.post(`${this.API_PATH}request/api/v1/short-leave-request-validation/`, body, this.httpOption)
  }

  // Create Loan API
  createLoanRequest(body:any){
    return this.http.post(`${this.API_PATH}request/api/v1/loan-request-create/`, body, this.httpOption)
  }

  // View Status
  viewStatusLoanData(empId:number,reqst_id:number){
    return this.http.get(`${this.API_PATH}request/api/v1/request-view-status/?emp_req_id=${empId}&request_section=Loan&specific_id=${reqst_id}`,this.httpOption)
  }

  // View Loan Request Data
  getLoanData(req_type_name:any,req_id:any){
    return this.http.get(`${this.API_PATH}request/api/v1/request-detail/?request_type=${req_type_name}&request_id=${req_id}`, this.httpOption);
  }

  // Get Loan Details
  getLoanTypeDetails(id:any){
    return this.http.post(`${this.API_PATH}loan/api/v1/loanType-details/${id}`, this.httpOption);
  }

  // Calc EMI
  calculateEMI(body:any){
    return this.http.post(`${this.API_PATH}loan/api/v1/emi-calculation-detail/`, body, this.httpOption);
  }
}
